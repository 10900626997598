import { makeAutoObservable } from "mobx";

type state = {
  state: string;
  startTime: string;
  scheduleKey: string;
  metadata: {
    closeDate: string;
  };
  phase: string;
};
class StateStore {
  state = {
    state: "",
    startTime: "",
    scheduleKey: "",
    metadata: {
      closeDate: "",
    },
    phase: "",
  };

  constructor() {
    makeAutoObservable(this);
  }

  setState = (data: state) => {
    this.state.state = data.state;

    this.state.metadata = data.metadata;

    this.state.phase = data.phase;

    this.state.scheduleKey = data.scheduleKey;
  };

  getstatedetails() {
    return this.state;
  }
}

export default StateStore;
