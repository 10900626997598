import React from "react";
import { campaignClient, publicCampaignClient } from "../../../api";
import { useQuery } from "react-query";
import Loading from "../../../Components/Loading";
import { useNavigate, useLocation } from "react-router-dom";
import { PageLoader, Table } from "../../../Components";
import { convertcaps } from "../../../helpers/utils";
import { useState, useEffect, useMemo } from "react";
import DistrictColumns from "./DistrictColumns";
import { CSVLink } from "react-csv";
import Header from "../../../Components/Header";
import dayjs from "dayjs";

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

export type DistrictDetails = {
  city: string;
  zip: string;
  district: string;
  address: string;
  school_sites: string;
  region: string;
  name: string;
  address1: string;
  state: string;
  event: {
    zip: string;
    address1: string;
    city: string;
    address2: string;
    eventTime: string;
    state: string;
    eventDate: string;
  };
};

const Districts = () => {
  const [filterTable, setFilterTable] = useState("");
  const [regionFilter, setRegionFilter] = useState("");
  

  const navigate = useNavigate();
  const { isLoading: loadingDistricts, data: districts } = useQuery(
    "getDistricts",
    async () => {
      const res: { districts: DistrictDetails[] } = await campaignClient.call("getDistricts", {});
      return res.districts.sort((a,b)=>a.name > b.name?1:0);
    },
    {
      onError: (error) => {
        window.vex.dialog.alert({
          unsafeMessage: `Something went wrong, please try again.`,
          callback: () => {
            return;
          },
        });
      },
    }
  );
  const regions = districts ? Array.from(new Set(districts.map((p) => p.region))) : [];

  const data = useMemo(() => {
    if (districts && districts.length > 0) {
      let filteredDistricts = districts;
      if (regionFilter) {
        filteredDistricts = districts.filter((d) => d.region === regionFilter);
      }
      return filteredDistricts.map((district: DistrictDetails) => {
        return {
          ...district,
          districtName: district.name ? convertcaps(district.name) : " ",
          eventDate: district.event?.eventDate
            ? new Date(district.event.eventDate).toLocaleDateString()
            : "N/A",
          eventTime: district.event?.eventTime
            ? district.event.eventTime
            : "N/A",
          eventLocation: district.event?.address1
            ? convertcaps(district.event?.address1) +
              ", " +
              (district.event?.address2 && district.event?.address2 + ", ") +
              convertcaps(district.event.city) +
              ", " +
              district.event?.state?.toUpperCase() +
              ", " +
              district.event?.zip
            : "N/A",
        };
      });
    } else {
      return [];
    }
  }, [districts, regionFilter]);

  const reportData = useMemo(() => {
    if (data) {
      return data.map((district) => {
        return {
          "School District": district.districtName,
          "Address 1": district.address,
          "Address 2": district.address1,
          City: district.city,
          State: district.state,
          Zip: district.zip,
          "Elem. School Sites": district.school_sites,
          Region: district.region,
        };
      });
    }
  }, [data]);
  const columns = DistrictColumns();

  if (loadingDistricts) {
    return <Loading />;
  }

  return (
    <>
      <section className="section section--alt">
        <div className="shell">
          <Header
            title="Districts"
            children={
              <div className="search search--alt">
                <div className="search__row">
                  <label htmlFor="q" className="hidden">
                    Search districts
                  </label>
                  <input
                    style={{ minWidth: "25rem" }}
                    type="search"
                    name="q"
                    id="q"
                    value={filterTable}
                    placeholder="Search"
                    className="search__field"
                    onChange={(e) => setFilterTable(e.target.value)}></input>
                  <button type="submit" className="search__btn">
                    <svg className="ico-search">
                      <image xlinkHref="/assets/images/svg/ico-search.svg"></image>
                    </svg>
                  </button>
                </div>
              </div>
            }
          />
          <hr className="underlineHR" />
          <div className="shell">
            <div className="section__inner">
              <div className="section__body">
                <div
                  className="section__actions"
                  style={{ display: "flex", justifyContent: "space-between" }}>
                  <button
                    type="button"
                    className="btn btn--purple btn--add"
                    onClick={(e) => {
                      navigate("/admin/adddistrict");
                    }}>
                    Add district
                  </button>
                  <div className="search search--alt select-multiple">
                    <div className="search__row select">
                      <select
                        style={{ width: "20rem" }}
                        value={regionFilter}
                        onChange={(e) => setRegionFilter(e.currentTarget.value)}>
                        <option value="">All regions</option>
                        {regions.map((r) => (
                          <option value={r} key={r}>
                            {convertcaps(r)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table table--schools">
                <Table
                  columns={columns}
                  data={data ? data : []}
                  tablePageSize={15}
                  filterValue={filterTable}
                />
              </div>
            </div>
            <div className="section__actions">
              <div className="paging">{/* <ReactPaginate /> */}</div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Districts;
