import { useState, useMemo, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SchoolColumns } from "./SchoolColumns";
import { campaignClient, publicCampaignClient } from "../../../api";
import { PageLoader, Table } from "../../../Components";
import { useQueries } from "react-query";
import { convertcaps } from "../../../helpers/utils";
import "./index.css";
import Header from "../../../Components/Header";
import { useForm } from "react-hook-form";
import { District } from "../../Dms/Signup";
import { useStores } from "../../../stores";
import { defaultStorageHelper } from "@spry/campaign-client";
import { faL } from "@fortawesome/free-solid-svg-icons";

type SchoolSignup = {
  schoolId?: string;
  schoolName: string;
  address1: string;
  address2?: string;
  city: string;
  zip: string;
  region: string;
  participating?: boolean;
  district: string;
  districtName?: string;
  host: boolean;
  contact: {
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
  };
};

type SelectUsers = {
  district: string;
  region: string;
  status: string;
};
const DMSSchools = () => {
  const navigate = useNavigate();
  const [filterTable, setFilterTable] = useState("");
  const [loading, setLoading] = useState(false);
  const user = useStores().authStore.user;
  const role = useStores().authStore.user.role || defaultStorageHelper.get("current");
  const [phase, setPhase] = useState("");

  useEffect(() => {
    setLoading(true);
    checkPhase("phase1b");
    setLoading(false);
  }, []);

  async function checkPhase(phase: string) {
    try {
      const { state }: { state: string } = await campaignClient.call("getschedule", { phase });
      if (!state) {
        navigate("/dms/comeBackLatter");
      }
      setPhase(state);
    } catch (e) {
      return;
    }
  }
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<SelectUsers>({
    defaultValues: {
      district: "",
      region: "",
      status: "all",
    },
  });
  const formWatch = watch();

  const [districts, schools] = useQueries([
    {
      queryKey: ["districts", 1],
      queryFn: async () => {
        if (role === "admin") {
          const res: { districts: any[] } = await publicCampaignClient.call("getDistricts", {});
          return res.districts;
        } else {
          const res: { districts: any[] } = await campaignClient.call("getDistricts", {
            userName: user.name,
          });

          return res.districts;
        }
      },
    },
    {
      queryKey: ["schools", 2],
      queryFn: async () => {
        let schools: SchoolSignup[] = [];
        if (role === "admin") {
          const res: { schools: SchoolSignup[] } = await campaignClient.call("schools", {});
          if (res.schools) {
            schools = res.schools;
          }
        } else {
          const res: { schools: SchoolSignup[] } = await campaignClient.call("schools", {
            userName: user.name,
          });
          if (res.schools) {
            schools = res.schools;
          }
        }

        const sortedSchools = schools?.sort((a, b) =>
          a.schoolName.toString().localeCompare(b.schoolName.toString())
        );

        return sortedSchools;
      },
    },
    { refetchOnWindowFocus: false },
  ]);

  const data = useMemo(() => {
    if (schools.data && districts.data && schools.data.length > 0) {
      let selectedSchools = schools.data;
      let { district, region, status } = formWatch;
      if (region) {
        selectedSchools = selectedSchools.filter(
          (s) => s.region?.toLowerCase() === region?.toLowerCase()
        );
      }
      if (district) {
        selectedSchools = selectedSchools.filter((s) => s.district === district);
      }
      if (status) {
        if (status === "host") {
          selectedSchools = selectedSchools.filter((s) => s.host);
        }
        if (status === "participating") {
          selectedSchools = selectedSchools.filter((s) => !s.host);
        }
      }

      return selectedSchools;
    }
  }, [schools.data, districts.data, formWatch]);

  const regions = districts.data ? Array.from(new Set(districts.data.map((p) => p.region))) : [];

  const columns = SchoolColumns();

  if (schools.isLoading || districts.isLoading) {
    return <PageLoader />;
  }
  return schools.data && schools.data?.length > 0 ? (
    <section className="section section--alt">
      <div className="shell">
        <Header
          title="Schools"
          children={
            <div className="search search--alt">
              <div className="search__row">
                <label htmlFor="q" className="hidden">
                  Search schools
                </label>
                <input
                  style={{ minWidth: "25rem" }}
                  type="search"
                  name="q"
                  id="q"
                  value={filterTable}
                  placeholder="Search"
                  className="search__field"
                  onChange={(e) => setFilterTable(e.target.value)}></input>
                <button type="submit" className="search__btn">
                  <svg className="ico-search">
                    <image xlinkHref="/assets/images/svg/ico-search.svg"></image>
                  </svg>
                </button>
              </div>
            </div>
          }
        />
        <hr className="underlineHR" />
        <div className="shell">
          <div className="section__inner">
            <div className="section__body">
              <div
                className="section__actions"
                style={{ display: "flex", justifyContent: "space-between" }}>
                {phase === "open" && (
                  <button
                    type="button"
                    className="btn btn--purple btn--add"
                    onClick={(e) => {
                      if (window.location.pathname.includes("/admin")) {
                        navigate("/admin/addschool");
                      } else {
                        navigate("/dms/addschool");
                      }
                    }}>
                    Add school
                  </button>
                )}
                {role === "admin" && (
                  <form onSubmit={handleSubmit(() => {})}>
                    <div style={{ display: "flex" }}>
                      <div className="search search--alt select-multiple">
                        <div className="search__row select">
                          <select
                            style={{ width: "20rem" }}
                            id="q"
                            {...register("region")}
                            className="">
                            <option value="">All regions</option>
                            {regions.map((r) => (
                              <option value={r.toLowerCase()} key={r}>
                                {convertcaps(r)}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="search search--alt select-multiple">
                        <div className="search__row select">
                          <select style={{ width: "20rem" }} {...register("district")}>
                            <option value="">All districts</option>
                            {districts.data &&
                              districts.data?.map((v: District) => (
                                <option value={v.district} key={v.district}>
                                  {v.districtName && convertcaps(v.districtName)}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>

                      <div className="search search--alt ">
                        <div className="search__row select">
                          {/* //status: 1->viewed, 2 -> been selected as district competition 3 -> been selected as district winner */}

                          <select style={{ width: "20rem" }} {...register("status")}>
                            <option value="all">All Statuses</option>
                            <option value="host">Host</option>
                            <option value="participating">Participating</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </div>
              <div className="table table--schools">
                <Table
                  columns={columns}
                  data={data ? data : []}
                  tablePageSize={15}
                  filterValue={filterTable}
                />
              </div>
            </div>
            <div className="section__actions">
              <div className="paging">{/* <ReactPaginate /> */}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : (
    <section className="section section--alt">
      <div className="shell">
        <Header title="Schools" />
        <hr className="underlineHR" />

        <div className="section__content" style={{ float: "left", marginTop: "25px" }}>
          <h4>No schools added</h4>
          {phase === "open" && (
            <>
              <p>
                You haven’t added any schools yet. Please click the button below to get started.
              </p>

              <div className="section__actions">
                <Link to="/dms/addschool" className="btn btn--purple btn--add btn--big">
                  <i>
                    <img src="assets/images/svg/plus-circle.svg" alt="" />
                  </i>
                  Add School
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default DMSSchools;
