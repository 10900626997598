import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStores } from "../../../stores";
import { convertcaps } from "../../../helpers/utils";

export const SchoolColumns = () => {
  const navigate = useNavigate();

  const COLUMNS = [
    {
      Header: "School name",
      accessor: "schoolName",
      width: "28%",
      Cell: (tableInfo: any) => {
        return tableInfo.data[tableInfo.row.index].schoolName
          ? convertcaps(tableInfo.data[tableInfo.row.index].schoolName)
          : "NA";
      },
    },
    {
      Header: "District",
      accessor: "districtName",
      width: "30%",
      Cell: (tableInfo: any) => {
        return tableInfo.data[tableInfo.row.index].districtName
          ? convertcaps(tableInfo.data[tableInfo.row.index].districtName)
          : "NA";
      },
    },
    {
      Header: "Region",
      accessor: "region",
      width: "30%",
    },
    {
      Header: "Status",
      accessor: "status",
      width: "35%",
      Cell: (tableInfo: any) => {
        let status = tableInfo.data[tableInfo.row.index].host ? "Host" : "Participating";
        return status;
      },
    },
    {
      Header: "Actions",
      accessor: "schoolId",
      Cell: (tableInfo: any) => {
        return (
          <div className="table__actions">
            <button
              className="noBorder"
              onClick={() => {
                if (window.location.pathname.includes("/admin")) {
                  navigate("/admin/editSchool", { state: tableInfo.data[tableInfo.row.index] });
                } else {
                  navigate("/dms/editSchool", { state: tableInfo.data[tableInfo.row.index] });
                }
              }}>
              <img src="/assets/images/svg/edit.svg" alt="edit user" width={25} height={25} />
            </button>
          </div>
        );
      },
    },
  ];
  return COLUMNS;
};
