import { Participant } from "@sprycore/spry-api-client/dist/MainDbReturnTypes";
import { Link } from "react-router-dom";
import { ACTIONS, convertcaps } from "../../../helpers/utils";

export const SubmissionsColumns = () => {
  const styles = {
    button: {
      border: "none",
      backgroundColor: "transparent",
      color: "blue",
    },
  } as const;
  const COLUMNS = [
    {
      Header: "Student name",
      accessor: "studentName",
      width: "8%",
      Cell: (tableInfo: any) => {
        return (
          <Link to="/regional/reviewstudent" state={{default:tableInfo.data[tableInfo.row.index]}}>
            {convertcaps(
              tableInfo.data[tableInfo.row.index].studentName 
            )}{" "}
          </Link>
        );
      },
    },
    {
      Header: "Recipe name",
      accessor: "recipeName",
      width: "10%",
      Cell: (tableInfo: any) => {
        return (
          <Link to="/regional/reviewstudent" state={{default:tableInfo.data[tableInfo.row.index]}}>
            {convertcaps(tableInfo.data[tableInfo.row.index].recipeName)}
          </Link>
        );
      },
    },
    {
      Header: "Region",
      accessor: "region",
      width: "10%",
      Cell: (tableInfo: any) => {
        return convertcaps(tableInfo.data[tableInfo.row.index].region);
      },
    },
    {
      Header: "district",
      accessor: "districtName",
      width: "10%",
      Cell: (tableInfo: any) => {
        return convertcaps(tableInfo.data[tableInfo.row.index].districtName || "N/A");
      },
    },
    {
      Header: "School",
      accessor: "school",
      width: "10%",
      Cell: (tableInfo: any) => {
        return convertcaps(tableInfo.data[tableInfo.row.index].school);
      },
    },
    {
      Header: "Status",
      accessor: "status",
      width: "10%",

      Cell: (tableInfo: any) => {
        return tableInfo.data[tableInfo.row.index].status;
      },
    },
    {
      Header: "Score",
      accessor: "score",
      width: "10%",

      Cell: (tableInfo: any) => {
        return tableInfo.data[tableInfo.row.index].score;
      },
    },
  ];
  return COLUMNS;
};
