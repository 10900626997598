import { useNavigate } from "react-router-dom";
import { useStores } from "../../stores";
import { useState } from "react";
import ModalCustom from "../ModalCustome";
import { defaultStorageHelper } from "@spry/campaign-client";
import "./index.css";

function HeaderLogout({ title, children }: { title?: string; children?: any }) {
  const [openModal, setOpenModal] = useState(false);

  const navigate = useNavigate();
  const { authStore } = useStores();

  const logOut = async () => {
    try {
      defaultStorageHelper.remove("multiple");
      await authStore.logout();

      navigate("/login", { replace: true });
    } catch (error) {
      console.error("error signing out: ", error);
    }
  };

  return (
    <header className="header">
      {openModal && (
        <ModalCustom>
          <div
            className="popup popup--alt textCenter"
            style={{
              position: "relative",
              backgroundColor: "#fff",
              paddingTop: "30px",
              paddingBottom: "30px",
            }}>
            <div className="popup__content">
              <h3>Logout?</h3>
              <p>Are you sure you want to logout?</p>
            </div>
            <div
              className="popup__actions"
              style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
              <button type="button" className="btn btn--purple" onClick={() => logOut()}>
                Yes, logout
              </button>

              <button
                type="button"
                className="btn btn--outline"
                onClick={() => {
                  setOpenModal(false);
                }}>
                Cancel
              </button>
            </div>
          </div>
        </ModalCustom>
      )}
      <div className="shell flexHeader">
        <a
          href="https://us.sodexo.com/industry/schools.html"
          rel="noreferrer"
          target="_blank"
          className="logo header__logo">
          <img src="/assets/images/svg/logo.svg" alt="" />
        </a>
        <button
          className="logout_btn"
          onClick={() => {
            defaultStorageHelper.remove("current");
            setOpenModal(true);
          }}>
        <b>Logout</b>
        </button>

        {/* <div className="header__nav js-nav">
          <nav className="nav-utilities">
            <ul>
              <li>
                <Link to="/signup">Sign-up</Link>
              </li>

              <li>
                <Link to="/login">Login</Link>
                 <Outlet /> 
              </li>
            </ul>
          </nav>
        </div> */}

        {/* <a href="#" className="nav-trigger js-nav-trigger">
        <span></span>

        <span></span>

        <span></span>
      </a> */}
      </div>
    </header>
  );
}

export default HeaderLogout;
