import { useEffect, useState } from "react"
import { useNavigate, useLocation, Location } from "react-router-dom"
import { EnumTypes } from "@spry/campaign-client"
import guessedCampaignState from "./guessedCampaignState"
import { getKnownCampaignState, fetchCampaignState } from "./campaignState"

const MaxRedirectsPerSecond = 5

let movedFromAllowedStates: EnumTypes.ValidCampaignState[] = []
let movedFromLocation: Location | null = null
const redirectTimes: number[] = []

const defaultPathMaps: {[x: string]: string} = {
    "closed": "/closed",
    "comingsoon": "/coming",
    "redemptiononly": "/redemption",
    "suspended": "/suspended",
    "open": "/"
}

export default function useRequiredCampaignState(allowedStates?: EnumTypes.ValidCampaignState | EnumTypes.ValidCampaignState[]) {    
    const navigate = useNavigate()
    const location = useLocation()
    const knownCampaignState = getKnownCampaignState()
    
    const [state, setState] = useState((knownCampaignState || guessedCampaignState).state)

    async function fetchState() {
        const campaignState = await fetchCampaignState()

      
        if (campaignState.state !== state) { setState(campaignState.state) } 
    }
    
    useEffect(() => {
        const allowed = !allowedStates ? [] : typeof allowedStates === "string" ? [allowedStates] : allowedStates

       
        
        if (!knownCampaignState) {
            fetchState()
        }
        if (!allowed.length || allowed.includes(state)) {
            return
        }
        const now = Date.now()
        const threshold = now - 1000
        while (redirectTimes.length && redirectTimes[0] < threshold) { redirectTimes.splice(0, 1) }
        redirectTimes.push(now)        
        if (redirectTimes.length > MaxRedirectsPerSecond) {
            console.error(`${redirectTimes.length} redirects have occurred in the past 1 second due to campaign state requirements (likely a configuration problem)`)
            return
        }
    
        if (movedFromLocation && movedFromAllowedStates.includes(state)) {
            navigate(movedFromLocation, { replace: true })
            movedFromLocation = null
            movedFromAllowedStates = []
            return
        }
    
        const to = defaultPathMaps[state]       

    
        if (!to) {
            console.error(`Current campaign state is ${state} but no redirect target has been configured.`)
            return
        }
    
        movedFromAllowedStates = allowed
        movedFromLocation = location
    
        navigate(to, { replace: true })
    }, [])
}