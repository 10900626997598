import "./index.css";
import { I18n } from "aws-amplify";
import { translations } from "@aws-amplify/ui-react";
import { convertcaps } from "../../../helpers/utils";
import { useQuery } from "react-query";
import { campaignClient } from "../../../api";
import { useStores } from "../../../stores";
import Loading from "../../../Components/Loading";
import { useNavigate } from "react-router-dom";
import { defaultStorageHelper } from "@spry/campaign-client";

const Roles = [
  {
    role: "primary",
    title: "District manager",
    path: "/dms",
    roles: [
      "Add secondary manager",
      "Add/edit schools",
      "Add/edit district event details",
      "Add/edit student submissions",
      "Select district competitors",
      "Declare district winners",
    ],
  },
  {
    role: "secondary",
    title: "District manager",
    path: "/dms",
    roles: [
      "Add secondary manager",
      "Add/edit schools",
      "Add/edit district event details",
      "Add/edit student submissions",
      "Select district competitors",
      "Declare district winners",
    ],
  },
  {
    role: "judge",
    title: "Judge",
    path: "/regional",
    roles: ["View students submissions", "Submit student scores"],
  },
  {
    role: "regional manager",
    title: "Regional manager",
    path: "/regional",
    roles: ["View student submissions", "Select regional winners"],
  },
  {
    role: "national manager",
    title: "National manager",
    path: "/national",
    roles: ["View student submissions", "Select national finalists"],
  },
  {
    role: "admin",
    title: "Super Admin",
    path: "/admin",
    roles: [
      "Add/edit users",
      "Add/edit students",
      "Add/edit schools",
      "Add/edit district",
      "Add/remove videos",
      "open/close competition phases",
    ],
  },
];

// district manger - "primary"|"secondary"
// judge - "judge"
// regional manager - "regional
// national-manager - "national
// admin - "admin"

function RoleSelection() {
  const navigate = useNavigate();
  const { authStore, stateStore } = useStores();

  const { isLoading: loadingDistrict, data: rolesData } = useQuery("getUserRoles", async () => {
    const res: { roles: string[] } = await campaignClient.call("getUserRoles", {
      userName: authStore.user.name,
    });

    let result: {
      role: string; //"secondary",
      title: string;
      path: string;
      roles: string[];
    }[] = [];
   if (res.roles.length === 1) {
      const path = Roles.filter((item) => item.role === res.roles[0])[0];
      authStore.setRole(res.roles[0]);
      defaultStorageHelper.set("current", res.roles[0]);
      navigate(path.path);
    } else if (res && res.roles.length > 1) {

      defaultStorageHelper.set("multiple", "true")
      res.roles.forEach((r) => {
        result.push(Roles.filter((item) => item.role === r)[0]);
      });

      return result;
    } else {
      try {
        await authStore.logout();
  
        navigate("/login", { replace: true });
      } catch (error) {
        console.error("error signing out: ", error);
      }
    }
  });

  return (
    <div className="main">
      {loadingDistrict && <Loading />}
      <section className="section section--alt">
        <div className="shell">
          <h3 className="textCenter">
            <b>Login as:</b>
          </h3>

          <br />
          <div className="roles">
            {rolesData &&
              rolesData?.map(({ title, roles, path, role }) => {
                return (
                  <div className="role" key={title}>
                    <h4>
                      <b>{title}</b>
                    </h4>
                    <p className="listTitle">{title} can:</p>
                    <ul className="roleList">
                      {roles.map((item, i) => (
                        <li key={i}>{item}</li>
                      ))}
                    </ul>
                    <button
                      className="form__btn btn btn--purple width100"
                      onClick={(e) => {
                        authStore.setRole(role);
                        defaultStorageHelper.set("current", role);

                        navigate(path);
                      }}>
                      Login as {convertcaps(title)}
                    </button>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </div>
  );
}

export default RoleSelection;
