import React, { useState, useEffect } from "react";
import { campaignClient, getSessionClient, publicCampaignClient } from "../../../api";
import { showToast } from "../../../Components/Toast/ToastManager";
import { defaultStorageHelper } from "@spry/campaign-client";

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

const ResetConfirmation = () => {
  const [disable, setDisable] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    const em = defaultStorageHelper.get("email");
    em && setEmail(em);
  }, []);

  const resendEmail = async () => {
    try {
      const { sessionKey } = getSessionClient();
      const res = await publicCampaignClient.call("resendVerifyEmail", {
        userName:defaultStorageHelper.get('email'),
        reset:true
      });
      if (res) {
        setDisable(true);
        showToast({
          content: "Verification link was successfully resend to " + email,
          duration: 5000,
          error: false,
        });
      } else {
        window.vex.dialog.alert({
          unsafeMessage: "Something went wrong, Please try again later.",
          callback: () => {
            return;
          },
        });
      }
    } catch {
      window.vex.dialog.alert({
        unsafeMessage: "Something went wrong, Please try again later.",
        callback: () => {
          return;
        },
      });
    }
  };
  return (
    <div className="main">
      <div className="hero hero--small">
        <div className="shell">
          <div className="hero__image">
            <img src="/assets/images/hero-image@2x.png" alt="" width={208} />
          </div>
        </div>
      </div>
      <section className="section section--secondary">
        <div className="shell">
          <div className="section__content">
            <h3>
              <b>Check your inbox</b>
            </h3>
            <img src="/assets/images/svg/envelope.svg" alt="" />
            <div className="section__entry">
              <p>To reset your password,click the reset link in the email we've send to {email}</p>

              <span
                onClick={disable ? undefined : resendEmail}
                style={
                  disable
                    ? { cursor: "not-allowed", color: "grey" }
                    : { textDecoration: "underline", cursor: "pointer" }
                }>
                Resend email
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ResetConfirmation;
