import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useRequiredCampaignState } from "../../helpers/campaignStates";
import Footer from "../Footer";
import { useStores } from "../../stores";

import Loading from "../Loading";
import { useAuth } from "../../helpers/hooks";

const LandingLayout = () => {
  useRequiredCampaignState("open");
  const [loading, setLoading] = useState(false);
  const { authStore } = useStores();

  const { isAuth } = useAuth(authStore);

  const navigate = useNavigate();
  useEffect(() => {
    isAuth.auth && navigate("/roleselection");
  }, [isAuth]); 
  return (
    <>
      <div className="wrapper">
        <header className="header">
          <div className="shell textCenter">
            <a
              href="https://us.sodexo.com/industry/schools.html"
              rel="noreferrer"
              target="_blank"
              className="logo header__logo">
              <img src="/assets/images/svg/logo.svg" alt="" />
            </a>

            {/* <div className="header__nav js-nav">
                <nav className="nav-utilities">
                  <ul>
                    <li>
                      <Link to="/signup">Sign-up</Link>
                    </li>

                    <li>
                      <Link to="/login">Login</Link>
                       <Outlet /> 
                    </li>
                  </ul>
                </nav>
              </div> */}

            {/* <a href="#" className="nav-trigger js-nav-trigger">
              <span></span>

              <span></span>

              <span></span>
            </a> */}
          </div>
        </header>
        {loading ? (
          <div style={{ height: "100vh" }}>
            <Loading />
          </div>
        ) : (
          <Outlet />
        )}

        <Footer />
      </div>
    </>
  );
};

export default LandingLayout;
