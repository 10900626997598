import { Routes } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import CustomRoutes from "./Config/Routes";
import { useStores, StoreContext } from "./stores";

import { Auth } from "aws-amplify";
import { setupApiClient } from "./api";
import BaseRoutes from "./Config/BaseRoutes";

function App() {
  setInterval(async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const { refreshToken } = cognitoUser.getSignInUserSession();
      cognitoUser.refreshSession(refreshToken, (err: any, session: any) => {
        setupApiClient(session.idToken.jwtToken);
      });
    } catch (e) {
      throw e;
    }
  }, 1200000);
  const stores = useStores();
  const { route, user } = useAuthenticator((context) => [context.route]);
  if (route === "authenticated" && user) {
    stores.authStore.setup();
  }

  return route === "authenticated" ? (
    <StoreContext.Provider value={stores}>
      <Routes>{CustomRoutes}</Routes>
    </StoreContext.Provider>
  ) : (
    <Routes>{BaseRoutes}</Routes>
  );
}

export default App;
