import React from "react";
import { useLocation } from "react-router-dom";

const AddSchoolConfirmation = () => {
  const { state } = useLocation();
  return (
    <section className="section section--secondary">
      <div className="shell">
        <div className="section__content">
          <h3>School added</h3>
          <img src="/assets/images/svg/check-big.svg" alt="" />
          <div className="section__entry">
            <p>{state} is ready to invite their students to participate.</p>
          </div>
          <div className="section__actions">
            <a href="/addschool" className="btn btn--purple btn--medium">
              Add Another School
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddSchoolConfirmation;
