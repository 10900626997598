import React, { useState, useEffect } from "react";
import { campaignClient, publicCampaignClient } from "../../../api";
import { useRequiredCampaignState } from "../../../helpers/campaignStates";

const Landing = () => {
  const [loading, setLoading] = useState(false);
  const [phase1, setPhase1] = useState("");

  useEffect(() => {
    setLoading(true);
    publicCampaignClient
      .call("getschedule", { phase: "phase1" })
      .then((res: any) => {
        //  console.log("schedule: ", res);
        if (res.state) {
          setPhase1(res.state);
        } else {
          setPhase1("comingsoon");
        }
      })
      .catch((e) => {
        setPhase1("comingsoon");
      });
  }, []);
  return (
    <div className="wrapper">
      <div className="main">
        <div className="hero">
          <div className="shell">
            <div className="hero__image">
              <img src="/assets/images/hero-image@2x.png" alt="" width={536} />
            </div>
          </div>
        </div>
        <section className="section">
          <div className="shell">
            <div className="section__content">
              {/* <h1>Welcome to the district manager judging portal</h1> */}
              <h1>Welcome to the district manager portal</h1>
              <div className="indent-right">
                {/* <p>School registration is now closed.</p> */}
                <p>
                  Please login using your credentials, review the submissions and select the
                  district competitors.
                </p>

                {/* <p>Register your district to participate in the Future Chefs National Challenge. We’re looking for 10-20 students from your area.  (Minimum 5 students)</p> */}
              </div>
              <div className="section__actions">
                {phase1 === "open" && (
                  <a href="/signup" className="btn btn--purple">
                    Sign-up
                  </a>
                )}
                <a href="/login" className="btn btn--purple btn--outline">
                  Login
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Landing;
