import React from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

const VideosColumns = (fns: { setIsDelete: Function; setPlayVideo: Function,setFileName:Function }) => {
  const navigate = useNavigate();
  const COLUMNS = [
    {
      Header: "Video name",
      accessor: "fileName",
    },
    {
      Header: "Finalist",
      accessor: "studentName",
    },
    {
      Header: "Updated",
      accessor: "updateTime",
      Cell: (tableInfo: any) => {
        let updatetime = new Date(tableInfo.data[tableInfo.row.index].updateTime).toLocaleString();
        return updatetime;
      },
    },

    {
      Header: "Actions",
      accessor: "sessionKey",
      Cell: (tableInfo: any) => {
        let videoKey = tableInfo.data[tableInfo.row.index].videoKey;
        let sessionKey = tableInfo.data[tableInfo.row.index].sessionKey;
        return (
          <div className="table__actions">
            <button
              className="noBorder"
              onClick={() => {
                fns.setPlayVideo(tableInfo.data[tableInfo.row.index].videoUrl);
                fns.setFileName(tableInfo.data[tableInfo.row.index].fileName)
              }}>
              <img src="/assets/images/svg/play.svg" alt="edit user" width={25} height={25} />
            </button>
            <button
              className="noBorder"
              onClick={() => {
                navigate("/admin/upload", {
                  state: { default: tableInfo.data[tableInfo.row.index] },
                });
              }}>
              <img src="/assets/images/svg/edit.svg" alt="edit user" width={25} height={25} />
            </button>
            <button
              className="noBorder"
              onClick={() => {
                fns.setIsDelete({ sessionKey, videoKey });
              }}>
              <img src="/assets/images/svg/trash.svg" alt="edit user" width={25} height={25} />
            </button>
          </div>
        );
      },
    },
    // {
    //   Header: "Votes",
    //   accessor: "votes",
    // },
  ];
  return COLUMNS;
};

export default VideosColumns;
