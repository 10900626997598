import React,{useState} from 'react'
import ReactPlayer from 'react-player'

const VideoPlayer = ({url,headtext}:{url:string,headtext:string}) => {
// console.log(controlInput)

// console.log(url)
  return (
    <div className="article__video">
											<div className="video-wrapper">
												<h3>{headtext} </h3>

											<ReactPlayer url={url} playing = {true} loop = {false}  controls={true} width='100%' /> 
											</div>
											
																					
											</div>
  )
}

export default VideoPlayer