import { useState, useEffect, useRef, useMemo } from "react";
import { campaignClient, publicCampaignClient } from "../../../api";
import { useLocation, useNavigate, useParams } from "react-router";
import Modal from "react-modal";
import { convertcaps, disableBrowserHistory } from "../../../helpers/utils";
import * as tus from "tus-js-client";
import VideoPlayer from "../../../Components/VideoPlayer";
import { useStores } from "../../../stores";
import Dropzone from "../../../Components/DropZone";
import "./index.css";
import { useQuery } from "react-query";
import Loading from "../../../Components/Loading";
import { Participant } from "@sprycore/spry-api-client/dist/MainDbReturnTypes";
import { showToast } from "../../../Components/Toast/ToastManager";
import Header from "../../../Components/Header";
import "./index.css";
import ReactPlayer from "react-player";
type GetVideos = {
  html_embed: string;
  player_embed_url: string;
  school: string;
  upload_link: string | null;
  uri: string;
  videoKey: string;
  fileName: string;
  status: number;
  creationDate: string;
  managerName: string;
  email: string;
};
declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

const UploadVideo = () => {
  disableBrowserHistory();
  const { id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const currentVideo = state?.default || undefined;
  const [videoUrl, setVideoUrl] = useState(currentVideo?currentVideo.videoUrl:"");
  const inputEl = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>();
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadingPercent, setUploadingPercent] = useState(0);
  const [uploadStart, setUploadStart] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [videoopen, setVideoOpen] = useState(false);
  const [data, setData] = useState<GetVideos>();
  const [filterTable, setFilterTable] = useState("");

  const [selectedWinner, setSelectedWinner] = useState("");

  const { isLoading: loadingWinners, data: nationalWinners } = useQuery(
    "getSubmissionsv2",
    async () => {
      const res: Participant[] = await campaignClient.call("getSubmissionsv2", {
        winType: "national-finalist",
      });
      return res.length ? res : [];
    },
    {
      onError: (error) => {
        window.vex.dialog.alert({
          unsafeMessage: `Something went wrong, please try again.`,
          callback: () => {
            return;
          },
        });
      },
    }
  );

  const handleDelete = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const result = await campaignClient.call("delvideo", {
        school: id,
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  async function handleUpload2() {
    if (!file || uploading || !selectedWinner) {
      return;
    }
    const fileToUpload = file;
    setUploading(true);
    setUploadingPercent(0);
    setUploadComplete(false);
    try {
      const data = await fileToUpload.arrayBuffer();

      const result: {
        upload_link: string | null;
        player_embed_url: string;
        html_embed: string;
        uri: string;
        videoKey: string;
        error?: string;
      } = await campaignClient.call("getvideouploadurl", {
        fileSize: file.size,
        fileName: file.name,
        sessionKey: selectedWinner,
      });

      if (result.upload_link) {
        setUploadStart(true);

        const upload = new tus.Upload(fileToUpload, {
          endpoint: "https://api.vimeo.com/me/videos",
          uploadUrl: result.upload_link,
          retryDelays: [0, 3000, 5000, 10000, 20000],
          metadata: {
            filename: fileToUpload.name,
            filetype: fileToUpload.type,
          },
          headers: {},
          onError: function (error) {
            console.log("Failed because: " + error);
          },
          onProgress: function (bytesUploaded, bytesTotal) {
            let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
            // console.log(bytesUploaded, bytesTotal, percentage + "%");
            setUploadingPercent(+percentage);
          },
          onSuccess: async function () {
            try {
              const uploadedVideo = await publicCampaignClient.call<GetVideos[]>("getVideos", {
                sessionKey: selectedWinner,
              });
              //  console.log(uploadedVideo);
              if (uploadedVideo[0].videoKey && uploadedVideo[0].player_embed_url) {
                //setData(uploadedVideo);
                showToast({
                  content: "Video has been saved!. ",
                  duration: 3000,
                  error: false,
                });
                navigate("/admin/videos");
              } else {
                window.vex.dialog.alert({
                  unsafeMessage: `Error while uploading video, please try again.`,
                  callback: () => {
                    return;
                  },
                });
              }
            } catch (e) {
              setLoading(false);
              window.vex.dialog.alert({
                unsafeMessage: `Error while uploading video, please try again.`,
                callback: () => {
                  return;
                },
              });
            }
            setUploading(false);
            setLoading(false);
          },
        });
        upload.start();
      } else {
        if (result.error === "DUP_Video") {
          window.vex.dialog.alert({
            unsafeMessage: `There is a video already uploaded for this winner.Please delete and try again!.`,
            callback: () => {
              navigate("/videos");
              return;
            },
          });

          setUploading(false);
          setLoading(false);
        }
      }
      // Start the upload
    } catch (e) {
      window.vex.dialog.alert({
        unsafeMessage: `Something went wrong.Please try again later.`,
        callback: () => {
          return;
        },
      });

      setUploading(false);
      setLoading(false);
    }
  }

  function formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  // console.log(videoUrl)
  const getSchoolVideo = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const result: GetVideos[] = await campaignClient.call("videos", {
        school: id,
      });
      // console.log(result);

      if (result?.length > 0) {
        const PendingVideos = result.filter((video) => video.status === 1);
        const ApprovedVideos = result.filter((video) => video.status === 2);

        if (ApprovedVideos.length > 0) {
          setData(ApprovedVideos[0]);
        } else if (PendingVideos.length > 0) {
          setData(PendingVideos[0]);
        }
      }
      // console.log(result[0].player_embed_url);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <>
      {loading || (loadingWinners && <Loading />)}
      <section className="section section--alt">
        <div className="shell">
          <Header title="Add video" />
          <hr className="underlineHR" />

          {/* /.uploader__head */}

          <div style={{ display: "flex" }}>
            <div className="uploader__actions">
              {videoUrl ? (
                <div className="video-preview">
                  <ReactPlayer
                url={videoUrl}
                playing={true}
                loop={false}
                controls={true}
                width="100%"
              />
                  <br />
                  <div className="video-details">
                    <div className="file-details">
                      <p>{file?.name || currentVideo?.fileName}</p>
                      <p>{file && formatBytes(file.size)}</p>
                    </div>
                    <div>
                      {/* <button>
                        {" "}
                        <img src="/assets/images/svg/play.svg" alt="icon" />
                      </button> */}
                      <button
                        onClick={() => {
                          setFile(null);
                          setVideoUrl("");
                        }}>
                        <img src="/assets/images/svg/trash.svg" alt="" width={20} />
                      </button>

                      {/* {!uploadStart && (
                      <button
                        className="linkButton"
                        onClick={() => {
                          setVideoUrl("");
                          setFile(null);
                        }}>
                        <img
                          src="assets/images/ico-trash.png"
                          alt="delete"
                          height="15px"
                          width="15px"
                          className="trash-img "
                        />
                      </button>
                    )} */}
                    </div>
                  </div>
                </div>
              ) : (
                <Dropzone
                  setFile={(v: any) => setFile(v)}
                  setVideoUrl={(v: any) => setVideoUrl(v)}
                />
              )}
              <label>National Finalist</label>
              <br />
              <select className="field" onChange={(e) => setSelectedWinner(e.currentTarget.value)}>
                <option value="">Select</option>
                {nationalWinners &&
                  nationalWinners.map((w) => (
                    <option
                      key={w.sessionKey}
                      value={w.sessionKey}
                      disabled={w.metadata.videoUrl ? true : false}>
                      {w.firstName &&
                        convertcaps(w?.firstName) + " " + (w.lastName && convertcaps(w.lastName))}
                    </option>
                  ))}
              </select>
              <br />
              <br />
              <div className="action-btns">
                {uploading ? (
                  <button type="button" className="btn modal-btn " disabled={true}>
                    {uploadStart ? uploadingPercent + "% Completed" : "Uploading"}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn modal-btn"
                    onClick={handleUpload2}
                    disabled={!selectedWinner || !file ? true : false}>
                    Add video
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn--outline"
                  style={{ border: "none", margin: "10px" }}
                  onClick={() => navigate("/admin/videos")}
                  disabled={uploadStart ? true : false}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {videoopen && (
        <Modal
          isOpen
          contentLabel="My dialog"
          ariaHideApp={false}
          className="popup"
          portalClassName="modal"
          style={{
            overlay: {
              position: "fixed",
              zIndex: 1043,
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(0,0,0,0.5)",

              //   -webkit-backface-visibility: hidden;
            },
          }}>
          {data && (
            <div className="confirm-modal">
              <div className="modal-close">
                {" "}
                <button onClick={() => setVideoOpen(false)} className="modal-close-btn">
                  <img src="assets/images/svg/close.svg" alt="close" />
                </button>
              </div>
              <div className="popup__content center">
                <VideoPlayer
                  url={data.player_embed_url}
                  headtext={data.fileName ? convertcaps(data.fileName) : ""}
                />
              </div>
            </div>
          )}
          {/* /.popup__content */}
        </Modal>
      )}
    </>
  );
};

export default UploadVideo;
