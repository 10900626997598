import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useOutletContext } from "react-router-dom";
import { campaignClient, publicCampaignClient } from "../../../api";
import { useStores } from "../../../stores";
import Loading from "../../../Components/Loading";
import "./index.css";
import { ACTIONS, convertcaps } from "../../../helpers/utils";
import { disableBrowserHistory } from "../../../helpers/utils";
import Header from "../../../Components/Header";
import ModalCustom from "../../../Components/ModalCustome";
import { showToast } from "../../../Components/Toast/ToastManager";
import { useForm } from "react-hook-form";
import { defaultStorageHelper } from "@spry/campaign-client";
import { useQuery } from "react-query";

interface Score {
  origin: number;
  health: number;
  ease: number;
  kid: number;
  plate: number;
  adapt: number;
}
function EntryDetailsJudge() {
  disableBrowserHistory();
  const navigate = useNavigate();
  const { state } = useLocation();
  const user = useStores().authStore.user;
  const role = user.role || defaultStorageHelper.get("current");
  let studentdetails = state?.default;
  let school_name = studentdetails?.metadata?.schoolId === "other" ? studentdetails?.metadata.otherSchool : studentdetails?.metadata.schoolName

  const [submitting, setSubmitting] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const judgescore = studentdetails?.metadata?.scores
    ? studentdetails?.metadata?.scores.find((s: { judge: string }) => s.judge === user.name)
    : undefined;

  let defaultValues: Score = judgescore
    ? { ...judgescore }
    : {
      adapt: 0,
      ease: 0,
      health: 0,
      kid: 0,
      origin: 0,
      plate: 0,
    };

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<Score>({ mode: "onChange", defaultValues: { ...defaultValues } });
  const formwatch = watch();
  const photoKey = studentdetails?.metadata?.recipe?.photoKey || "";
  const { isLoading: downloadinguserfiles, data: userfile } = useQuery(
    ["getalldistricts", photoKey],

    async () => {
      const res: { downloadUrls: { [key: string]: string } } = await publicCampaignClient.call(
        "upload",
        { s3Key: photoKey }
      );
      return res.downloadUrls[photoKey];
    },
    { enabled: !!photoKey }
  );

  useEffect(() => {
    if (!studentdetails) {
      navigate("/regional/students");
    }
  }, []);
  const [loading, setLoading] = useState(false);

  const { isLoading: loadingphase4, data: phase4 } = useQuery(
    ["getschedule", role],

    async () => {
      const res: { state: string } = await publicCampaignClient.call("getschedule", { phase: "phase4" });

      return res.state === "open" ? true : false;
    },
    { refetchOnWindowFocus: false }
  );
  const { isLoading: loadingnationalFinalists, data: nationalFinalists } = useQuery(
    ["nationalFinalists", role === "national manager"],

    async () => {
      const res: any[] = await campaignClient.call("getSubmissions", {
        winType: "national-finalist",
      });

      return res;
    },
    { refetchOnWindowFocus: false }
  );
  const { isLoading: loadingphase4a, data: phase4a } = useQuery(
    ["getschedulea", role],

    async () => {
      const res:{state:string} = await publicCampaignClient.call("getschedule", { phase: "phase4a" });

      return res.state === "open" ? true : false;
    },
    { refetchOnWindowFocus: false }
  );
  const { isLoading: loadingphase4b, data: phase4b } = useQuery(
    ["getscheduleb", role],

    async () => {
      const res:{state:string} = await publicCampaignClient.call("getschedule", { phase: "phase4b" });

      return res.state === "open" ? true : false;
    },
    { refetchOnWindowFocus: false }
  );


  async function handledeclare(status: string) {
    if (submitting) {
      return;
    }
    setIsOpen(false);
    setSubmitting(true);
    try {
      const result = await campaignClient.call("submission", {
        sessionKey: studentdetails.sessionKey,
        action: status,
      });
      if (result) {
        showToast({
          content: "Student selected as regionsl winner.",
          duration: 3000,
          error: false,
        });
        navigate("/regional/students");
      }
    } catch (e) {
      setSubmitting(false);
    }
    setSubmitting(false);
  }

  async function submitscore(data: Score) {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    try {
      const { result, error, message } = await campaignClient.call<{
        result: boolean;
        error: string;
        message: string;
      }>("score", {
        sessionKey: studentdetails.sessionKey,
        judge: user.name,
        score: { ...data },
      });
      if (result) {
        setSubmitting(false);
        navigate("/regional/confirmsubmission", {
          state: {
            score: Object.values(data).reduce((a, b) => +a + +b, 0),
            student: studentdetails?.firstName + " " + studentdetails?.lastName,
          },
        });
      }
      if (error) {
        showToast({
          content: `${message}`,
          duration: 3000,
          error: false,
        });
      }
    } catch (e) {
      showToast({
        content: "Something went wrong!",
        duration: 3000,
        error: false,
      });
    }
  }
  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <>
      {(submitting ||
        loadingnationalFinalists ||
        downloadinguserfiles ||
        loadingphase4b ||
        loadingphase4a ||
        loadingphase4) && <Loading />}

      <section className="section section--alt judge">
        <div className="shell">
          <Header title="Review student" />
          <hr className="underlineHR" />
          <div className="shell">
            <div className="section__inner section-submission">
              <div className="section__body">
                <div className="section__content">
                  <h6 style={{ marginTop: "20px" }}>Student details</h6>

                  <div className="table-student">
                    <table>
                      <tbody>
                        <tr>
                          <td>Student first name</td>
                          <td>{convertcaps(studentdetails?.firstName || "")}</td>
                        </tr>

                        <tr>
                          <td>Student last name</td>
                          <td>{convertcaps(studentdetails?.lastName || "")}</td>
                        </tr>

                        <tr>
                          <td>Student email</td>

                          <td>{convertcaps(studentdetails?.metadata?.studentEmail || "")}</td>
                        </tr>

                        <tr>
                          <td>Parent/Guardian first name</td>

                          <td>{convertcaps(studentdetails?.metadata?.parent?.firstName || "")}</td>
                        </tr>

                        <tr>
                          <td>Parent/Guardian last name</td>

                          <td>{convertcaps(studentdetails?.metadata?.parent?.lastName || "")}</td>
                        </tr>

                        <tr>
                          <td>Parent/Guardian email</td>

                          <td>{convertcaps(studentdetails?.email || "")}</td>
                        </tr>

                        <tr>
                          <td>District</td>

                          <td>{convertcaps(studentdetails?.metadata?.districtName || "")}</td>
                        </tr>

                        <tr>
                          <td>School</td>

                          <td>{convertcaps(school_name || "")}</td>
                        </tr>

                        <tr>
                          <td>Grade</td>

                          <td>{convertcaps(studentdetails?.metadata?.grade || "")}</td>
                        </tr>

                        <tr>
                          <td>Region</td>

                          <td>{convertcaps(studentdetails?.metadata?.region || "N/A")}</td>
                        </tr>
                        <tr>
                          <td>State</td>

                          <td>{convertcaps(studentdetails?.metadata?.state || "N/A")}</td>
                        </tr>

                        <tr>
                          <td>Parental form submitted</td>

                          <td>{studentdetails?.metadata?.release ? "Yes" : "No"}</td>
                        </tr>

                        <tr>
                          <td>Selected for district competition</td>

                          <td>{studentdetails?.metadata?.status >= 2 ? "Yes" : "No"}</td>
                        </tr>
                        <tr>
                          <td>Shirt size</td>

                          <td>
                            {studentdetails?.metadata?.release
                              ? studentdetails.metadata?.shirtSize
                              : "N/A"}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div className="recipe">
                      <div className="recipe__head">
                        <h6 className="recipe__subtitle">Recipe</h6>
                        {userfile && <img src={userfile} alt="pic2" width={250} />}

                        <h3 className="recipe__name">{studentdetails?.metadata?.recipe?.name}! </h3>
                        {/* /.recipe__name */}
                      </div>
                      {/* /.recipe__head */}

                      <div className="recipe__group">
                        <h6>Ingredients</h6>

                        {studentdetails?.metadata?.recipe?.ingredients?.map((ing: string) => (
                          <li key={ing}>{ing}</li>
                        ))}
                      </div>
                      {/* /.recipe__group */}

                      <div className="recipe__group">
                        <h6>Preparation method</h6>
                        {/* {studentdetails?.metadata.recipe.preparationMethod} */}
                        {studentdetails?.metadata?.recipe?.preparationMethod

                          .filter((line: string, index: number) => {
                            return line.length > 0;
                          })
                          .map((line: string, index: number) => {
                            return (
                              <li key={index} style={{ listStyle: "none" }}>
                                <p style={{ marginBottom: "5px" }}>
                                  <strong>Step {index + 1}</strong>
                                </p>
                                <p style={{ marginBottom: "15px" }}>{line.length > 0 && line}</p>
                              </li>
                            );
                          })}
                      </div>
                      {/* /.recipe__group */}
                    </div>
                  </div>

                  {/* /.table */}
                </div>
                <div className="section__aside">
                  {role === "judge" && (
                    <>
                      <h3>Judges score card</h3>
                      <div className="form__section">
                        <div className="table-student">
                          <form onSubmit={handleSubmit(submitscore)}>
                            <table className="score-table">
                              <tr>
                                <th>Category</th>
                                <th>Score</th>
                              </tr>
                              <tr>
                                <td>Originality</td>
                                <td>
                                  <input
                                    {...register(`origin`)}
                                    type="number"
                                    min={0}
                                    max={10}
                                    step={1}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Healthy Attributes</td>
                                <td>
                                  <input
                                    {...register(`health`)}
                                    type="number"
                                    min={0}
                                    max={10}
                                    step={1}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Ease of Preparation</td>
                                <td>
                                  <input
                                    {...register(`ease`)}
                                    type="number"
                                    min={0}
                                    max={10}
                                    step={1}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Kid Friendliness</td>
                                <td>
                                  <input
                                    {...register(`kid`)}
                                    type="number"
                                    min={0}
                                    max={10}
                                    step={1}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Plate Presentation</td>
                                <td>
                                  <input
                                    {...register(`plate`)}
                                    type="number"
                                    min={0}
                                    max={10}
                                    step={1}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Adaptability as a School Lunch</td>
                                <td>
                                  <input
                                    {...register(`adapt`)}
                                    type="number"
                                    min={0}
                                    max={10}
                                    step={1}
                                  />
                                </td>
                              </tr>
                              <tr style={{ backgroundColor: "#F4F4F4" }}>
                                <th> Total score</th>
                                <th style={{ textAlign: "center" }}>
                                  {+formwatch.plate +
                                    +formwatch.origin +
                                    +formwatch.kid +
                                    +formwatch.health +
                                    +formwatch.ease +
                                    +formwatch.adapt}
                                </th>
                              </tr>
                            </table>
                            {!judgescore && phase4 && (
                              <div className="form__actions">
                                {submitting ? (
                                  <button
                                    type="submit"
                                    disabled
                                    className="form__btn btn btn--purple">
                                    <i className="fas fa-circle-notch fa-spin"></i>
                                  </button>
                                ) : (
                                  <button type="submit" className="form__btn btn btn--purple">
                                    Submit
                                  </button>
                                )}
                              </div>
                            )}
                          </form>
                        </div>
                      </div>
                    </>
                  )}
                  {(role === "regional manager" || "national manager") && (
                    <>
                      <>
                        <h3> Scores</h3>
                        <div className="form__section">
                          <div className="table-student">
                            <form onSubmit={handleSubmit(submitscore)}>
                              <table className="score-table">
                                <tr>
                                  <th>Judge</th>
                                  <th>Score</th>
                                </tr>
                                {studentdetails?.metadata?.scores?.map((s: any) => (
                                  <tr>
                                    <td>{s.judge}</td>
                                    <td style={{ textAlign: "center" }}>
                                      {+s.plate +
                                        +s.origin +
                                        +s.kid +
                                        +s.health +
                                        +s.ease +
                                        +s.adapt}
                                    </td>
                                  </tr>
                                ))}

                                <tr style={{ backgroundColor: "#F4F4F4" }}>
                                  <th> Total score</th>
                                  <th style={{ textAlign: "center" }}>
                                    {studentdetails?.metadata?.scores
                                      ?.map(
                                        (s: any) =>
                                          +s.plate +
                                          +s.origin +
                                          +s.kid +
                                          +s.health +
                                          +s.ease +
                                          +s.adapt
                                      )
                                      .reduce((a: number, b: number) => +a + +b, 0)}
                                  </th>
                                </tr>
                              </table>
                              <div className="form__actions">
                                {submitting ? (
                                  <button
                                    type="submit"
                                    disabled
                                    className="form__btn btn btn--purple">
                                    <i className="fas fa-circle-notch fa-spin"></i>
                                  </button>
                                ) : (
                                  <>
                                    {role === "regional manager" && (
                                      <button
                                        type="button"
                                        className="form__btn btn btn--purple"
                                        disabled={
                                          studentdetails?.metadata?.status >= 7 ||
                                          !phase4a
                                        }
                                        onClick={() => {
                                          setIsOpen(true);
                                        }}>
                                        Declare as Regional Winner
                                      </button>
                                    )}
                                    {role === "national manager" && (
                                      <button
                                        type="button"
                                        className="form__btn btn btn--purple"
                                        disabled={
                                          studentdetails?.metadata?.status >= 8 ||
                                          !phase4b
                                        }
                                        onClick={() => {
                                          setIsOpen(true);
                                        }}>
                                        Declare as National Finalist
                                      </button>
                                    )}
                                  </>
                                )}
                              </div>
                            </form>
                          </div>
                        </div>
                      </>
                    </>
                  )}
                </div>

                {/* /.section__aside */}
              </div>
              {/* /.section__body */}
            </div>
            {/* /.section__inner */}
          </div>
          {/* /.shell */}
        </div>
      </section>

      {/* /.section */}
      {isOpen && (
        <ModalCustom>
          <div
            className="popup popup--alt textCenter"
            style={{
              position: "relative",
              backgroundColor: "#fff",
              padding: "45px",
            }}>
            <div className="modal-close">
              {" "}
              <button
                onClick={closeModal}
                className="modal-close-btn"
                style={{ background: "transparent" }}>
                <img src="/assets/images/svg/close.svg" alt="close" />
              </button>
            </div>
            <div className="center">
              {+studentdetails?.metadata?.status == 4 && (
                <>
                  <h3>Declare as the regional winner?</h3>
                  <p> Are you sure you want to select this submission as the regional winner? </p>
                  <button className="btn modal-btn" onClick={() => handledeclare("regional-winner")}>
                    Select as regional winner
                  </button>
                  <button
                    className="btn cancel-btn"
                    onClick={() => {
                      setIsOpen(false);
                    }}>
                    CANCEL
                  </button>
                </>
              )}
              {+studentdetails?.metadata?.status === 7 && nationalFinalists && (
                <>
                  <h3>Declare as a National Finalist?</h3>
                  <p>
                    {" "}
                    Are you sure you want to declare this submission as a national finalist? Once
                    the submission is declared as a National Finalist, this action can not be
                    reversed.{" "}
                  </p>
                  <p>
                    {" "}
                    Current number of national finalists declared: {nationalFinalists.length}/5
                  </p>

                  <button className="btn modal-btn" onClick={() => handledeclare("national-finalist")}>
                    Select as Natinal Finalist
                  </button>
                  <button
                    className="btn cancel-btn"
                    onClick={() => {
                      setIsOpen(false);
                    }}>
                    CANCEL
                  </button>
                </>
              )}
            </div>
          </div>
        </ModalCustom>
      )}
    </>
  );
}

export default EntryDetailsJudge;
