import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useStores } from "../../../stores";
import { campaignClient, publicCampaignClient } from "../../../api";
import { useQuery } from "react-query";
import {
  americanZipCodeRegex,
  convertcaps,
  emailRegex,
  looseNorthAmericanPhoneRegex,
} from "../../../helpers/utils";
import { showToast } from "../../../Components/Toast/ToastManager";
import Header from "../../../Components/Header";
import Loading from "../../../Components/Loading";
import { defaultStorageHelper } from "@spry/campaign-client";

type SchoolSignup = {
  schoolId?: string;
  schoolName: string;
  address: string;
  address1?: string;
  city: string;
  zip: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  participating?: boolean;
  host: number;
  district: string;
  contact: {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
  };
};
declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

const EditSchoolAdmin = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { state } = useLocation();
  let user = useStores().authStore.user;
  const role = user.role || defaultStorageHelper.get("current")

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SchoolSignup>({ defaultValues: { ...state, host: state.host ? 1 : 0 } });

  const { isLoading: loadingDistricts, data: districts } = useQuery(
    "getalldistricts",

    async () => {
      if (role === "admin") {
        const res: { districts: any[] } = await campaignClient.call("getDistricts", {});
        return res.districts;
      } else {
        const res: { districts: any[] } = await campaignClient.call("getDistricts", {
          userName: user.name,
        });
        return res.districts;
      }
    },

    {
      onError: (error) => {
        window.vex.dialog.alert({
          unsafeMessage: `Something went wrong, please try again.`,
          callback: () => {
            return;
          },
        });
      },
    }
  );

  const handleSave = async (data: SchoolSignup) => {
    try {
      setLoading(true);
      const res: any = await campaignClient.call("saveschool", {
        ...data,
        schoolId: state.schoolId,
        host: +data.host === 1 ? true : false,
      });
      if (res.schoolId) {
        showToast({
          content: "School saved successfully.",
          duration: 3000,
          error: false,
        });
        setLoading(false);
        if (window.location.pathname.includes("/admin")) {
          navigate("/admin/schools");
        } else {
          navigate("/dms/schoolConfirmation");
        }
      }
      if (res.error) {
        if (res.error === "HOST_SCHOOL_EXIST") {
          window.vex.dialog.alert({
            unsafeMessage:
              "There is already a school selected as the district host. To change the host deselect the original host and select a new one.",
            callback: () => {
              setLoading(false);
              return;
            },
          });
        } else {
          if (res.error && res.message) {
            window.vex.dialog.alert({
              unsafeMessage: res.message,
              callback: () => {
                setLoading(false);
                return;
              },
            });
          }
        }
      }
      setLoading(false);
    } catch (error) {
      window.vex.dialog.alert({
        unsafeMessage: "Something went wrong, please try again.",
        callback: () => {
          setLoading(false);
          return;
        },
      });
    }
  };
  if (loadingDistricts) {
    return <Loading />;
  }

  if (state.schoolId) {
    return (
      <section className="section section--alt">
        <div className="shell">
          <Header title="Edit school" />
          <hr className="underlineHR" />{" "}
          <div className="shell">
            <div className="form form--school" style={{ float: "left", marginTop: "30px" }}>
              <form onSubmit={handleSubmit(handleSave)}>
                <div className="form__head">
                  <h6>School info</h6>
                  {/* <div className="form__entry">
                <p>
                  Add the details for a participating school from your district.
                </p>
              </div> */}
                  <span className="form__notice">* Required field</span>
                </div>
                <div className="form__body">
                  <div className="form__section">
                    <div className="form__row">
                      <div className="form__cols">
                        <div className="form__col form__col--1of2">
                          <label className="form__label" htmlFor="school_name">
                            * School name
                          </label>
                          <div className="form__controls">
                            <input
                              className="field"
                              placeholder="School name"
                              {...register("schoolName", {
                                required: {
                                  value: true,
                                  message: "Please enter your school name.",
                                },
                                minLength: {
                                  value: 2,
                                  message: "Plase enter a valid school name.",
                                },
                              })}
                            />
                          </div>
                          {errors.schoolName && (
                            <p className="Registration-error">
                              <i className="fas fa-exclamation-circle" />{" "}
                              {errors.schoolName.message}
                            </p>
                          )}
                        </div>
                        <div className="form__col form__col--1of2">
                          <label className="form__label" htmlFor="address_1">
                            * District
                          </label>
                          <div className="form__controls">
                            <select
                              className="field"
                              {...register("district", {
                                required: {
                                  value: true,
                                  message: "Please select your district.",
                                },
                              })}>
                              <option value="">Select</option>
                              {districts?.map((district) => {
                                return (
                                  <option value={district.district}>
                                    {convertcaps(district.name)}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          {errors.district && (
                            <p className="Registration-error">
                              <i className="fas fa-exclamation-circle" /> {errors.district.message}
                            </p>
                          )}
                        </div>

                        <div className="form__col form__col--1of2">
                          <label className="form__label" htmlFor="address_1">
                            * Address 1
                          </label>
                          <div className="form__controls">
                            <input
                              className="field"
                              placeholder="Address 1"
                              {...register("address", {
                                required: {
                                  value: true,
                                  message: "Please enter your address.",
                                },
                                minLength: { value: 2, message: "Plase enter a valid address" },
                              })}
                            />
                          </div>
                          {errors.address && (
                            <p className="Registration-error">
                              <i className="fas fa-exclamation-circle" /> {errors.address.message}
                            </p>
                          )}
                        </div>
                        <div className="form__col form__col--1of2">
                          <label className="form__label" htmlFor="address_2">
                            Address 2
                          </label>
                          <div className="form__controls">
                            <input
                              className="field"
                              placeholder="Address 2"
                              {...register("address1")}
                            />
                          </div>
                        </div>
                        <div className="form__col form__col--1of2">
                          <label className="form__label" htmlFor="city">
                            * City
                          </label>
                          <div className="form__controls">
                            <input
                              className="field"
                              placeholder="City"
                              {...register("city", {
                                required: {
                                  value: true,
                                  message: "Please enter your city.",
                                },
                                minLength: { value: 2, message: "Plase enter a valid city" },
                              })}
                            />
                          </div>
                          {errors.city && (
                            <p className="Registration-error">
                              <i className="fas fa-exclamation-circle" /> {errors.city.message}
                            </p>
                          )}
                        </div>
                        <div className="form__col form__col--1of2">
                          <label className="form__label" htmlFor="zip_code">
                            * ZIP code
                          </label>
                          <div className="form__controls">
                            <input
                              className="field"
                              placeholder="ZIP code"
                              {...register("zip", {
                                required: {
                                  value: true,
                                  message: "Please enter your zip code.",
                                },
                                pattern: {
                                  value: americanZipCodeRegex,
                                  message: "Please enter a valid zip code.",
                                },
                              })}
                            />
                          </div>
                          {errors.zip && (
                            <p className="Registration-error">
                              <i className="fas fa-exclamation-circle" /> {errors.zip.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form__section">
                    <div className="form__entry form__entry--alt">
                      <h6>Site contact details (optional)</h6>
                    </div>
                    <div className="form__row">
                      <div className="form__cols">
                        <div className="form__col form__col--1of2">
                          <label className="form__label" htmlFor="first_name">
                            First name
                          </label>
                          <div className="form__controls">
                            <input
                              className="field"
                              placeholder="First name"
                              {...register("contact.firstName")}
                            />
                          </div>
                        </div>
                        <div className="form__col form__col--1of2">
                          <label className="form__label" htmlFor="last_name">
                            Last name
                          </label>
                          <div className="form__controls">
                            <input
                              className="field"
                              placeholder="Last name"
                              {...register("contact.lastName")}
                            />
                          </div>
                        </div>
                        <div className="form__col form__col--1of2">
                          <label className="form__label" htmlFor="email">
                            Email
                          </label>
                          <div className="form__controls">
                            <input
                              className="field"
                              placeholder="Email"
                              {...register("contact.email", {
                                required: false,
                                pattern: {
                                  value: emailRegex,
                                  message: "Please enter a valid email.",
                                },
                              })}
                            />
                          </div>
                          {errors.contact?.email && (
                            <p className="Registration-error">
                              <i className="fas fa-exclamation-circle" />{" "}
                              {errors.contact?.email.message}
                            </p>
                          )}
                        </div>
                        <div className="form__col form__col--1of2">
                          <label className="form__label" htmlFor="phone_number">
                            Phone number
                          </label>
                          <div className="form__controls">
                            <input
                              className="field"
                              placeholder="Phone number"
                              {...register("contact.phone", {
                                required: {
                                  value: false,
                                  message: "Please enter your email.",
                                },
                                pattern: {
                                  value: looseNorthAmericanPhoneRegex,
                                  message: "Please enter a valid phone number.",
                                },
                              })}
                            />
                          </div>
                          {errors.contact?.phone && (
                            <p className="Registration-error">
                              <i className="fas fa-exclamation-circle" />{" "}
                              {errors.contact?.phone.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form__col form__col--1of2">
                      <p>
                        <b>Participation</b>
                      </p>
                      <label className="form__label" htmlFor="host">
                        * Status
                      </label>
                      <div className="form__controls">
                        <select
                          className="field"
                          {...register("host", {
                            required: {
                              value: true,
                              message: "Please select a status.",
                            },
                          })}>
                          <option value="">Select</option>
                          <option value={0}>Participating</option>
                          <option value={1}>Host</option>
                        </select>
                      </div>
                      {errors.host && (
                        <p className="Registration-error">
                          <i className="fas fa-exclamation-circle" /> {errors.host.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form__actions">
                  {loading ? (
                    <button type="submit" disabled className="form__btn btn btn--purple">
                      <i className="fas fa-circle-notch fa-spin"></i>
                    </button>
                  ) : (
                    <button type="submit" className="form__btn btn btn--purple">
                      Submit
                    </button>
                  )}
                  <button
                    type="button"
                    className="form__btn btn btn--outline"
                    onClick={() => {
                      window.location.pathname.includes("/admin")
                        ? navigate("/admin/schools")
                        : navigate("/dms/schools");
                    }}>
                    Back
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  } else return <></>;
};

export default EditSchoolAdmin;
