import React from "react";
import AuthStore from "./AuthStore";
import StateStore from "./StateStore";

class RootStore {
  public authStore;
  public stateStore;

  constructor() {
    this.authStore = new AuthStore();
    this.stateStore =new StateStore()
  }
}

export const StoreContext = React.createContext(new RootStore());

export const useStores = () => React.useContext(StoreContext);
