import { useQuery } from "react-query";
import { campaignClient, getSessionClient, publicCampaignClient } from "../../../api";
import { useLocation, useParams } from "react-router-dom";
import HeaderLogout from "../../../Components/HeaderLogout";

const ComeBackLater = () => {
  // const { sk } = useParams();
  // const { search } = useLocation();
  // const token = search.split("t=")[1];
  // const { isLoading: loadingVerify, data: districtDetails } = useQuery("verify", async () => {
  //   const res = await publicCampaignClient.call("verify", {
  //     sessionKey: sk,
  //     token,
  //   });

  //   return res;
  // });

  return true ? (
    <div className="wrapper">
      <div className="main">
      <HeaderLogout />
        <div className="hero">
          <div className="shell">
            <div className="hero__image">
              <img src="/assets/images/hero-image@2x.png" alt="" width={536} />
            </div>
          </div>
        </div>
        <section className="section">
          <div className="shell">
            <div className="section__content textCenter">
              {/* <h1>Welcome to the district manager judging portal</h1> */}
              <h2>
                <b>Please come back later.</b>
              </h2>
              <div className="indent-right">
                {/* <p>School registration is now closed.</p> */}
                <p>
                  Please come back to the Sodexo Future Chefs district manager portal to
                  begin adding schools to your district.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ComeBackLater;
