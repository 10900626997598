import { useFieldArray, useForm } from "react-hook-form";

import {
  campaignClient,
  getSessionClient,
  publicCampaignClient,
  setSessionClient,
} from "../../../api";
import { useNavigate } from "react-router-dom";
import { convertcaps, emailRegex } from "../../../helpers/utils";
import { useQuery } from "react-query";
import Loading from "../../../Components/Loading";
import { defaultStorageHelper } from "@spry/campaign-client";
import { useEffect, useState } from "react";
import { showToast } from "../../../Components/Toast/ToastManager";

interface UserSignUp {
  firstName: string;
  lastName: string;
  email: string;
  district: string;
  terms: boolean;
  confirmEmail: string;
  secondary: User[];
}

export type District = {
  city: string;
  zip: string;
  district: string;
  districtName: string;
  address: string;
  school_sites: string;
  region: string;
  name: string;
  address1: string;
  state: string;
};

interface User {
  firstName: string;
  lastName: string;
  email: string;
  confirmEmail: string;
}

type ReturnUser = {
  result: string;
  code: string;
  error: string;
  message: string;
  sessionKey: string;
  previousUser?: boolean;
};

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

const Signup = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [phase1, setPhase1] = useState("");

  useEffect(() => {
    setLoading(true);
    publicCampaignClient
      .call("getschedule", { phase: "phase1" })
      .then((res: any) => {
        //  console.log("schedule: ", res);
        if (res.state !== "open") {
          navigate("/dms");
        }
      })
      .catch((e) => {
        navigate("/dms");
      });
  }, []);

  const {
    register,
    handleSubmit,
    getValues,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<UserSignUp>({
    defaultValues: {
      secondary: [],
    },
  });

  const { fields, append, prepend, remove, swap, move, insert, replace } = useFieldArray({
    control,
    name: "secondary",
  });

  const { isLoading: loadingDistrict, data: districtDetails } = useQuery(
    "getDistricts",
    async () => {
      const res: { districts: District[] } = await publicCampaignClient.call("getDistricts", {});
      const sortedDistricts = res.districts.sort((a, b) =>
        a.districtName.toString().localeCompare(b.districtName.toString())
      );
      return sortedDistricts;
    }
  );
  const divider = "-/-";

  const handleRegister = async (data: UserSignUp) => {
    try {
      const [district, region] = data.district.split(divider);
      const { code, error, message, sessionKey, previousUser } =
        await publicCampaignClient.call<ReturnUser>("signupUser", {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          district,
          region,
          phase: "1a",
          userType: "primary",
          sessionKey: getSessionClient().__sessionKey,
          rules: data.terms,
        });
      if (error && message) {
        window.vex.dialog.alert({
          unsafeMessage: message,
          callback: () => {
            return;
          },
        });
      }
      if (sessionKey) {
        if (data.secondary.length > 0) {
          try {
            const { code, error, message } = await publicCampaignClient.call<ReturnUser>(
              "signupUser",
              {
                firstName: data.secondary[0].firstName,
                lastName: data.secondary[0].lastName,
                email: data.secondary[0].email,
                district,
                region,
                managerName: data.firstName + " " + data.lastName,
                phase: "1a",
                userType: "secondary",
                sessionKey: getSessionClient().__sessionKey,
              }
            );

            if (error && message) {
              window.vex.dialog.alert({
                unsafeMessage: `secondary user: ${message}`,
                callback: () => {
                  return;
                },
              });
            }
          } catch (e) {
            console.log(e);
          }
         
        }
        setSessionClient(campaignClient.recoverSession(sessionKey));
        defaultStorageHelper.set("email", data.email);
        if (!previousUser) {
          navigate("/signupcheck");
        } else {
          showToast({
            content: "User has been added, Please login to add schools",
            duration: 3000,
            error: false,
          });
          navigate("/login");
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className="wrapper">
      {loadingDistrict && <Loading />}
      <div className="main">
        <section className="section section--alt">
          <div className="shell">
            <div className="form form-sign-up">
              <form onSubmit={handleSubmit(handleRegister)}>
                <div className="form__head">
                  <h3>Sign up</h3>
                  <hr className="underlineHR" />
                  <div className="form__entry">
                    <p>
                      Please provide your info below to register as a district manager. You can also
                      register additional contacts to perform the same duties as a district manager.
                      (They will receive their own login credentials in an email)
                    </p>
                  </div>
                  <br />
                  <br />

                  <span className="form__notice">* Required field</span>
                </div>
                <div className="form__body">
                  <div className="form__row">
                    <div className="form__cols">
                      <div className="form__col form__col--1of2">
                        <label className="form__label" htmlFor="first_name">
                          * First name
                        </label>
                        <div className="form__controls">
                          <input
                            className="field"
                            placeholder="First name"
                            {...register("firstName", {
                              required: {
                                value: true,
                                message: "Please enter your first name.",
                              },
                            })}
                          />
                        </div>
                        {errors.firstName && (
                          <p className="Registration-error">
                            <i className="fas fa-exclamation-circle" /> {errors.firstName.message}
                          </p>
                        )}
                      </div>
                      <div className="form__col form__col--1of2">
                        <label className="form__label" htmlFor="last_name">
                          * Last name
                        </label>
                        <div className="form__controls">
                          <input
                            className="field"
                            placeholder="Last name"
                            {...register("lastName", {
                              required: {
                                value: true,
                                message: "Please enter your last name.",
                              },
                            })}
                          />
                        </div>
                        {errors.lastName && (
                          <p className="Registration-error">
                            <i className="fas fa-exclamation-circle" /> {errors.lastName.message}
                          </p>
                        )}
                      </div>
                      <div className="form__col form__col--1of2">
                        <label className="form__label" htmlFor="email">
                          * Email
                        </label>
                        <div className="form__controls">
                          <input
                            className="field"
                            placeholder="Email"
                            {...register("email", {
                              required: {
                                value: true,
                                message: "Please enter your email.",
                              },
                              pattern: {
                                value: emailRegex,
                                message: "Please enter a valid email.",
                              },
                            })}
                          />
                        </div>
                        {errors.email && (
                          <p className="Registration-error">
                            <i className="fas fa-exclamation-circle" /> {errors.email.message}
                          </p>
                        )}
                      </div>

                      <div className="form__col form__col--1of2">
                        <label className="form__label" htmlFor="last_name">
                          * Confirm email
                        </label>
                        <div className="form__controls">
                          <input
                            className="field"
                            placeholder="Confirm email"
                            {...register("confirmEmail", {
                              required: {
                                value: true,
                                message: "Please enter your confirmation email.",
                              },
                              validate: (val: string) => {
                                if (watch("email") != val) {
                                  return "Please make sure your email matches.";
                                }
                              },
                            })}
                          />
                        </div>
                        {errors.confirmEmail && (
                          <p className="Registration-error">
                            <i className="fas fa-exclamation-circle" />{" "}
                            {errors.confirmEmail.message}
                          </p>
                        )}
                      </div>
                      <div className="form__col form__col--1of2">
                        <label className="form__label" htmlFor="district">
                          * District
                        </label>

                        <div className="form__controls">
                          <div className="select js-select">
                            <select
                              id="district"
                              data-width="100%"
                              data-placeholder="District"
                              style={{ paddingTop: "0.8rem" }}
                              {...register("district", {
                                required: {
                                  value: true,
                                  message: "Please select your district.",
                                },
                              })}>
                              <option value="">Select district</option>
                              {districtDetails &&
                                districtDetails?.map((v: District) => (
                                  <option
                                    value={v.districtName + divider + v.region}
                                    key={v.districtName}>
                                    {v.districtName && convertcaps(v.districtName)}
                                  </option>
                                ))}
                            </select>
                            <i className="fas fa-angle-down"></i>
                          </div>
                          {errors.district && (
                            <p className="Registration-error">
                              <i className="fas fa-exclamation-circle" /> {errors.district.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form__row">
                    <div className="form__controls">
                      <div className="checkbox">
                        <input
                          type="checkbox"
                          id="checkbox_terms"
                          {...register("terms", {
                            required: {
                              value: true,
                              message: "You must agree to the Terms & Conditions to register.",
                            },
                          })}
                        />
                        <label htmlFor="checkbox_terms">
                          I agree to the{" "}
                          <a href="https://us.sodexo.com/legal-privacy.html" target="_blank">
                            Terms and Conditions
                          </a>
                          .
                        </label>
                        {errors.terms && (
                          <p className="Registration-error">
                            <i className="fas fa-exclamation-circle" /> {errors.terms.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* {error ? <p className="Registration-error"> {error} </p> : ""} */}

                {fields.map((item, index) => {
                  return (
                    <li key={item.id} style={{ listStyle: "none", marginTop: "20px" }}>
                      <p>Additional district contact registration</p>
                      <div className="form__row">
                        <div className="form__cols">
                          <div className="form__col form__col--1of2">
                            <label className="form__label" htmlFor="first_name">
                              * First name
                            </label>
                            <div className="form__controls">
                              <input
                                className="field"
                                placeholder="First name"
                                {...register(`secondary.${index}.firstName`, {
                                  required: {
                                    value: true,
                                    message: "Please enter your first name.",
                                  },
                                })}
                              />
                            </div>
                            {errors?.secondary && errors.secondary[index]?.firstName && (
                              <p className="Registration-error">
                                <i className="fas fa-exclamation-circle" />{" "}
                                {errors.secondary[index]?.firstName?.message}
                              </p>
                            )}
                          </div>
                          <div className="form__col form__col--1of2">
                            <label className="form__label" htmlFor="last_name">
                              * Last name
                            </label>
                            <div className="form__controls">
                              <input
                                className="field"
                                placeholder="Last name"
                                {...register(`secondary.${index}.lastName`, {
                                  required: {
                                    value: true,
                                    message: "Please enter your last name.",
                                  },
                                })}
                              />
                            </div>
                            {errors?.secondary && errors.secondary[index]?.lastName && (
                              <p className="Registration-error">
                                <i className="fas fa-exclamation-circle" />{" "}
                                {errors.secondary[index]?.lastName?.message}
                              </p>
                            )}
                          </div>
                          <div className="form__col form__col--1of2">
                            <label className="form__label" htmlFor="email">
                              * Email
                            </label>
                            <div className="form__controls">
                              <input
                                className="field"
                                placeholder="Email"
                                {...register(`secondary.${index}.email`, {
                                  required: {
                                    value: true,
                                    message: "Please enter your email.",
                                  },
                                  pattern: {
                                    value: emailRegex,
                                    message: "Please enter a valid email.",
                                  },
                                })}
                              />
                            </div>
                            {errors?.secondary && errors.secondary[index]?.email && (
                              <p className="Registration-error">
                                <i className="fas fa-exclamation-circle" />{" "}
                                {errors.secondary[index]?.email?.message}
                              </p>
                            )}
                          </div>
                          <div className="form__col form__col--1of2">
                            <label className="form__label" htmlFor="last_name">
                              * Confirm email
                            </label>
                            <div className="form__controls">
                              <input
                                className="field"
                                placeholder="Confirm email"
                                {...register(`secondary.${index}.confirmEmail`, {
                                  required: {
                                    value: true,
                                    message: "Please enter your confirmation email.",
                                  },
                                  validate: (val: string) => {
                                    if (watch(`secondary.${index}.email`) != val) {
                                      return "Please make sure your email matches.";
                                    }
                                  },
                                })}
                              />
                            </div>
                            {errors?.secondary && errors?.secondary[index]?.confirmEmail && (
                              <p className="Registration-error">
                                <i className="fas fa-exclamation-circle" />{" "}
                                {errors?.secondary[index]?.confirmEmail?.message}
                              </p>
                            )}
                          </div>
                          <div className="form__col">
                            <button
                              style={{
                                height: "4rem",

                                border: "solid 0.1rem #dddddd",
                                background: "transparent",
                                textAlign: "center",
                                lineHeight: "4rem",
                                color: "red",
                              }}
                              type="button"
                              className="form__btn btn"
                              onClick={() => remove(index)}>
                              Remove contact
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
                <br />
                <br />

                <div className="form__actions">
                  <button
                    className="form__btn btn--purple btn btn--outline "
                    style={{ marginRight: "20px" }}
                    type="button"
                    onClick={() => {
                      append({ firstName: "", lastName: "", email: "", confirmEmail: "" });
                    }}
                    disabled={getValues().secondary.length > 0 ? true : false}>
                    Add another district contact
                    <img
                      src="/assets/images/svg/plus-circle-p.svg"
                      alt=""
                      style={{ marginTop: "-7px", marginLeft: "10px" }}
                    />
                  </button>

                  {isSubmitting ? (
                    <button type="submit" disabled className="form__btn btn btn--purple">
                      <i className="fas fa-circle-notch fa-spin"></i>
                    </button>
                  ) : (
                    <button type="submit" className="form__btn btn btn--purple">
                      Create Account(s)
                    </button>
                  )}

                  <span>
                    Already registered?{" "}
                    <a href="/login" className="link">
                      Login
                    </a>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Signup;
