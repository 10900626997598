import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import "./index.css";
import { campaignClient, getSessionClient, publicCampaignClient } from "../../../api";
import { useQuery } from "react-query";
import Loading from "../../../Components/Loading";
import { ACTIONS, convertcaps, emailRegex, stringRegex } from "../../../helpers/utils";
import { showToast } from "../../../Components/Toast/ToastManager";
import Header from "../../../Components/Header";
import { DistrictDetails } from "../../../Components/Layout";
import { actions } from "react-table";
import { SchoolSignup } from "../../../helpers/types";
import { useStores } from "../../../stores";
import { defaultStorageHelper } from "@spry/campaign-client";

type Student = {
  firstName?: string;
  lastName?: string;
  email: string;
  metadata: Metadata;
  preferredLanguage: string;
};

export type Metadata = {
  district: string;
  schoolId: string;
  schoolName: string;
  grade: string;
  region: string;
  studentEmail: string;
  otherSchool: string;
  parent: {
    firstName: string;
    lastName: string;
  };
  status?: number;
  recipe: {
    name: string;
    ingredients: string[];
    preparationMethod: string[];
    photoKey: string;
  };
  scores: Score[];
};
type Score = {
  origin: number;
  health: number;
  ease: number;
  kid: number;
  plate: number;
  adapt: number;
  judge?: string;
};
declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

const AddStudentAdmin = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [loading, setLoading] = useState(false);
  const [showOtherschool, setShowotherschool] = useState(false);
  const [photoKey, setPhotoKey] = useState("");
  const [uploading, setUploading] = useState(false);
  const [imageLoaded, setImageLoaded] = useState<any>();
  const [file, setFile] = useState<File>();
  const [filename, setFilename] = useState("");
  const [filetype, setFiletype] = useState("");
  const [imageUrl, setImageUrl] = useState(defaultStorageHelper.get("photoUrl") || "");
  const defaultValues: Student = state?.default
    ? { ...state.default }
    : {
      email: "",
      firstName: "",
      lastName: "",
      preferredLanguage: "en",
      metadata: {
        district: "",
        grade: "",
        parent: {
          firstName: "",
          lastName: "",
        },
        recipe: {
          name: "",
          ingredients: ["", "", ""],
          preparationMethod: ["", "", ""],
          photoKey: "",
        },
        otherSchool: "",
        region: "",
        schoolId: "",
        schoolName: "",
        studentEmail: "",
        status: 1,
        scores: [],
      },
    };
  const ingredientPlaceholders = [
    "e.g. 1 cup sugar",
    "e.g. 2 cups flour, sifted",
    "e.g. 2 tablespoons unsalted butter, softened",
  ];
  const preparationPlaceholders = [
    "e.g. Preheat oven to 350 degrees F",
    "e.g. Combine all dry ingredients in a large bowl.",
    "e.g. Pour into greased trays and basket for 15-20 minutes",
  ];
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<Student>({ mode: "onChange", defaultValues: { ...defaultValues } });
  const formwatch = watch();

  const district_selected = register("metadata.district", {
    required: { value: true, message: "Please select your district" },
  });
  const school_selected = register("metadata.schoolId", {
    required: { value: true, message: "Please select your school." },
  });

  let role = useStores().authStore.user.role || defaultStorageHelper.get("current");
  let user = useStores().authStore.user;

  const { isLoading: loadingDistricts, data: districts } = useQuery(
    ["getalldistricts", role],

    async () => {
      if (role === "admin") {
        const res: { districts: any[] } = await campaignClient.call("getDistricts", {});
        return res.districts;
      } else {
        const res: { districts: any[] } = await campaignClient.call("getDistricts", {
          userName: user.name,
        });
        return res.districts;
      }
    },
    { enabled: !!user }
  );
  const { isLoading: downloadinguserfiles, data: userfile } = useQuery(
    ["getalldistricts", defaultValues.metadata.recipe.photoKey],

    async () => {
      const res: { downloadUrls: { [key: string]: string } } = await publicCampaignClient.call(
        "upload",
        { s3Key: defaultValues.metadata.recipe.photoKey }
      );
      return res.downloadUrls[defaultValues.metadata.recipe.photoKey];
    },
    { enabled: !!defaultValues.metadata.recipe.photoKey }
  );

  const { isLoading: loadingschools, data: schools } = useQuery(
    "schools",
    async () => {
      const res: { schools: SchoolSignup[] } = await campaignClient.call("schools", {});
      return res.schools;
    },
    {
      onError: (error) => {
        window.vex.dialog.alert({
          unsafeMessage: `Something went wrong, please try again.`,
          callback: () => {
            return;
          },
        });
      },
    }
  );

  const selectedScools = useMemo(() => {
    if (formwatch?.metadata?.district && schools) {
      return schools.filter((s) => s.district === formwatch.metadata.district);
    } else return [];
  }, [formwatch?.metadata?.district, schools]);

  function handleSingleFile(data: any) {
    if (!data?.target?.files?.length) {
      return;
    }
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      setImageLoaded(reader.result?.toString() || "");
    });
    reader.readAsDataURL(data.target.files[0]);
    setFile(data.target.files[0]);

    setFilename(data?.target?.files?.[0]?.name);
  }

  async function handleUpload() {
    if (!file || uploading) {
      return;
    }
    setUploading(true);

    const fileToUpload = file;
    try {
      const data = await fileToUpload.arrayBuffer();
      const sessionClient = getSessionClient();
      const s3Key = await sessionClient.uploadFile({
        data,
        contentType: fileToUpload.type,
      });
      setPhotoKey(s3Key);
      return { key: s3Key, filename: fileToUpload.name };
    } catch (e) {
      console.log(e);
    }
    setUploading(false);
  }

  const handleAdd = async (data: Student) => {
    try {
      setLoading(true);
      const region = districts?.find((d) => d.district === data.metadata.district)?.region;
      const { sessionKey } = getSessionClient();
      let res;
      const result = await handleUpload();
      if (result?.key) {
        data.metadata.recipe.photoKey = result.key;
      }

      if (state?.default) {
        res = await campaignClient.call<{
          sessionKey?: string;
          error?: string;
          message?: string;
        }>("savestudent", {
          sessionKey,
          ...data,
          metadata: {
            ...data.metadata, filename: result?.filename, status: data.metadata.status ? +data.metadata.status : 0
          },
          region,
        });
      } else {
        res = await publicCampaignClient.call<{
          sessionKey?: string;
          error?: string;
          message?: string;
        }>("register", {
          sessionKey,
          ...data,
          metadata: {
            ...data.metadata,
            filename: result?.filename,
            schoolName:
              data.metadata.schoolId === "other"
                ? data.metadata.schoolName
                : data.metadata.schoolName,
          },
          region,
        });
      }

      if (res.sessionKey) {
        showToast({
          content: "Student info has been saved. ",
          duration: 3000,
          error: false,
        });
        if (window.location.pathname.includes("/admin")) {
          navigate("/admin/students");
        } else {
          navigate("/dms/students");
        }
      }
      if (res.error) {
        showToast({
          content: `${res.message}`,
          duration: 3000,
          error: true,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  if (loadingDistricts || loadingschools) {
    return <Loading />;
  }
  return (
    <section className="section section--alt">
      <div className="shell">
        <Header title={state?.default ? "Edit student" : "Add student"} />
        <hr className="underlineHR" />{" "}
        <div className="shell">
          <div className="form form--school" style={{ float: "left", marginTop: "30px" }}>
            <form onSubmit={handleSubmit(handleAdd)}>
              <div className="form__head">
                <h6>Student details</h6>
                {/* <div className="form__entry">
                <p>
                  Add the details for a participating school from your district.
                </p>
              </div> */}
                <span className="form__notice">* Required field</span>
              </div>
              <div className="form__group-body">
                <div className="form__row">
                  <div className="form__cols">
                    <div className="form__col form__col--1of2">
                      <label className="form__label" htmlFor="first_name">
                        * Status
                      </label>

                      <div className="form__controls">
                        <select
                          className="field"
                          disabled={state?.default && role === "admin" ? false : true}
                          {...register("metadata.status")}
                          placeholder="status">
                          <option value="">Please select a status</option>
                          {Object.keys(ACTIONS).map((k) => (
                            <option key={k} value={k}>
                              {ACTIONS[Number(k)]}
                            </option>
                          ))}
                        </select>
                      </div>
                      {/* /.form__controls */}
                      {errors.metadata?.status && (
                        <p className="error-message">
                          <i className="fas fa-exclamation-circle" />{" "}
                          {errors.metadata?.status.message}
                        </p>
                      )}
                    </div>
                    <div className="form__col form__col--1of2">
                      <label className="form__label" htmlFor="first_name">
                        * Student first name
                      </label>

                      <div className="form__controls">
                        <input
                          className="field"
                          {...register("firstName", {
                            required: {
                              value: true,
                              message: "Please enter your first name.",
                            },
                            pattern: {
                              value: stringRegex,
                              message: "Please enter a valid first name.",
                            },
                          })}
                          placeholder="First name"
                        />
                      </div>
                      {/* /.form__controls */}
                      {errors.firstName && (
                        <p className="error-message">
                          <i className="fas fa-exclamation-circle" /> {errors.firstName.message}
                        </p>
                      )}
                    </div>
                    {/* /.form__col */}

                    <div className="form__col form__col--1of2">
                      <label className="form__label" htmlFor="last_name">
                        * Last name
                      </label>

                      <div className="form__controls">
                        <input
                          className="field"
                          {...register("lastName", {
                            required: {
                              value: true,
                              message: "Please enter your last name.",
                            },
                            pattern: {
                              value: stringRegex,
                              message: "Please enter your last name.",
                            },
                          })}
                          placeholder="Last name"
                        />
                      </div>
                      {/* /.form__controls */}
                      {errors.lastName && (
                        <p className="error-message">
                          <i className="fas fa-exclamation-circle" /> {errors.lastName.message}
                        </p>
                      )}
                    </div>
                    {/* /.form__col */}

                    <div className="form__col form__col--1of2">
                      <label className="form__label" htmlFor="studentEmail">
                        Email
                      </label>

                      <div className="form__controls">
                        <input
                          className="field"
                          {...register("metadata.studentEmail", {
                            required: {
                              value: false,
                              message: "Please enter your email",
                            },
                            pattern: {
                              value: emailRegex,
                              message: "Please enter a valid email.",
                            },
                          })}
                          placeholder="Email"
                          type="email"
                        />
                      </div>
                      {/* /.form__controls */}
                      {errors.metadata?.studentEmail && (
                        <p className="error-message">
                          <i className="fas fa-exclamation-circle" />{" "}
                          {errors.metadata.studentEmail.message}
                        </p>
                      )}
                    </div>

                    <div className="form__col form__col--1of2">
                      <label className="form__label" htmlFor="Language">
                        Preferred Language
                      </label>

                      <div className="form__controls">
                        <select
                          className="field"
                          {...register("preferredLanguage", {
                            required: {
                              value: true,
                              message: "Please select your language",
                            },
                          })}
                          placeholder="language">
                          <option value="">Select</option>
                          <option value="en">English</option>
                          <option value="sp">Spanish</option>
                        </select>
                      </div>
                      {/* /.form__controls */}
                      {errors.preferredLanguage && (
                        <p className="error-message">
                          <i className="fas fa-exclamation-circle" />{" "}
                          {errors.preferredLanguage.message}
                        </p>
                      )}
                    </div>
                    {/* /.form__col */}

                    <div className="form__col form__col--1of2">
                      <label className="form__label" htmlFor="select_district">
                        * District
                      </label>

                      <div className="form__controls">
                        <div className="select js-select">
                          <select
                            {...district_selected}
                            onChange={(e) => {
                              district_selected.onChange(e);
                              let region =
                                districts!.find((d) => d.district === e.currentTarget.value)
                                  ?.region || "";
                              setValue("metadata.region", region);
                            }}>
                            <option value="">Please select a district</option>
                            {districts?.map((district) => {
                              return (
                                <option value={district.district} key={district.district}>
                                  {convertcaps(district.name)}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        {/* /.select */}
                        {errors.metadata?.district && (
                          <p className="error-message">
                            <i className="fas fa-exclamation-circle" />{" "}
                            {errors.metadata.district.message}
                          </p>
                        )}
                      </div>
                      {/* /.form__controls */}
                    </div>
                    {/* /.form__col */}

                    <div className="form__col form__col--1of2">
                      <label className="form__label" htmlFor="select_school">
                        * School
                      </label>

                      <div className="form__controls">
                        <div className="select js-select">
                          <select
                            {...school_selected}
                            onChange={(e) => {
                              school_selected.onChange(e);
                              let schoolname =
                                schools!.find((d) => d.schoolId === e.currentTarget.value)
                                  ?.schoolName || "";
                              setValue("metadata.schoolName", schoolname);
                            }}>
                            {!formwatch.metadata.district || selectedScools.length ? (
                              <option value="" defaultValue="">
                                Please select a school
                              </option>
                            ) : (
                              <option value="">No schools have been added to this district </option>
                            )}
                            {selectedScools.length &&
                              selectedScools.map((s) => (
                                <option value={s.schoolId} key={s.schoolId}>
                                  {convertcaps(s.schoolName)}
                                </option>
                              ))}

                            {formwatch.metadata.district && <option value="other">Other</option>}
                          </select>
                        </div>
                        {/* /.select */}
                        {errors.metadata?.schoolId && (
                          <p className="error-message">
                            <i className="fas fa-exclamation-circle" />{" "}
                            {errors.metadata.schoolId.message}
                          </p>
                        )}
                      </div>
                      {/* /.form__controls */}
                    </div>
                    {/* /.form__col */}

                    <div className="form__col form__col--1of2">
                      <label className="form__label" htmlFor="select_grade">
                        * Grade
                      </label>

                      <div className="form__controls">
                        <div className="select select--grade js-select">
                          <select
                            {...register("metadata.grade", {
                              required: { value: true, message: "Please select your grade." },
                            })}>
                            <option value="">Select</option>
                            <option value="1">Grade 1</option>
                            <option value="2">Grade 2</option>
                            <option value="3">Grade 3</option>
                            <option value="4">Grade 4</option>
                            <option value="5">Grade 5</option>
                          </select>
                        </div>
                        {/* /.select */}
                        {errors.metadata?.grade && (
                          <p className="error-message">
                            <i className="fas fa-exclamation-circle" />{" "}
                            {errors.metadata.grade.message}
                          </p>
                        )}
                      </div>
                      {/* /.form__controls */}
                    </div>
                    {formwatch.metadata.schoolId === "other" && (
                      <div className="form__col form__col--1of2">
                        <div className="form__controls">
                          <input
                            className="field"
                            {...register("metadata.otherSchool", {
                              required: {
                                value: true,
                                message: "Please enter your school name.",
                              },
                              // pattern:{
                              // value:stringRegex,
                              // message: "Please enter a valid school name."}
                            })}
                            placeholder="Please enter your school name"
                          />
                        </div>
                        {/* /.form__controls */}
                        {errors?.metadata?.otherSchool && (
                          <p className="error-message">
                            <i className="fas fa-exclamation-circle" />{" "}
                            {errors.metadata.otherSchool.message}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="form__head" style={{ marginTop: "30px" }}>
                <h6>Parent details</h6>
              </div>
              <div className="form__group-body">
                <div className="form__row">
                  <div className="form__cols">
                    <div className="form__col form__col--1of2">
                      <label className="form__label" htmlFor="first_name">
                        * Parent/Guardian first name
                      </label>

                      <div className="form__controls">
                        <input
                          className="field"
                          {...register("metadata.parent.firstName", {
                            required: {
                              value: true,
                              message: "Please enter your first name.",
                            },
                            pattern: {
                              value: stringRegex,
                              message: "Please enter a valid first name.",
                            },
                          })}
                          placeholder="First name"
                        />
                      </div>
                      {/* /.form__controls */}
                      {errors?.metadata?.parent?.firstName && (
                        <p className="error-message">
                          <i className="fas fa-exclamation-circle" />{" "}
                          {errors.metadata.parent.firstName.message}
                        </p>
                      )}
                    </div>
                    {/* /.form__col */}

                    <div className="form__col form__col--1of2">
                      <label className="form__label" htmlFor="last_name">
                        * Parent/Guardian last name
                      </label>

                      <div className="form__controls">
                        <input
                          className="field"
                          {...register("metadata.parent.lastName", {
                            required: {
                              value: true,
                              message: "Please enter your last name.",
                            },
                            pattern: {
                              value: stringRegex,
                              message: "Please enter your last name.",
                            },
                          })}
                          placeholder="Last name"
                        />
                      </div>
                      {/* /.form__controls */}
                      {errors?.metadata?.parent?.lastName && (
                        <p className="error-message">
                          <i className="fas fa-exclamation-circle" />{" "}
                          {errors.metadata.parent.lastName.message}
                        </p>
                      )}
                    </div>
                    {/* /.form__col */}

                    <div className="form__col form__col--1of2">
                      <label className="form__label" htmlFor="email">
                        *Parent /Gardian email
                      </label>

                      <div className="form__controls">
                        <input
                          className="field"
                          {...register("email", {
                            required: {
                              value: true,
                              message: "Please enter a parent mail",
                            },
                            pattern: {
                              value: emailRegex,
                              message: "Please enter a valid email.",
                            },
                          })}
                          placeholder="Email"
                          type="email"
                        />
                      </div>
                      {/* /.form__controls */}
                      {errors.email && (
                        <p className="error-message">
                          <i className="fas fa-exclamation-circle" /> {errors.email.message}
                        </p>
                      )}
                    </div>
                    {/* /.form__col */}
                  </div>
                </div>
              </div>
              <div className="form__section">
                <h6 className="form__title" style={{ marginTop: "35px", marginBottom: "35px" }}>
                  Add your recipe
                </h6>

                <div className="form__row">
                  <div className="form__cols form__cols-alt">
                    <div className="form__col">
                      <label className="form__label" htmlFor="recipe_name">
                        * Name of recipe
                      </label>

                      <div className="form__controls">
                        <input
                          className="field"
                          {...register("metadata.recipe.name", {
                            required: {
                              value: true,
                              message: "Please enter a name for your recipe.",
                            },
                          })}
                          placeholder="Give your recipe a fun name!"
                          type="text"
                        />
                      </div>
                      {/* /.form__controls */}
                      {errors.metadata?.recipe?.name && (
                        <p className="error-message">
                          <i className="fas fa-exclamation-circle" />{" "}
                          {errors.metadata.recipe.name.message}
                        </p>
                      )}
                    </div>
                    {/* /.form__col */}

                    <div className="form__col">
                      <div className="form__group-head">
                        <div className="photoContainer">
                          <div>
                            <label htmlFor="upload">
                              <div className="photoImg">
                                {imageLoaded ? (
                                  <img src={imageLoaded} alt="recipe-img" width={150} />
                                ) : userfile ? (
                                  <div>
                                    {" "}
                                    <img src={userfile} alt="recipe-img" width={150} />
                                  </div>
                                ) : (
                                  <img src="/assets/images/svg/photo.png" alt="" />
                                )}
                              </div>{" "}
                            </label>
                            <div className="form__controls">
                              <input
                                type="file"
                                onChange={handleSingleFile}
                                className="form__field"
                                id="upload"
                                name="upload"
                                accept=".png, .jpg, .jpeg, capture=user"
                              />
                            </div>
                            <br />
                          </div>

                          <div className="photoTitle">
                            {" "}
                            <h4 className="paddingBottom">
                              <b>Photo</b>
                            </h4>
                            <p>
                              Add a photo of your recipe. Please upload a JPEG or PNG. <br />
                              The photo must be at list 960 x 960.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form__col">
                      <h4 className="paddingBottom">
                        <b>Ingredients</b>
                      </h4>
                      <p>
                        Enter one ingredient per line. Include the quantity (i.e. cups, tablespoons)
                        and any special preparation (i.e. sifted,deced, chopped).
                      </p>

                      {formwatch.metadata?.recipe?.ingredients?.map((i, index) => (
                        <>
                          <div className="form__controls form__controls-textarea" key={index}>
                            <input
                              className="field"
                              id={index + ""}
                              {...register(`metadata.recipe.ingredients.${index}`, {
                                required: {
                                  value: true,
                                  message: "Please enter the ingredients",
                                },
                                minLength: { value: 1, message: "Please enter the ingredients" },
                              })}
                              placeholder={ingredientPlaceholders[index]}
                            />
                            {/* /.form__controls */}
                            <button
                              className="linkButton"
                              type="button"
                              disabled={
                                index === 0 ||
                                  !formwatch.metadata.recipe.ingredients[index].trim().length
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                let temp = formwatch.metadata.recipe.ingredients[index];
                                let updatedvalues = [...formwatch.metadata.recipe.ingredients];
                                updatedvalues[index] =
                                  formwatch.metadata.recipe.ingredients[index - 1];
                                updatedvalues[index - 1] = temp;
                                setValue("metadata.recipe.ingredients", [...updatedvalues]);
                              }}>
                              <img src="/assets/images/svg/arrow-up.svg" alt="arrow_up" />
                            </button>
                            <button
                              className="linkButton"
                              type="button"
                              disabled={
                                index === formwatch.metadata.recipe.ingredients.length - 1 ||
                                  !formwatch.metadata.recipe.ingredients[index].trim().length
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                let temp = formwatch.metadata.recipe.ingredients[index];
                                let updatedvalues = [...formwatch.metadata.recipe.ingredients];
                                updatedvalues[index] =
                                  formwatch.metadata.recipe.ingredients[index + 1];
                                updatedvalues[index + 1] = temp;
                                setValue("metadata.recipe.ingredients", [...updatedvalues]);
                              }}>
                              <img src="/assets/images/svg/arrow-down.svg" alt="arrow_down" />
                            </button>
                            <button
                              type="button"
                              className="linkButton"
                              disabled={index === 0 ? true : false}
                              onClick={() => {
                                setValue(
                                  "metadata.recipe.ingredients",
                                  formwatch.metadata.recipe.ingredients.filter(
                                    (l, i) => i !== index
                                  )
                                );
                              }}>
                              <img src="/assets/images/svg/trash.svg" alt="trash" />
                            </button>
                          </div>
                          {errors.metadata?.recipe?.ingredients &&
                            errors.metadata?.recipe?.ingredients[index] && (
                              <p className="error-message">
                                <i className="fas fa-exclamation-circle" />{" "}
                                {errors.metadata?.recipe?.ingredients[index]?.message}
                              </p>
                            )}
                        </>
                      ))}
                    </div>
                    {/* Add ingredients function */}

                    <button
                      onClick={() => {
                        setValue("metadata.recipe.ingredients", [
                          ...formwatch.metadata.recipe.ingredients,
                          "",
                        ]);
                      }}
                      type="button"
                      className=" btn btn--outline">
                      <i>
                        <img
                          src="/assets/images/svg/ico-plus.svg"
                          alt=""
                          style={{ marginTop: "-6px", marginRight: "10px" }}
                        />
                      </i>{" "}
                      Add ingredient
                    </button>

                    <div className="form__col">
                      <br />
                      <br />
                      <h4 className="paddingBottom">
                        <b> Preparation method</b>
                      </h4>
                      <p>
                        Explain how to make your recipe, including oven temperature, baking or
                        cooking times, and pan sizes, etc.
                      </p>

                      {formwatch.metadata?.recipe?.preparationMethod?.map((p, index) => (
                        <>
                          <p className="noBottomMargin">
                            <b>Step {index + 1}</b>
                          </p>
                          <div className="form__controls form__controls-textarea ">
                            <textarea
                              className="field field--textarea prepartion-textarea"
                              {...register(`metadata.recipe.preparationMethod.${index}`, {
                                required: {
                                  value: true,
                                  message: "Please enter the step",
                                },
                              })}
                              id={index + ""}
                              placeholder={preparationPlaceholders[index]}></textarea>
                            <button
                              className="linkButton"
                              type="button"
                              disabled={
                                index === 0 ||
                                  !formwatch.metadata.recipe.preparationMethod[index].trim().length
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                let temp = formwatch.metadata.recipe.preparationMethod[index];
                                let updatedvalues = [
                                  ...formwatch.metadata.recipe.preparationMethod,
                                ];
                                updatedvalues[index] =
                                  formwatch.metadata.recipe.preparationMethod[index - 1];
                                updatedvalues[index - 1] = temp;
                                setValue("metadata.recipe.preparationMethod", [...updatedvalues]);
                              }}>
                              <img src="/assets/images/svg/arrow-up.svg" alt="arrow_up" />
                            </button>
                            <button
                              className="linkButton"
                              type="button"
                              disabled={
                                index === formwatch.metadata.recipe.preparationMethod.length - 1 ||
                                  !formwatch.metadata.recipe.preparationMethod[index].trim().length
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                let temp = formwatch.metadata.recipe.preparationMethod[index];
                                let updatedvalues = [
                                  ...formwatch.metadata.recipe.preparationMethod,
                                ];
                                updatedvalues[index] =
                                  formwatch.metadata.recipe.preparationMethod[index + 1];
                                updatedvalues[index + 1] = temp;
                                setValue("metadata.recipe.preparationMethod", [...updatedvalues]);
                              }}>
                              <img src="/assets/images/svg/arrow-down.svg" alt="arrow_down" />
                            </button>
                            <button
                              type="button"
                              className="linkButton"
                              disabled={index === 0 ? true : false}
                              onClick={() => {
                                setValue(
                                  "metadata.recipe.preparationMethod",
                                  formwatch.metadata.recipe.preparationMethod.filter(
                                    (l, i) => i !== index
                                  )
                                );
                              }}>
                              <img src="/assets/images/svg/trash.svg" alt="trash" />
                            </button>
                          </div>
                          {errors.metadata?.recipe?.preparationMethod &&
                            errors.metadata?.recipe?.preparationMethod[index] && (
                              <p className="error-message">
                                <i className="fas fa-exclamation-circle" />{" "}
                                {errors.metadata?.recipe?.preparationMethod[index]?.message}
                              </p>
                            )}
                        </>
                      ))}

                      <button
                        type="button"
                        onClick={() =>
                          setValue("metadata.recipe.preparationMethod", [
                            ...formwatch.metadata.recipe.preparationMethod,
                            "",
                          ])
                        }
                        className=" btn btn--outline">
                        <i>
                          <img
                            src="/assets/images/svg/ico-plus.svg"
                            alt=""
                            style={{ marginTop: "-6px", marginRight: "10px" }}
                          />
                        </i>{" "}
                        Add Step
                      </button>
                      <br />
                      <br />
                    </div>
                    {/* /.form__col */}
                  </div>
                  {/* /.form__cols */}
                </div>
                {/* /.form__row */}

                {/* <div className="form__row">
                  <span className="warning-message">
                    *Your recipe submission cannot be changed once you’ve clicked submit
                  </span>
                </div> */}
                {/* /.form__row */}

                {/* /.form__row */}
              </div>
              {role === "admin" &&
                <div className="form__section">
                  {formwatch.metadata?.scores?.map((s, index) => {

                    return (
                      <div className="table-student">
                        <h6
                          className="form__title"
                          style={{ marginTop: "35px", marginBottom: "35px" }}>
                          Score {index + 1}
                          {/* - {s.judge} */}
                        </h6>
                        <table className="score-table">
                          <tr>
                            <th>Category</th>
                            <th>Score</th>
                          </tr>
                          <tr>
                            <td>Originality</td>
                            <td>
                              <input
                                {...register(`metadata.scores.${index}.origin`)}
                                type="number"
                                min={0}
                                max={10}
                                step={1}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Healthy Attributes</td>
                            <td>
                              <input
                                {...register(`metadata.scores.${index}.health`)}
                                type="number"
                                min={0}
                                max={10}
                                step={1}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Ease of Preparation</td>
                            <td>
                              <input
                                {...register(`metadata.scores.${index}.ease`)}
                                type="number"
                                min={0}
                                max={10}
                                step={1}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Kid Friendliness</td>
                            <td>
                              <input
                                {...register(`metadata.scores.${index}.kid`)}
                                type="number"
                                min={0}
                                max={10}
                                step={1}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Plate Presentation</td>
                            <td>
                              <input
                                {...register(`metadata.scores.${index}.plate`)}
                                type="number"
                                min={0}
                                max={10}
                                step={1}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Adaptability as a School Lunch</td>
                            <td>
                              <input
                                {...register(`metadata.scores.${index}.adapt`)}
                                type="number"
                                min={0}
                                max={10}
                                step={1}
                              />
                            </td>
                          </tr>
                          <tr style={{ backgroundColor: "#F4F4F4" }}>
                            <th> Total score</th>
                            <th style={{ textAlign: "center" }}>
                              {Object.values(s).filter(a => !a.toString().includes('@'))?.reduce((a, b) => +a + +b, 0)}
                            </th>
                          </tr>
                        </table>
                      </div>
                    );
                  })}
                </div>
              }

              <div className="form__actions">
                {loading ? (
                  <button type="submit" disabled className="form__btn btn btn--purple">
                    <i className="fas fa-circle-notch fa-spin"></i>
                  </button>
                ) : (
                  <button type="submit" className="form__btn btn btn--purple">
                    Submit
                  </button>
                )}
                <button
                  type="button"
                  className="form__btn btn btn--outline"
                  onClick={() => {
                    if (window.location.pathname.includes("/admin")) {
                      navigate("/admin/students");
                    } else {
                      navigate("/dms/students");
                    }
                  }}>
                  Back
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddStudentAdmin;
