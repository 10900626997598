function Footer() {
  return (
    <footer className="footer">
      <div className="shell">
        <ul className="terms-nav">
          <li>
            <a href="https://us.sodexo.com/legal-privacy.html" target="_blank">
              Terms &amp; Conditions
            </a>
          </li>

          <li>
            <a
              href="https://us.sodexo.com/legal-privacy/privacy-policy.html"
              target="_blank"
            >
              Privacy Policy
            </a>
          </li>
        </ul>
        <div className="copyright">
          <p>{`© ${new Date().getFullYear()} Sodexo. All rights reserved.`}</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
