import Footer from "../../../Components/Footer";

const Closed = () => {
  return (
    <div className="wrapper">
      <div className="main">
        <div className="hero">
          <div className="shell">
            <div className="hero__image">
              <img src="/assets/images/hero-image@2x.png" alt="" width={536} />
            </div>
          </div>
        </div>
        <section className="section section--medium">
          <div className="shell">
            <div className="section__content">
              <h1>This site is now closed</h1>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Closed;
