import React from "react";
import { campaignClient, publicCampaignClient } from "../../../api";
import { useQuery } from "react-query";
import Loading from "../../../Components/Loading";
import { useNavigate } from "react-router-dom";
import { PageLoader, Table } from "../../../Components";
import UsersColumns from "./UsersColumns";
import { useCSVDownloader } from "react-papaparse";
import { convertcaps, emailRegex } from "../../../helpers/utils";
import { useState, useEffect, useMemo } from "react";
import "./index.css";
import Header from "../../../Components/Header";
import { useStores } from "../../../stores";
import ModalCustom from "../../../Components/ModalCustome";
import CSVReader from "../../../Components/CSVReader";
import { generateKey } from "@sprycore/spry-api-client";
import { showToast } from "../../../Components/Toast/ToastManager";
import { DistrictDetails } from "../Districts";

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

export type User = {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  district: string;
  userType: string;
  region: string;
  tk: string;
};
type HeaderTypes = { type: "field"; field: string } | { type: "unknown"; header: string };
const headerFields: Record<string, string> = {
  firstName: "firstName",
  lastName: "lastName",
  email: "email",
  "district/region": "district/region",
  userType: "userType",
};
function matchHeaders(dataheaders: string[]) {
  const unmatched = dataheaders.filter((x) => !headerFields[x]);
  return unmatched.length;
}

const Users = () => {
  const navigate = useNavigate();
  const user = useStores().authStore.user.name;
  const [userData, setUserData] = useState<any[]>();
  const [readStatus, setReadStatus] = useState("");
  const [filterTable, setFilterTable] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userFilter, setUserFilter] = useState("");
  const [key, setKey] = useState("all");
  const { CSVDownloader } = useCSVDownloader();

  const { isLoading: loadingDistricts, data: districts } = useQuery(
    "getDistricts",
    async () => {
      const res: { districts: DistrictDetails[] } = await campaignClient.call("getDistricts", {});
      return res.districts;
    },
    {
      onError: (error) => {
        window.vex.dialog.alert({
          unsafeMessage: `Something went wrong, please try again.`,
          callback: () => {
            return;
          },
        });
      },
    }
  );
  const regions = districts ? Array.from(new Set(districts.map((p) => p.region.toLowerCase()))) : [];

  const deleteUsers = async (userName: string, userType: string) => {
    try {
      const deleted = await campaignClient
        .call("deleteadmin", {
          userName,
          userType,
          tk: "Dg2k@VtiPia4",
        })
        .then((res) =>
          window.vex.dialog.alert({
            unsafeMessage: `User has been deleted successfully!`,
            callback: () => {
              refetch();
            },
          })
        );
    } catch (e) {
      window.vex.dialog.alert({
        unsafeMessage: `${e}`,
        callback: () => {
          return;
        },
      });
    }
  };

  const processCSV = (data: string[][]) => {
    if (districts) {
      if (!data.length) {
        return;
      }
      // if (Object.keys(headerFields).filter((a) => !data[0].includes(a))) {
      //   setErrorMessage(["Unknown fields in the file."]);
      //   return
      // }
      //create a user object from csv
      data = data.map((x) => x.map((c) => c.trim()));
      for (let row of data) {
        while (row[row.length - 1] === "") {
          row.pop();
        }
      }
      data = data.filter((x) => x.length);

      const headers: HeaderTypes[] | undefined = data.shift()?.map((x) => {
        const field = headerFields[x.replaceAll(/\s/g, "")];
        if (field) {
          return { type: "field", field };
        }
        return { type: "unknown", header: x };
      });
      if (!headers?.length || !data.length) {
        setReadStatus("No data.");
        return;
      }
      if (headers.length !== 5) {
        setReadStatus("Wrong number of columns.");
        return;
      }
      const dataheaders = headers.map((h) => {
        if (h.type === "unknown") {
          return h.header;
        } else if (h.type === "field") {
          return h.field;
        } else {
          return "";
        }
      });
      if (matchHeaders(dataheaders) > 0) {
        setReadStatus("Unexpected or wrong column headers.");
        return;
      }
      const districtIndex = dataheaders.findIndex((d) => d === "district/region");
      const userTypeIndex = dataheaders.findIndex((d) => d === "userType");
      let unknownDistrict: { [key: string]: string } = {};
      const districtNames: string[] = districts?.map((d) => d.name).map((s) => s.toLowerCase());

      const districtcheck = data.map((row, index) => {
        if (row[userTypeIndex] === "primary" || row[userTypeIndex] === "secondary") {
          if (!districtNames.includes(row[districtIndex].toLowerCase())) {
            unknownDistrict[index + 2] = row[districtIndex];
          }
        }
        if (row[userTypeIndex] === "judge" || row[userTypeIndex] === "regional manager") {
          if (!regions.includes(row[districtIndex].toLowerCase())) {
            unknownDistrict[index + 2] = row[districtIndex];
          }
        }
        if (row[userTypeIndex] === "national manager") {
          if (row[districtIndex].toLowerCase() !== "all") {
            unknownDistrict[index + 2] = row[districtIndex];
          }
        }
      });
      if (Object.keys(unknownDistrict).length) {
        setReadStatus(
          `Unknown district/region found in rows ${Object.keys(unknownDistrict).join(",")}`
        );
        return;
      }
      const rows = data.map((row) => {
        let userObj: { [key: string]: string } = {};
        row.map((field, i) => {
          if (
            Object.keys(headerFields)[i] === "district/region" &&
            (row[userTypeIndex] === "primary" || row[userTypeIndex] === "secondary")
          ) {
            userObj["district"] = field;
            userObj["region"] = districts.find((d) => d.name.toLowerCase() === field.toLowerCase())?.region || "";
          } else if (
            Object.keys(headerFields)[i] === "district/region" &&
            (row[userTypeIndex] === "judge" || row[userTypeIndex] === "regional manager"||row[userTypeIndex] === "national manager")
          ) {
            userObj["region"] = field.toLowerCase();
          } else {
            userObj[Object.keys(headerFields)[i]] = field;
          }
        });
        return userObj;
      });

      setUserData(
        rows.map((r) => {
          if (r.userType === "primary" || r.userType === "secondary") {
          
          }
          if (
            r.userType === "judge" ||
            r.userType === "regional manager" ||
            r.userType === "national manager"
          ) {
            r.district = r.region;
            r.tk = "Ty5!kLSiUia9";
            return r;
          } else {
            return r;
          }
        })
      );
    }
  };

  const {
    isLoading: loadingUsers,
    data: users,
    refetch,
  } = useQuery("managers", async () => {
    const res: User[] = await campaignClient.call("managers", { userName: user });
    return res;
  });

  const data = useMemo(() => {
    if (users && users.length > 0) {
      let selectedUsers = users;
      if (userFilter) {
        if (userFilter == "dm") {
          selectedUsers = users.filter(
            (u) => u.userType === "primary" || u.userType === "secondary"
          );
        } else {
          selectedUsers = users.filter((u) => u.userType === userFilter);
        }
      }
      return selectedUsers.map((user: User) => {
        return {
          ...user,
          firstName: user?.firstName && convertcaps(user.firstName),
          lastName: user?.lastName && convertcaps(user.lastName),
          district: user.district ? user.district : "N/A",
          type: user.userType,
        };
      });
    }
  }, [users, userFilter]);

  const functions = {
    deleteUsers,
  };

  const addUsers = async (data: User[]) => {
    if (!data.length) {
      return;
    }
    setLoading(true);
    try {
      let errors: string[] = [];
      const result = await Promise.all(
        data.map(async (r) => {
          const res = await publicCampaignClient.call<{
            sessionKey: string;
            error?: string;
            message?: string;
          }>("signupUser", {
            ...r,
            phase: "phase1",
            sessionKey: generateKey(),
          });
          if (res.error) {
            errors.push(res.message!);
          }
          if (res.sessionKey) {
            errors.push("");
          }
        })
      );

      if (errors.filter((e) => e.length).length) {
      } else {
        refetch();
        setIsOpen(false);
        showToast({
          content: "Users added successfully! ",
          duration: 3000,
          error: false,
        });
      }
      setLoading(false);
    } catch (e) {
      window.vex.dialog.alert({
        unsafeMessage: "file is too_large",
        callback: () => {
          return;
        },
      });
      return;
    }
  };

  const columns = UsersColumns(functions);

  return (
    <>
      {(loadingUsers || loading || loadingDistricts) && <Loading />}
      <section className="section section--alt">
        <div className="shell">
          <Header
            title="Users"
            children={
              <div className="search search--alt">
                <div className="search__row">
                  <label htmlFor="q" className="hidden">
                    Search Schools
                  </label>
                  <input
                    style={{ minWidth: "25rem" }}
                    type="search"
                    name="q"
                    id="q"
                    value={filterTable}
                    placeholder="Search"
                    className="search__field"
                    onChange={(e) => setFilterTable(e.target.value)}></input>
                  <button type="submit" className="search__btn">
                    <svg className="ico-search">
                      <image xlinkHref="/assets/images/svg/ico-search.svg"></image>
                    </svg>
                  </button>
                </div>
              </div>
            }
          />
          <hr className="underlineHR" />
          <div className="shell">
            <div className="section__inner">
              <div className="section__body">
                <div
                  className="section__actions"
                  style={{ display: "flex", justifyContent: "space-between" }}>
                  <button
                    type="button"
                    className="btn btn--purple btn--add"
                    onClick={(e) => {
                      setIsOpen(true);
                    }}>
                    Add user
                  </button>
                  <div className="search search--alt">
                    <div className="search__row select">
                      <select
                        style={{ minWidth: "25rem" }}
                        name="q"
                        id="q"
                        value={userFilter}
                        onChange={(e) => {
                          setUserFilter(e.currentTarget.value);
                        }}
                        className="">
                        <option value="">All user types</option>
                        <option value="dm">District Managers</option>
                        <option value="regional manager">Regional Managers</option>
                        <option value="judge">Regional Judges</option>
                        <option value="national manager">National Managers</option>
                        <option value="admin">Admins</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="table table--schools">
                  <Table
                    columns={columns}
                    data={data ? data : []}
                    tablePageSize={15}
                    filterValue={filterTable}
                  />
                </div>
              </div>
              <div className="section__actions">
                <div className="paging">{/* <ReactPaginate /> */}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {isOpen && (
        <ModalCustom>
          <div
            className="popup popup--alt textCenter"
            style={{
              position: "relative",
              backgroundColor: "#fff",
              padding: "45px",
            }}>
            <div className="modal-close">
              {" "}
              <button
                onClick={(e) => {
                  setUserData([]);
                  setIsOpen(false);
                  setReadStatus("");
                }}
                className="modal-close-btn">
                <img src="/assets/images/svg/close.svg" alt="close" />
              </button>
            </div>
            <div className="popup__content">
              <h3>Add users?</h3>
              <div>
                To add a group of new users, click the button below to select a file for upload. The
                file must contain the complete info for each new user you would like to add. Please
                refer tot he example CSV file
                <CSVDownloader
                  filename="users"
                  className="downloand-link"
                  data={() => {
                    return [
                      {
                        firstName: "John",
                        lastName: "Maxwell",
                        email: "test@email.com",
                        "district/region": "Test District",
                        userType: "primary",
                      },
                      {
                        firstName: "Richard",
                        lastName: "kdjfksl",
                        email: "test2@email.com",
                        "district/region": "Test District",
                        userType: "secondary",
                      },
                      {
                        firstName: "Naomi",
                        lastName: "kdjfksl",
                        email: "test3@email.com",
                        "district/region": "Collins",
                        userType: "judge",
                      },
                      {
                        firstName: "Bala",
                        lastName: "kdjfksl",
                        email: "test3@email.com",
                        "district/region": "Collins",
                        userType: "regional manager",
                      },
                      {
                        firstName: "Mithwin",
                        lastName: "kdjfksl",
                        email: "test3@email.com",
                        "district/region": "all",
                        userType: "national manager",
                      },
                    ];
                  }}>
                  Download here.
                </CSVDownloader>
                Once uploaded, click 'Add users'.
              </div>
            </div>
            <div className="popup__actions">
              <CSVReader loaddata={(s: any[]) => processCSV(s)} />
              {readStatus && (
                <p className="Registration-error">
                  <i className="fas fa-exclamation-circle" /> {readStatus}
                </p>
              )}
              <button
                type="button"
                className="btn btn--purple"
                onClick={(e) => {
                  userData && addUsers(userData);
                }}
                disabled={userData?.length ? false : true}>
                Add users
              </button>
            </div>
          </div>
        </ModalCustom>
      )}
    </>
  );
};

export default Users;
