import { Link } from "react-router-dom";
import Header from "../../../Components/Header";
import { disableBrowserHistory } from "../../../helpers/utils";

function Restricted() {
  disableBrowserHistory();

  return (
    <>
      <section className="section section--alt">
        <div className="shell"></div>
        <Header title="Restricted" />
        <hr className="underlineHR" />
        <div className="shell">
          <div className="section__inner section-submission">
            <div className="section__body">
              <div className="section__content">
                <h3>Your details are not valid to access this page. Please check and try again.</h3>
              </div>
            </div>
          </div>
        </div>
        {/* /.shell */}

        {/* /.form__actions */}
      </section>
      {/* /.section */}
    </>
  );
}

export default Restricted;
