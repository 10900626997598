import { defaultStorageHelper } from "@spry/campaign-client";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import { convertcaps } from "../../../helpers/utils";
import { useStores } from "../../../stores";

const StudentColumns = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const role = useStores().authStore.user.role || defaultStorageHelper.get("current");
  const COLUMNS = [
    {
      Header: "Student name",
      accessor: "studentName",
      width: "18%",
    },
    {
      Header: "Recipe name",
      accessor: "recipeName",
      width: "18%",
    },

    {
      Header: "Region",
      accessor: "region",
    },
    {
      Header: "District",
      accessor: "districtName",
      width: "25%",
      Cell: (tableInfo: any) => {
        let district = convertcaps(tableInfo.data[tableInfo.row.index].districtName);
        return district;
      },
    },
    {
      Header: "School",
      accessor: "school",
      width: "35%",
    },
    {
      Header: "Status",
      accessor: "status",
      width: "35%",
    },
    {
      Header: "Parent signed",
      accessor: "parentSigned",
      width: "5%",
     
    },
    {
      Header: "Total Score",
      accessor: "totalScore",
      width: "10%",

      Cell: (tableInfo: any) => {
        return tableInfo.data[tableInfo.row.index].totalScore ||""
      },
    },
    {
      Header: "No of votes",
      accessor: "noOfVotes",
      width: "10%",

      Cell: (tableInfo: any) => {
        return tableInfo.data[tableInfo.row.index].noOfVotes ||""
      },
    },
    {
      Header: "Actions",
      accessor: "username",
      Cell: (tableInfo: any) => {
        let districtName = tableInfo.data[tableInfo.row.index].districtName;
        let status = tableInfo.data[tableInfo.row.index]?.metadata?.status;
        return (
          <div className="table__actions">
            <button
              className="noBorder"
              style={{ marginRight: "10px" }}
              onClick={() => {
                if (location.pathname.includes("/admin")) {
                  navigate("/admin/editstudent", {
                    state: { default: { ...tableInfo.data[tableInfo.row.index], districtName } },
                  });
                } else {
                  navigate("/dms/editstudent", {
                    state: { default: tableInfo.data[tableInfo.row.index] },
                  });
                }
              }}>
              <img src="/assets/images/svg/edit.svg" alt="edit user" width={25} height={25} />
            </button>
            {(role == "primary" || role == "secondary") && (
              <button
                className="noBorder"
                style={{ marginTop: "-2px" }}
                onClick={() => {
                  navigate("/dms/reviewstudent", {
                    state: { default: tableInfo.data[tableInfo.row.index] },
                  });
                }}>
                {+status === 3 ? (
                  <img
                    src="/assets/images/svg/star-yellow.svg"
                    alt="edit user"
                    width={20}
                    height={20}
                  />
                ) : (
                  <img
                    src="/assets/images/svg/star-white.svg"
                    alt="edit user"
                    width={20}
                    height={20}
                  />
                )}
              </button>
            )}
          </div>
        );
      },
    },
  ];
  return COLUMNS;
};

export default StudentColumns;
