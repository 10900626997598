import { useEffect, useState } from "react";
import { Navigate, Link, useLocation } from "react-router-dom";
import { Outlet, useNavigate } from "react-router-dom";
import { useStores } from "../../stores";
import Footer from "../Footer";
import { useAuth } from "../../helpers/hooks";
import "./index.css";
import { useQuery } from "react-query";
import { campaignClient, publicCampaignClient } from "../../api";
import Loading from "../Loading";
import ReactModal from "react-modal";
import ModalCustom from "../ModalCustome";

export type DistrictDetails = {
  city: string;
  zip: string;
  district: string;
  address: string;
  school_sites: string;
  region: string;
  name: string;
  address1: string;
  state: string;
  event: {
    zip: string;
    address: string;
    city: string;
    address1: string;
    eventTime: string;
    state: string;
    eventDate: string;
  };
};

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

type Props = {
  children: JSX.Element;
};

const Layout = () => {
  const { authStore, stateStore } = useStores();
  const location = useLocation();
  const [stateData, setStateData] = useState<{ state: string; metadata: any }>();
  const [openModal, setOpenModal] = useState(false);

  const [menuOpen, setMenuOpen] = useState(false);

  const navigate = useNavigate();

  const logOut = async () => {
    try {
      await authStore.logout();

      navigate("/dms/login", { replace: true });
    } catch (error) {
      console.error("error signing out: ", error);
    }
  };

  // const getschedule = async () => {
  //   console.log("getschedule");
  //   if (loading) {
  //     return;
  //   }
  //   setLoading(true);
  //   try {
  //     const res: any = await publicCampaignClient.call("getschedule", {
  //       phase: "phase3a",
  //     });
  //     stateStore.setState(res);

  //     if (res.state === "closed") {
  //       await authStore.logout();
  //       navigate("/closed");
  //     } else {
  //       setStateData(res)

  //       setOpen(true);

  //       setLoading(false);
  //     }
  //   } catch (e) {
  //     window.vex.dialog.alert({
  //       unsafeMessage: `Something went wrong, please try again.`,
  //       callback: () => {
  //         return;
  //       },
  //     });
  //   }
  // };
  // useEffect(() => {
  //   getschedule();
  // }, [location.pathname]);

  return (
    <div className="wrapper">
      {/* {loadingDistrict && <Loading />} */}
      {openModal && (
        <ModalCustom>
          <div
            className="popup popup--alt textCenter"
            style={{
              position: "relative",
              backgroundColor: "#fff",
              paddingTop: "30px",
              paddingBottom: "30px",
            }}>
            <div className="popup__content">
              <h3>Logout?</h3>
              <p>Are you sure you want to logout?</p>
            </div>
            <div>
              <button type="button" className="btn btn--purple" onClick={() => logOut()}>
                Yes, logout
              </button>

              <button
                type="button"
                className="btn btn--outline"
                onClick={() => {
                  setOpenModal(false);
                }}>
                Cancel
              </button>
            </div>
          </div>
        </ModalCustom>
      )}
      <header className="header">
        <div className="shell">
          <div className="header__inner " style={{ justifyContent: "center" }}>
            <a
              href="https://us.sodexo.com/industry/schools.html"
              rel="noopener"
              target="_blank"
              className="logo header__logo">
              <img src="/assets/images/svg/logo.svg" alt="" />
            </a>

            {/* <div className={menuOpen ? "header__nav js-nav is-active" : "header__nav js-nav"}>
              <nav className="nav-utilities">
                <ul>
             
                  <li>
                    <button
                      onClick={() => {
                        navigate("/dms/addSchool");
                        setMenuOpen(false);
                      }}
                      style={{ border: "none", backgroundColor: "transparent", fontWeight: "700" }}>
                      Add Schools
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => {
                        navigate("/dms/schools");
                        setMenuOpen(false);
                      }}
                      style={{ border: "none", backgroundColor: "transparent", fontWeight: "700" }}>
                      School List
                    </button>
                  </li>
             

                   <li>
                    <button
                      onClick={() => {
                        navigate("/landing");
                        setMenuOpen(false);
                      }}
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        fontWeight: "700",
                      }}>
                      All Submissions
                    </button>
                  </li> 

                  <li>
                    <button
                      onClick={() => {
                        navigate("/dms/event");
                        setMenuOpen(false);
                      }}
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        fontWeight: "700",
                      }}>
                      District Event Details
                    </button>
                    {!districtDetails?.event && (
                      <div className="tooltip" style={{ minHeight: "0px" }}>
                        <img
                          src="/assets/images/svg/question.svg"
                          alt="note"
                          className="filter-green"
                        />
                        <span className="tooltiptext">
                          {" "}
                          <i className="fas fa-exclamation-circle" /> Please enter the details for
                          your district competition.
                        </span>
                      </div>
                    )}
                  </li>

                  <li>
                    <a
                      href="#"
                      onClick={() => {
                        setOpenModal(true);
                      }}>
                      Logout
                    </a>
                  </li>
                </ul>
              </nav>
            </div> */}

            {/* <a
              onClick={() => (menuOpen ? setMenuOpen(false) : setMenuOpen(true))}
              className={
                menuOpen ? "nav-trigger js-nav-trigger is-active" : "nav-trigger js-nav-trigger"
              }>
              <span></span>

              <span></span>

              <span></span>
            </a> */}
          </div>
        </div>
      </header>

      <div className="main">
        <div className="hero hero--small">
          <div className="shell">
            <div className="hero__image">
              <img src="/assets/images/hero-image@2x.png" alt="" width={208} />
            </div>
          </div>
        </div>
        {/* {loadingDistrict ? (
          <div style={{ height: "100vh" }}>
            {" "}
            <Loading />
          </div>
        ) : ( */}
        <> {<Outlet />}</>
        {/* )} */}
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
