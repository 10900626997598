import { Route, Navigate } from "react-router-dom";
import { Landing, Login, Signup, SignUpConfirmation, Closed, Coming } from "../Pages/Dms";
import LandingLayout from "../Components/LandingLayout";
import LandingLayoutLeft from "../Components/LandingLayoutLeft";
import Verify from "../Pages/Dms/Verify";
import SignUpCheck from "../Pages/Dms/SignUpCheck";
import ResetPassword from "../Pages/Dms/ResetPassword";
import ResetConfirmation from "../Pages/Dms/ResetConfirmation";

export default (
  <>
    <Route path="/" element={<LandingLayout />}>
      <Route index element={<Navigate to="/landing" />} />
      <Route path="landing" element={<Landing />} />
      <Route path="verify/:sk" element={<Verify />} />

      <Route path="login" element={<Login />} />
      <Route path="resetpassword" element={<ResetPassword/>}/>
      <Route path="resetconfirmation" element={<ResetConfirmation/>}/>

      <Route path="signupconfirmation" element={<SignUpConfirmation />} />
      <Route path="signupcheck" element={<SignUpCheck />} />
    </Route>
    <Route path="/" element={<LandingLayoutLeft />}>
      <Route path="signup" element={<Signup />} />
    </Route>

    <Route path="closed" element={<Closed />} />
    <Route path="coming" element={<Coming />} />    

  </>
);
