import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation, useOutletContext } from "react-router-dom";
import { campaignClient, publicCampaignClient } from "../../../api";
import { useStores } from "../../../stores";
import Loading from "../../../Components/Loading";
import "./index.css";
import Modal from "react-modal";
import { ACTIONS, convertcaps } from "../../../helpers/utils";
import { disableBrowserHistory } from "../../../helpers/utils";
import Header from "../../../Components/Header";
import ModalCustom from "../../../Components/ModalCustome";
import { showToast } from "../../../Components/Toast/ToastManager";
import { useForm } from "react-hook-form";
import { defaultStorageHelper } from "@spry/campaign-client";
import { useQuery } from "react-query";

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };
interface Score {
  origin: number;
  health: number;
  ease: number;
  kid: number;
  plate: number;
  adapt: number;
}
function EntryDetails() {
  disableBrowserHistory();
  const navigate = useNavigate();
  const { state } = useLocation();
  const role =
    useStores().authStore.user.role || defaultStorageHelper.get("current");
  const [studentdetails, setStudentDetails] = useState(state?.default);
  const user = useStores().authStore.user.name;

  const [submitting, setSubmitting] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const photoKey = studentdetails?.metadata?.recipe?.photoKey || "";
  const { isLoading: downloadinguserfiles, data: userfile } = useQuery(
    ["getalldistricts", photoKey],

    async () => {
      const res: { downloadUrls: { [key: string]: string } } =
        await publicCampaignClient.call("upload", { s3Key: photoKey });
      return res.downloadUrls[photoKey];
    },
    { enabled: !!photoKey }
  );

  async function markasviewed() {
    try {
      const result = await campaignClient.call("submission", {
        sessionKey: studentdetails.sessionKey,
        action: "district-reviewed",
      });
      if (!result) {
        window.vex.dialog.alert({
          unsafeMessage:
            "Your details are not valid to access this page. Please check and try again.",
          callback: () => {
            setSubmitting(false);
            navigate("/dms/students");
            return;
          },
        });
        return;
      } else {
        setStudentDetails({
          ...studentdetails,
          metadata: { ...studentdetails.metadata, status: 2 },
        });
      }
    } catch (e) {
      // console.log(e)
    }
  }
  useEffect(() => {
    if (!studentdetails) {
      navigate("/dms/students");
    }
    if (+studentdetails?.metadata?.status < 2) {
      markasviewed();
    }
    return () => {
      setSubmitting(false);
    };
  }, []);

  const { data: phase2, isLoading: phase2loading } = useQuery(
    "getschedulephase2",
    async () => {
      const res: any = await campaignClient.call("getschedule", {
        phase: "phase2",
      });
      return res;
    }
  );

  const { data: phase3a, isLoading: phase3aloading } = useQuery(
    "getschedulephase3a",
    async () => {
      const res: any = await campaignClient.call("getschedule", {
        phase: "phase3a",
      });
      return res;
    }
  );
  const { data: phase3b, isLoading: phase3bloading } = useQuery(
    "getschedulephase3b",
    async () => {
      const res: any = await campaignClient.call("getschedule", {
        phase: "phase3b",
      });
      return res;
    }
  );

  const { isLoading: winnersloading, data: districtWinners } = useQuery(
    ["getSubmissionsv2", studentdetails],
    async () => {
      const res: any = await campaignClient.call("getSubmissionsv2", {
        winType: "district-winner",
        district: studentdetails.metadata?.district,
      });
      return res;
    }
  );

  const { isLoading: distictLoading, data: district } = useQuery(
    ["getDistricts", studentdetails],
    async () => {
      const res: { districts: any[] } = await campaignClient.call(
        "getDistricts",
        {
          userName: user,
        }
      );

      return res.districts.find(
        (d) => d.district === studentdetails.metadata.district
      );
    }
  );

  const phase = useMemo(() => {
    if (phase2 && phase3a && phase3b) {
      let temp = "2";

      if (phase2.state === "open") {
        temp = "2";
        if (phase3a.state === "open") {
          temp = "3a";
        }
      } else if (phase3a.state === "open") {
        temp = "3a";
      } else if (phase3a.state === "closed" && phase3b.state === "open") {
        temp = "3b";
      }
      return temp;
    }
  }, [phase2, phase3a, phase3b]);

  async function handledeclare() {
    setSubmitting(true);
    setIsOpen(false);
    try {
      const result: { error: string; message: string; sessionKey: string } =
        await campaignClient.call("submission", {
          sessionKey: studentdetails.sessionKey,
          action: "district-winner",
        });
      if (result.error) {
        showToast({
          content: `${result.message}`,
          duration: 3000,
          error: true,
        });
      }
      if (result) {
        showToast({
          content: "Student selected as district winner.",
          duration: 3000,
          error: false,
        });
        navigate("/dms/students");
      }
    } catch (e) {
      setSubmitting(false);
    }
    setSubmitting(false);
  }
  async function handleSelection() {
    setSubmitting(true);
    try {
      const result = await campaignClient.call<{
        error?: string;
        message?: string;
        sessionKey?: string;
      }>("submission", {
        sessionKey: studentdetails.sessionKey,
        action: "district-competitor",
      });
      if (result.sessionKey) {
        showToast({
          content: "Student selected for district competition.",
          duration: 3000,
          error: false,
        });
      } else if (result.error === "District-error") {
        window.vex.dialog.alert({
          unsafeMessage: ` Please enter the details for your district competition to select competitors.`,
          callback: () => {
            return;
          },
        });
      } else {
        window.vex.dialog.alert({
          unsafeMessage: "Something went wrong, please try again later.",
          callback: () => {
            return;
          },
        });
      }
      navigate("/dms/students");
      setSubmitting(false);
    } catch (e) {
      window.vex.dialog.alert({
        unsafeMessage: "Something went wrong, please try again later.",
        callback: () => {
          return;
        },
      });
      setSubmitting(false);
    }
  }
  console.log(districtWinners);

  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <>
      {(submitting ||
        downloadinguserfiles ||
        !studentdetails ||
        phase2loading ||
        phase3aloading ||
        phase3bloading ||
        distictLoading ||
        winnersloading) && <Loading />}

      <section className="section section--alt">
        <div className="shell">
          <Header title="Review student" />
          <hr className="underlineHR" />
          <div className="shell">
            <div className="section__inner section-submission">
              <div className="section__body" style={{ float: "left" }}>
                <div className="section__content">
                  <h6 style={{ marginTop: "20px" }}>Student details</h6>

                  <div className="table-student">
                    <table>
                      <tbody>
                        <tr>
                          <td>Student first name</td>
                          <td>
                            {convertcaps(studentdetails?.firstName || "")}
                          </td>
                        </tr>

                        <tr>
                          <td>Student last name</td>
                          <td>{convertcaps(studentdetails?.lastName || "")}</td>
                        </tr>

                        <tr>
                          <td>Student email</td>

                          <td>
                            {convertcaps(
                              studentdetails?.metadata.studentEmail || ""
                            )}
                          </td>
                        </tr>

                        <tr>
                          <td>Parent/Guardian first name</td>

                          <td>
                            {convertcaps(
                              studentdetails?.metadata.parent?.firstName || ""
                            )}
                          </td>
                        </tr>

                        <tr>
                          <td>Parent/Guardian last name</td>

                          <td>
                            {convertcaps(
                              studentdetails?.metadata.parent?.lastName || ""
                            )}
                          </td>
                        </tr>

                        <tr>
                          <td>Parent/Guardian email</td>

                          <td>{convertcaps(studentdetails?.email || "")}</td>
                        </tr>

                        <tr>
                          <td>District</td>

                          <td>
                            {convertcaps(studentdetails?.districtName || "")}
                          </td>
                        </tr>

                        <tr>
                          <td>School</td>

                          <td>
                            {convertcaps(
                              studentdetails?.metadata.schoolName || ""
                            )}
                          </td>
                        </tr>

                        <tr>
                          <td>Grade</td>

                          <td>
                            {convertcaps(studentdetails?.metadata.grade || "")}
                          </td>
                        </tr>

                        <tr>
                          <td>Region</td>

                          <td>
                            {convertcaps(
                              studentdetails?.metadata.region || "N/A"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>State</td>

                          <td>
                            {convertcaps(
                              studentdetails?.metadata.state || "N/A"
                            )}
                          </td>
                        </tr>

                        <tr>
                          <td>Parental form submitted</td>

                          <td>
                            {studentdetails?.metadata?.release ? "Yes" : "No"}
                          </td>
                        </tr>

                        <tr>
                          <td>Selected for district competition</td>

                          <td>
                            {+studentdetails?.metadata?.status > 2
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        <tr>
                          <td>Shirt size</td>

                          <td>
                            {studentdetails?.metadata?.release
                              ? studentdetails?.metadata?.shirtSize
                              : "N/A"}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div className="recipe">
                      <div className="recipe__head">
                        <h6 className="recipe__subtitle">Recipe</h6>
                        {userfile && (
                          <img src={userfile} alt="pic2" width={250} />
                        )}

                        <h3 className="recipe__name">
                          {studentdetails?.metadata?.recipe?.name}!{" "}
                        </h3>
                        {/* /.recipe__name */}
                      </div>
                      {/* /.recipe__head */}

                      <div className="recipe__group">
                        <h6>Ingredients</h6>

                        {studentdetails?.metadata?.recipe?.ingredients?.map(
                          (ing: string) => (
                            <li key={ing}>{ing}</li>
                          )
                        )}
                      </div>
                      {/* /.recipe__group */}

                      <div className="recipe__group">
                        <h6>Preparation method</h6>
                        {/* {studentdetails?.metadata.recipe.preparationMethod} */}
                        {studentdetails?.metadata.recipe?.preparationMethod

                          .filter((line: string, index: number) => {
                            return line.length > 0;
                          })
                          .map((line: string, index: number) => {
                            return (
                              <li key={index} style={{ listStyle: "none" }}>
                                <p style={{ marginBottom: "5px" }}>
                                  <strong>Step {index + 1}</strong>
                                </p>
                                <p style={{ marginBottom: "15px" }}>
                                  {line.length > 0 && line}
                                </p>
                              </li>
                            );
                          })}
                      </div>
                      {/* /.recipe__group */}
                    </div>
                  </div>

                  {/* /.table */}
                  <div className="section__actions">
                    {(role === "primary" || role === "secondary") && (
                      <>
                        {+studentdetails?.metadata?.status === 2 &&
                          phase === "3a" && (
                            <button
                              className="btn btn--purple no-border"
                              onClick={() => setIsOpen(true)}
                              disabled={+studentdetails?.metadata.gi >= 3}
                            >
                              Select for District Competition
                            </button>
                          )}

                        {+studentdetails?.metadata?.status === 3 &&
                          districtWinners?.length < 1 &&
                          phase === "3b" && (
                            <button
                              className="btn btn--purple no-border"
                              onClick={() => setIsOpen(true)}
                              disabled={
                                +studentdetails?.metadata.status >= 4
                                  ? true
                                  : false
                              }
                            >
                              <strong>Declare as District Winner</strong>
                            </button>
                          )}
                      </>
                    )}
                  </div>
                </div>

                {/* /.section__aside */}
              </div>
              {/* /.section__body */}
            </div>
            {/* /.section__inner */}
          </div>
          {/* /.shell */}
        </div>
      </section>

      {/* /.section */}
      {isOpen && (
        <ModalCustom>
          <div
            className="popup popup--alt textCenter"
            style={{
              position: "relative",
              backgroundColor: "#fff",
              padding: "45px",
            }}
          >
            <div className="modal-close">
              {" "}
              <button
                onClick={closeModal}
                className="modal-close-btn"
                style={{ background: "transparent" }}
              >
                <img src="/assets/images/svg/close.svg" alt="close" />
              </button>
            </div>
            <div className="center">
              {+studentdetails?.metadata?.status === 3 && (
                <>
                  <h3>Declare as the district winner?</h3>
                  <p>
                    {" "}
                    Are you sure you want to select this submission as district
                    winner?{" "}
                  </p>

                  <div
                    className="popup__actions"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    {" "}
                    <button className="btn btn--purple" onClick={handledeclare}>
                      Declare as the District Winner
                    </button>
                    <button
                      className="btn btn--outline"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      CANCEL
                    </button>
                  </div>
                </>
              )}
              {+studentdetails?.metadata?.status === 2 && (
                <>
                  <h3>Declare as the district competitor?</h3>
                  <p>
                    {" "}
                    Are you sure you want to select this submission for the
                    district competition?{" "}
                  </p>
                  <p> Please make sure event details are as correct? </p>
                  <div className="event-details">
                    <p> Date: {district.event.eventDate}</p>
                    <p> Time: {district.event.eventTime}</p>
                    <p>
                      {" "}
                      Location :{" "}
                      {district.event.address2
                        ? `${district.event.address1}, ${district.event.address2},
                   ${district.event.city}, ${district.event.state}, 
                   ${district.event.zip}`
                        : `${district.event.address1}, 
                   ${district.event.city}, ${district.event.state}, 
                  ${district.event.zip}`}
                    </p>
                  </div>

                  <div
                    className="popup__actions"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <button
                      className="btn btn--purple"
                      onClick={handleSelection}
                      disabled={!district.event}
                    >
                      Select for District Competition
                    </button>
                    <button
                      className="btn btn--outline"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      CANCEL
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </ModalCustom>
      )}
    </>
  );
}

export default EntryDetails;
