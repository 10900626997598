import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { convertcaps } from "../../../helpers/utils";
import "./index.css";

type state = {
  score: number;
  student: string;
};
function Confirmsubmission() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as state;
  return (
    <>
      <section className="section section--large" style={{ flexGrow: "1" }}>
        <div className="confirm-section">
          <div className="shell">
            <div className="section__content">
              <h3>
                <strong>Recipe scored</strong>
              </h3>

              <img src="/assets/images/svg/check-big.svg" alt="" />

              <div className="section__entry">
                <h3>
                  <strong>Score: {state.score} </strong>
                </h3>

                <p>Thank you! Your score for {convertcaps(state.student)} has been submitted. </p>
              </div>
              {/* /.section__entry */}
            </div>
            {/* /.section__content */}
          </div>
          {/* /.shell */}

          <div></div>
          <Link to="judge/students" type="submit" className="form__btn btn btn--purple admin-submit">
            Return to All Submissions
          </Link>
        </div>
        {/* /.form__actions */}
      </section>
      {/* /.section */}
    </>
  );
}

export default Confirmsubmission;
