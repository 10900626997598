
const ComeBack = ({ text }: { text: string }) => {
  return (
    <>
      <section className="section section--large">
        <div className="shell">
          {text === "soon" ? (
            <div className="section__content">
              <h3>Please come back later.</h3>

              <div className="section__entry"></div>
            </div>
          ) : (
            <div className="section__content">
              <div className="section__entry">
                <h3>Closed. </h3>
              </div>
            </div>
          )}
        </div>
        {/* /.shell */}
      </section>
      {/* /.section */}
    </>
  );
};

export default ComeBack;
