import { useQuery } from "react-query";
import { useState, useEffect } from "react";
import {
  campaignClient,
  getSessionClient,
  publicCampaignClient,
  setSessionClient,
} from "../../../api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Loading from "../../../Components/Loading";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useStores } from "../../../stores";
import { useAuth } from "../../../helpers/hooks";
import { defaultStorageHelper } from "@spry/campaign-client";
import { emailRegex } from "../../../helpers/utils";
import dayjs from "dayjs";
import { showToast } from "../../../Components/Toast/ToastManager";

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

interface User {
  password: string;
  confirmPassword: string;
  email: string;
}

type ReturnUpdate = {
  code: string;
  error: string;
  message: string;
  sessionKey: string;
};

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

const Verify = () => {
  const { sk } = useParams();
  const { search } = useLocation();
  const [loading, setLoading] = useState(false);

  const { authStore } = useStores();

  const { isAuth } = useAuth(authStore);

  const token = search.split("t=")[1];
  const navigate = useNavigate();

  const [passwordShown, setPasswordShown] = useState({ first: false, second: false });

  const [isVerified, setIsVerified] = useState(false);
  const [loadingVerify, setLoadingVerify] = useState(false);
  const [timer, setTimer] = useState(defaultStorageHelper.get("timer") || null);

  const verifyLink = async () => {
    if (loadingVerify || isVerified) {
      return;
    }
    setLoadingVerify(true);
    try {
      const { result, error, message }: { result: string; error: string; message: string } =
        await publicCampaignClient.call("verify", {
          sessionKey: sk,
          token,
        });
      if (result) {
        setIsVerified(true);
        setLoadingVerify(false);
        return;
      } else if (error === "SESSION_EXPIRED") {
        setLoadingVerify(false);
        return;
      } else if (error && message) {
        window.vex.dialog.alert({
          unsafeMessage: message,
          callback: () => {
            setLoadingVerify(false);
            //   navigate("/login");
            return;
          },
        });
      } else {
        window.vex.dialog.alert({
          unsafeMessage: "Something went wrong, Please try again later.",
          callback: () => {
            setLoadingVerify(false);
            return;
          },
        });
      }
    } catch (e) {
      setLoadingVerify(false);
    }
  };

  const logOut = async () => {
    try {
      defaultStorageHelper.remove("multiple");
      await authStore.logout();
      navigate(`/verify/${sk}/?t=${token}`);
    } catch (error) {
      console.error("error signing out: ", error);
    }
  };

  useEffect(() => {
    if (!isVerified) {
      verifyLink();
      if (isAuth) {
        isAuth?.auth && logOut();
      }
    }
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<User>();

  const handleUpdatePassword = async (data: User) => {
    try {
      const { code, error, message, sessionKey } = await publicCampaignClient.call<ReturnUpdate>(
        "updatePassword ",
        {
          sessionKey: sk,
          password: data.password,
          tempPassword: token,
        }
      );
      if (sessionKey) {
        navigate("/signupconfirmation");
      } else if (error && message) {
        window.vex.dialog.alert({
          unsafeMessage: message,
          callback: () => {
            return;
          },
        });
      }
    } catch {
      window.vex.dialog.alert({
        unsafeMessage: "Something went wrong, Please try again later.",
        callback: () => {
          return;
        },
      });
    }
  };

  const handleResend = async () => {
    const res = await publicCampaignClient.call("resendVerifyEmail", {
      sessionKey: sk,
    });
    if (res) {
      setTimer(dayjs().add(1, "minute").toISOString());
      defaultStorageHelper.set("timer", dayjs().add(1, "minute").toISOString());
      showToast({
        content: "Verification link was successfully resent.",
        duration: 5000,
        error: false,
      });
    } else {
      window.vex.dialog.alert({
        unsafeMessage: "Something went wrong, Please try again later.",
        callback: () => {
          return;
        },
      });
    }
  };

  const togglePasswordVisibility = (isFirst?: boolean) => {
    if (isFirst) {
      setPasswordShown({ ...passwordShown, first: !passwordShown.first });
    } else {
      setPasswordShown({ ...passwordShown, second: !passwordShown.second });
    }
  };

  return (
    <div className="wrapper">
      <div className="main">
        <div className="hero">
          <div className="shell">
            <div className="hero__image">
              <img src="/assets/images/hero-image@2x.png" alt="" width={536} />
            </div>
          </div>
        </div>
        {isVerified || loadingVerify ? (
          <>
            {(loadingVerify || loading) && <Loading />}
            <section className="section section--alt">
              <div className="shell">
                <div className="form form--login">
                  <form onSubmit={handleSubmit(handleUpdatePassword)}>
                    <div className="form__head textCenter">
                      <h3>
                        <b>Reset your password</b>
                      </h3>

                      <div className="form__entry">
                        <p>Please enter your new password below.</p>
                      </div>
                      <br />
                      <br />
                    </div>
                    <div className="form__body">
                      <div className="form__row">
                        <div className="form__cols">
                          <div className="form__col">
                            <label className="form__label" htmlFor="first_name">
                              Password
                            </label>
                            <div className="form__controls pass-wrapper">
                              <input
                                className="field"
                                type={passwordShown.first ? "text" : "password"}
                                placeholder="Password"
                                {...register("password", {
                                  required: {
                                    value: true,
                                    message: "Please enter your password.",
                                  },
                                })}
                              />
                              <i className="iconEye" onClick={() => togglePasswordVisibility(true)}>
                                {!passwordShown.first ? eye : eyeSlash}
                              </i>
                            </div>
                            {errors.password && (
                              <p className="Registration-error">
                                <i className="fas fa-exclamation-circle" />{" "}
                                {errors.password.message}
                              </p>
                            )}
                          </div>
                          <div className="form__col ">
                            <label className="form__label" htmlFor="last_name">
                              Confirm Password
                            </label>
                            <div className="form__controls pass-wrapper">
                              <input
                                className="field"
                                type={passwordShown.second ? "text" : "password"}
                                placeholder="Confirm password"
                                {...register("confirmPassword", {
                                  required: {
                                    value: true,
                                    message: "Please enter your password confirmation.",
                                  },
                                  validate: (val: string) => {
                                    if (watch("password") != val) {
                                      return "Please make sure your passwords matches.";
                                    }
                                  },
                                })}
                              />
                              <i className="iconEye" onClick={() => togglePasswordVisibility()}>
                                {!passwordShown.second ? eye : eyeSlash}
                              </i>
                            </div>
                            {errors.confirmPassword && (
                              <p className="Registration-error">
                                <i className="fas fa-exclamation-circle" />{" "}
                                {errors.confirmPassword.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form__actions textCenter">
                      {isSubmitting ? (
                        <button type="submit" disabled className="form__btn btn btn--purple">
                          <i className="fas fa-circle-notch fa-spin"></i>
                        </button>
                      ) : (
                        <button type="submit" className="form__btn btn btn--purple">
                          Submit
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </>
        ) : (
          <section className="section section--alt">
            <div className="shell">
              <div className="form form--login">
                <div className="form__head textCenter">
                  <p>
                    Sorry, the verification link has expired. Please click below to resend the link
                  </p>{" "}
                  <button
                    disabled={timer && dayjs().isBefore(dayjs(timer)) ? true : false}
                    onClick={handleResend}
                    style={{
                      textDecoration: "underline",
                      background: "transparent",
                      border: "none",
                    }}>
                    Resend verification link
                  </button>
                  {timer && dayjs().isBefore(dayjs(timer)) && (
                    <p className="error-message" style={{ fontSize: "14px" }}>
                      {" "}
                      <i className="fas fa-exclamation-circle" /> Please wait a minute to try
                      resending the verification email again.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    </div>
  );
};

export default Verify;
