import { useEffect, useState } from "react";
import { Navigate, Link, NavLink, useLocation } from "react-router-dom";
import { Outlet, useNavigate } from "react-router-dom";
import { useStores } from "../../stores";
import Footer from "../Footer";
import { useAuth } from "../../helpers/hooks";
import "./index.css";
import { useQuery } from "react-query";
import { Restricted } from "../../Pages/Dms";
import { defaultStorageHelper } from "@spry/campaign-client";

type DistrictDetails = {
  city: string;
  zip: string;
  district: string;
  address: string;
  school_sites: string;
  region: string;
  name: string;
  address1: string;
  state: string;
  event: {
    zip: string;
    address: string;
    city: string;
    address1: string;
    eventTime: string;
    state: string;
    eventDate: string;
  };
};

type Props = {
  children: JSX.Element;
};

const LayoutAdmin = () => {
  const { authStore } = useStores();
  const { isAuth } = useAuth(authStore);
  const { pathname } = useLocation();
  const role = useStores().authStore.user.role || defaultStorageHelper.get("current");

  const [menuOpen, setMenuOpen] = useState(false);

  const StyleActive = {
    padding: "20px",
    width: "100%",
    backgroundColor: "#232357",
    borderLeft: "15px solid #464498",
  };
  const StyleNoActive = { padding: "20px 30px", width: "100%" };
  return !isAuth.loading ? (
    isAuth.auth ? (
      <div className="wrapper">
        <div className="main change1">
          <header className="header header-mobile">
            <div className="hero hero--small" style={{ padding: "0rem 0rem 2.4rem" }}>
              <div className="shell" style={{ paddingLeft: "30px", textAlign: "left" }}>
                <div className="hero__image">
                  <img src="/assets/images/svg/logo-white.svg" alt="" width={130} />
                </div>
              </div>
            </div>
            <hr className="underlineLayout" />
            <div className="shell" style={{ paddingLeft: "0px", paddingRight: "0" }}>
              <div className="header__inner" style={{ width: "100%" }}>
                <div
                  className={menuOpen ? "header__nav js-nav is-active" : "header__nav js-nav"}
                  style={{ width: "100%",background: "#2d2d6d",
                color: "white"}}>
                  <nav className="nav-utilities">
                    {role === "admin" && (
                      <ul
                        style={{
                          flexDirection: "column",
                          color: "white",
                          alignItems: "start",
                          paddingLeft: 0,
                        }}>
                        <NavLink
                          to="/admin/landing"
                          style={({ isActive }) => (isActive ? StyleActive : StyleNoActive)}
                          onClick={() => setMenuOpen(false)}
                          className="landinglink">
                          {" "}
                          Users
                        </NavLink>
                        <NavLink
                          to="/admin/students"
                          style={({ isActive }) => (isActive ? StyleActive : StyleNoActive)}
                          onClick={() => setMenuOpen(false)}
                          className="landinglink">
                          {" "}
                          Students
                        </NavLink>
                        <NavLink
                          to="/admin/districts"
                          style={({ isActive }) => (isActive ? StyleActive : StyleNoActive)}
                          onClick={() => setMenuOpen(false)}
                          className="landinglink">
                          {" "}
                          Districts
                        </NavLink>
                        <NavLink
                          to="/admin/schools"
                          style={({ isActive }) => (isActive ? StyleActive : StyleNoActive)}
                          onClick={() => setMenuOpen(false)}
                          className="landinglink">
                          {" "}
                          Schools
                        </NavLink>
                        <NavLink
                          to="/admin/videos"
                          style={({ isActive }) => (isActive ? StyleActive : StyleNoActive)}
                          onClick={() => setMenuOpen(false)}
                          className="landinglink">
                          {" "}
                          Videos
                        </NavLink>
                        <NavLink
                          to="/admin/phases"
                          style={({ isActive }) => (isActive ? StyleActive : StyleNoActive)}
                          onClick={() => setMenuOpen(false)}
                          className="landinglink">
                          {" "}
                          Phases
                        </NavLink>
                      </ul>
                    )}
                  </nav>
                </div>

                <a
                  onClick={() => (menuOpen ? setMenuOpen(false) : setMenuOpen(true))}
                  className={
                    menuOpen ? "nav-trigger js-nav-trigger is-active" : "nav-trigger js-nav-trigger"
                  }>
                  <span></span>

                  <span></span>

                  <span></span>
                </a>
              </div>
            </div>
          </header>

          {/* <div className="hero hero--small">
            <div className="shell">
              <div className="hero__image">
                <img src="assets/images/hero-image@2x.png" alt="" width={208} />
              </div>
            </div>
          </div> */}
          {role === "admin" ? <Outlet /> : <Restricted />}
        </div>

        {/* <Footer /> */}
      </div>
    ) : (
      <Navigate to="/" replace />
    )
  ) : (
    <></>
  );
};

export default LayoutAdmin;
