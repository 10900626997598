import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { campaignClient } from "../../../api";
import { useStores } from "../../../stores";
import {
  americanZipCodeRegex,
  convertcaps,
  states,
} from "../../../helpers/utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useQuery } from "react-query";
import Loading from "../../../Components/Loading";
import dayjs from "dayjs";
import Header from "../../../Components/Header";
import { showToast } from "../../../Components/Toast/ToastManager";

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

type DistrictDetails = {
  city: string;
  zip: string;
  district: string;
  address: string;
  school_sites: string;
  region: string;
  name: string;
  address1: string;
  state: string;
  event: {
    zip: string;
    address: string;
    city: string;
    address1: string;
    eventTime: string;
    state: string;
    eventDate: string;
  };
};
type EventDetails = {
  eventDate: Date | undefined;
  eventTime: Date | undefined;
  address1: string;
  address2: string;
  city: string;
  zip: string;
  state: string;
  district: string;
};

const EditEventDetails = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  let user = useStores().authStore.user;

  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm<EventDetails>({
    mode: "onChange",
  });

  const districtRegister = {
    ...register("district", {
      required: {
        value: true,
        message: "Please select a district.",
      },
    }),
  };

  let formwatch = watch();
  const { isLoading: loadingDistricts, data: districts } = useQuery(
    "getalldistricts",
    async () => {
      if (user.role === "admin") {
        const res: { districts: any[] } = await campaignClient.call(
          "getDistricts",
          {}
        );
        return res.districts;
      } else {
        const res: { districts: any[] } = await campaignClient.call(
          "getDistricts",
          {
            userName: user.name,
          }
        );
        return res.districts;
      }
    },
    {
      onError: (error) => {
        window.vex.dialog.alert({
          unsafeMessage: `Something went wrong, please try again.`,
          callback: () => {
            return;
          },
        });
      },
    }
  );

  const { authStore } = useStores();

  const deleteEvent = async () => {
    try {
      const res: any = await campaignClient.call("districtevent", {
        district: authStore.user,
      });
      if (res === true) {
        setLoading(false);
        navigate("/event");
      }
    } catch (e) {
      window.vex.dialog.alert({
        unsafeMessage: "Something went wrong, please try again later",
        callback: () => {
          setLoading(false);
          return;
        },
      });
    }
  };

  const handleAdd = async (data: EventDetails) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const {
        zip,
        state,
        city,
        address2,
        address1,
        district,
        eventDate,
        eventTime,
      } = data;
      const { result, error } = await campaignClient.call<{
        result: boolean;
        error: string;
      }>("districtevent", {
        event: {
          eventDate: dayjs(data.eventDate).format("MM/DD/YYYY"),
          eventTime: dayjs(data.eventTime).format("hh:mm a"),
          address1,
          address2,
          zip,
          state,
          city,
        },
        district,
      });
      if (result) {
        showToast({
          content: "Event details are saved",
          duration: 3000,
          error: false,
        });
        setLoading(false);
      }
      if (error) {
      }
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  if (loadingDistricts) {
    return <Loading />;
  }

  return (
    <section className="section section--alt">
      <div className="shell">
        <Header title="District event" />
        <hr className="underlineHR" />
        <br />
        <div className="form form--school" style={{ float: "left" }}>
          <form onSubmit={handleSubmit(handleAdd)}>
            <div className="form__body">
              <div className="form__section">
                <div className="form__row">
                  <div className="form__cols">
                    <div className="form__col form__col--1of2">
                      <label className="form__label" htmlFor="school_name">
                        * District
                      </label>
                      <div className="form__controls district">
                        <select
                          className="field"
                          placeholder="District"
                          {...districtRegister}
                          onChange={(e) => {
                            districtRegister.onChange(e);
                            const selectedistrict = districts?.find(
                              (d) => d.district === e.currentTarget.value
                            );

                            if (selectedistrict?.event) {
                              let {
                                eventDate,
                                eventTime,
                                address1,
                                address2,
                                city,
                                state,
                                zip,
                              }: EventDetails = selectedistrict.event;
                              reset({
                                eventDate: eventDate
                                  ? new Date(eventDate)
                                  : undefined,
                                eventTime: eventTime
                                  ? new Date(`${eventDate},${eventTime}`)
                                  : undefined,
                                address1,
                                address2,
                                state,
                                city,
                                zip,
                              });
                            } else {
                              reset({
                                eventDate: undefined,
                                address2: "",
                                eventTime: undefined,
                                state: "",
                                zip: "",
                                city: "",
                                address1: "",
                              });
                            }
                          }}
                        >
                          <option value="">Select</option>
                          {districts?.map((district) => {
                            return (
                              <option value={district.district} key={district}>
                                {convertcaps(district.name)}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {errors.district && (
                        <p className="Registration-error">
                          <i className="fas fa-exclamation-circle" />{" "}
                          {errors.district.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form__row">
                  <div className="form__cols">
                    <div className="form__col form__col--1of2">
                      <label className="form__label" htmlFor="school_name">
                        * Event date
                      </label>
                      <div className="form__controls date">
                        {/* <input
                        className="field"
                        placeholder="School name"
                        {...register("eventDate", {
                          required: {
                            value: true,
                            message: "Please select a date.",
                          },
                        })}
                      /> */}

                        <Controller
                          control={control}
                          name="eventDate"
                          rules={{
                            required: {
                              value: true,
                              message: "Please select a date",
                            },
                          }}
                          render={({ field }) => (
                            <>
                              <DatePicker
                                className="field"
                                placeholderText="Select date"
                                onChange={(date) =>
                                  date && field.onChange(date)
                                }
                                selected={field.value}
                              />
                              <span className="calIco">
                                <img
                                  src="/assets/images/ico-calendar.png"
                                  alt="Calendar"
                                />
                              </span>
                            </>
                          )}
                        />
                      </div>
                      {errors.eventDate && (
                        <p className="Registration-error">
                          <i className="fas fa-exclamation-circle" />{" "}
                          {errors.eventDate.message}
                        </p>
                      )}
                    </div>
                    <div className="form__col form__col--1of2">
                      <label className="form__label" htmlFor="school_name">
                        * Event time
                      </label>
                      <div className="form__controls event-time clock">
                        <Controller
                          control={control}
                          name="eventTime"
                          rules={{
                            required: {
                              value: true,
                              message: "Please select time.",
                            },
                          }}
                          render={({ field }) => (
                            <>
                              <DatePicker
                                className="field time_only"
                                placeholderText="Select time"
                                onChange={(date) =>
                                  date && field.onChange(date)
                                }
                                selected={field.value}
                                showTimeSelect
                                showTimeSelectOnly
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                              />
                              <span className="timeIco">
                                <img
                                  src="/assets/images/ico-clock.png"
                                  alt="Select Time"
                                />
                              </span>
                            </>
                          )}
                        />
                      </div>
                      {errors.eventTime && (
                        <p className="Registration-error">
                          <i className="fas fa-exclamation-circle" />{" "}
                          {errors.eventTime.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form__row">
                  <div className="form__cols">
                    <div className="form__col form__col--1of2">
                      <label className="form__label" htmlFor="address_1">
                        * Address 1
                      </label>
                      <div className="form__controls">
                        <input
                          className="field"
                          placeholder="Address 1"
                          {...register("address1", {
                            required: {
                              value: true,
                              message: "Please enter your address.",
                            },
                            minLength: {
                              value: 2,
                              message: "Plase enter a valid address",
                            },
                          })}
                        />
                      </div>
                      {errors.address1 && (
                        <p className="Registration-error">
                          <i className="fas fa-exclamation-circle" />{" "}
                          {errors.address1.message}
                        </p>
                      )}
                    </div>
                    <div className="form__col form__col--1of2">
                      <label className="form__label" htmlFor="address_2">
                        Address 2
                      </label>
                      <div className="form__controls">
                        <input
                          className="field"
                          placeholder="Address 2"
                          {...register("address2")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form__row">
                  <div className="form__cols">
                    <div className="form__col form__col--1of3">
                      <label className="form__label" htmlFor="city">
                        * City
                      </label>
                      <div className="form__controls">
                        <input
                          className="field"
                          placeholder="City"
                          {...register("city", {
                            required: {
                              value: true,
                              message: "Please enter your city.",
                            },
                            minLength: {
                              value: 2,
                              message: "Plase enter a valid city",
                            },
                          })}
                        />
                      </div>
                      {errors.city && (
                        <p className="Registration-error">
                          <i className="fas fa-exclamation-circle" />{" "}
                          {errors.city.message}
                        </p>
                      )}
                    </div>

                    <div className="form__col form__col--1of3">
                      <label className="form__label" htmlFor="zip_code">
                        * State
                      </label>
                      <div className="form__controls">
                        <select
                          className="field"
                          placeholder="State"
                          {...register("state", {
                            required: {
                              value: true,
                              message: "Please select a state.",
                            },
                          })}
                        >
                          <option value="">Please select a state</option>
                          {states.map((state) => (
                            <option
                              key={state.abbreviation}
                              value={state.abbreviation.toLocaleLowerCase()}
                            >
                              {state.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {errors.state && (
                        <p className="Registration-error">
                          <i className="fas fa-exclamation-circle" />{" "}
                          {errors.state.message}
                        </p>
                      )}
                    </div>
                    <div className="form__col form__col--1of3">
                      <label className="form__label" htmlFor="zip_code">
                        * ZIP code
                      </label>
                      <div className="form__controls">
                        <input
                          className="field"
                          placeholder="ZIP code"
                          {...register("zip", {
                            required: {
                              value: true,
                              message: "Please enter your zip code.",
                            },
                            pattern: {
                              value: americanZipCodeRegex,
                              message: "Please enter a valid zip code.",
                            },
                          })}
                        />
                      </div>
                      {errors.zip && (
                        <p className="Registration-error">
                          <i className="fas fa-exclamation-circle" />{" "}
                          {errors.zip.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form__actions">
              {loading ? (
                <button
                  type="submit"
                  disabled
                  className="form__btn btn btn--purple"
                >
                  Saving
                </button>
              ) : (
                <button type="submit" className="form__btn btn btn--purple">
                  Save changes
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default EditEventDetails;
