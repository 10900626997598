import { Route, Navigate } from "react-router-dom";
import {
  Closed,
  Coming,
  EditEventDetails,
  EntryDetails,
  AddSchoolConfirmation,
} from "../Pages/Dms";
import { Layout } from "../Components";
import LayoutLeft from "../Components/LayoutLeft";
import RoleSelection from "../Pages/Dms/RoleSelection";
import ComeBackLater from "../Pages/Dms/ComeBackLater";
import LayoutAdmin from "../Components/LayoutAdmin";
import Districts from "../Pages/Admin/Districts";
import EditDistrict from "../Pages/Admin/EditDistrict";
import EditUser from "../Pages/Admin/EditUser";
import Manage from "../Pages/Admin/Manage";
import UploadVideo from "../Pages/Admin/UploadVideo";
import Users from "../Pages/Admin/Users";
import Videos from "../Pages/Admin/Videos";
import { AddDistrict } from "../Pages/Admin/AddDistrict";
import Students from "../Pages/Admin/Students";
import AllSchools from "../Pages/Admin/Schools";
import AddSchoolAdmin from "../Pages/Admin/AddSchool";
import EditSchoolAdmin from "../Pages/Admin/EditSchool";
import AddStudentAdmin from "../Pages/Admin/AddStudent";
import Submissions from "../Pages/Judges/Submissions";
import Confirmsubmission from "../Pages/Judges/ConformSubmission";
import EntryDetailsJudge from "../Pages/Judges/EntryDetails";
import DMSSchools from "../Pages/Dms/Schools";
import Verify from "../Pages/Dms/Verify";

export default (
  <>
    <Route path="landing" element={<Navigate to="/roleselection" />} />
    <Route path="login" element={<Navigate to="/roleselection" />} />
    <Route path="dms/comeBackLatter" element={<ComeBackLater />} />
    <Route path="verify/:sk" element={<Verify />} />

    <Route path="/" element={<Layout />}>
      <Route path="roleselection" element={<RoleSelection />} />
    </Route>
    <Route path="/dms" element={<LayoutLeft />}>
      <Route index element={<Navigate to="/dms/schools" />} />
      {/***** phase1 routes *********/}
      {/* <Route path="comeBackLatter" element={<ComeBackLater />} /> */}
      <Route path="schools" element={<DMSSchools />} />
      <Route path="addSchool" element={<AddSchoolAdmin />} />
      <Route path="editSchool" element={<EditSchoolAdmin />} />
      <Route path="confirmSchool" element={<AddSchoolConfirmation />} />
      <Route path="event" element={<EditEventDetails />} />
      <Route path="students" element={<Students />} />
      <Route path="addstudent" element={<AddStudentAdmin />} />
      <Route path="reviewstudent" element={<EntryDetails />} />
      <Route path="editstudent" element={<AddStudentAdmin />} />
      <Route path="*" element={<Navigate to="/dms" />} />
    </Route>
    <Route path="/judge" element={<LayoutLeft />}>
      <Route index element={<Navigate to="/judge/students" />} />
      {/***** phase1 routes *********/}
      <Route path="students" element={<Submissions type="all" />} />
      <Route path="reviewedStudents" element={<Submissions type="reviewed" />} />
      <Route path="unreviewedStudents" element={<Submissions type="unreviewed" />} />
      <Route path="reviewstudent" element={<EntryDetailsJudge />} />
      <Route path="confirmsubmission" element={<Confirmsubmission />} />
      <Route path="*" element={<Navigate to="/judge" />} />
    </Route>

    <Route path="/admin" element={<LayoutAdmin />}>
      <Route index element={<Navigate to="/admin/landing" />} />
      <Route path="landing" element={<Users />} />
      <Route path="adduser" element={<AddStudentAdmin />} />
      <Route path="edituser" element={<EditUser />} />
      <Route path="editdistrict" element={<EditDistrict />} />
      <Route path="adddistrict" element={<AddDistrict />} />
      <Route path="districts" element={<Districts />} />
      <Route path="adddistrict" element={<AddDistrict />} />
      <Route path="editdistrict" element={<EditDistrict />} />
      <Route path="schools" element={<AllSchools />} />
      <Route path="addschool" element={<AddSchoolAdmin />} />
      <Route path="editschool" element={<EditSchoolAdmin />} />
      <Route path="manage" element={<Manage />} />
      <Route path="videos" element={<Videos />} />
      <Route path="upload" element={<UploadVideo />} />
      <Route path="students" element={<Students />} />
      <Route path="phases" element={<Manage />} />
      <Route path="addstudent" element={<AddStudentAdmin />} />
      <Route path="editstudent" element={<AddStudentAdmin />} />
    </Route>
    <Route path="/regional" element={<LayoutLeft />}>
      <Route index element={<Navigate to="/regional/students" />} />
      {/***** phase1 routes *********/}
      <Route path="students" element={<Submissions type="all" />} />
      <Route path="reviewedStudents" element={<Submissions type="reviewed" />} />
      <Route path="unreviewedStudents" element={<Submissions type="unreviewed" />} />
      <Route path="reviewstudent" element={<EntryDetailsJudge />} />
      <Route path="confirmsubmission" element={<Confirmsubmission />} />
      <Route path="*" element={<Navigate to="/regional" />} />
    </Route>
    <Route path="/national" element={<LayoutLeft />}>
      <Route index element={<Navigate to="/national/students" />} />
      {/***** phase1 routes *********/}
      <Route path="students" element={<Submissions type="all" />} />
      <Route path="reviewedStudents" element={<Submissions type="reviewed" />} />
      <Route path="unreviewedStudents" element={<Submissions type="unreviewed" />} />
      <Route path="reviewstudent" element={<EntryDetailsJudge />} />
      <Route path="confirmsubmission" element={<Confirmsubmission />} />
      <Route path="*" element={<Navigate to="/national" />} />
    </Route>


    <Route path="closed" element={<Closed />} />
    <Route path="coming" element={<Coming />} />
  </>
);
