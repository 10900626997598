import { useNavigate } from "react-router-dom";
import { useStores } from "../../stores";
import { useEffect, useState } from "react";
import ModalCustom from "../ModalCustome";
import { defaultStorageHelper } from "@spry/campaign-client";
import { Flex } from "@aws-amplify/ui-react";

function Header({ title, children }: { title: string; children?: any }) {
  const [openModal, setOpenModal] = useState(false);
  const [isMultipleRoles, setIsMultipleRoles] = useState(false);
  const navigate = useNavigate();
  const { authStore } = useStores();

  useEffect(() => {
    const isMultiple = defaultStorageHelper.get("multiple");
    isMultiple && setIsMultipleRoles(true);
  }, []);

  const logOut = async () => {
    try {
      defaultStorageHelper.remove("multiple");
      await authStore.logout();

      navigate("/login", { replace: true });
    } catch (error) {
      console.error("error signing out: ", error);
    }
  };

  return (
    <div className="headerFlex">
      <h3>
        <b>{title}</b>
      </h3>
      {children}
      <div>
        {isMultipleRoles && (
          <button
            className="logout_btn"
            style={{ marginRight: "5rem" }}
            onClick={() => {
              defaultStorageHelper.remove("current");
              navigate("/roleselection");
            }}>
            <h6>Back to roles</h6>
          </button>
        )}
        <button
          className="logout_btn"
          onClick={() => {
            setOpenModal(true);
          }}>
          <i>
            <img src="/assets/images/svg/logout-icon.svg" alt="" width={25} />
          </i>
        </button>
      </div>
      {openModal && (
        <ModalCustom>
          <div
            className="popup popup--alt textCenter"
            style={{
              position: "relative",
              backgroundColor: "#fff",
              paddingTop: "30px",
              paddingBottom: "30px",
            }}>
            <div className="popup__content">
              <h3>Logout?</h3>
              <p>Are you sure you want to logout?</p>
            </div>
            <div
              className="popup__actions"
              style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
              <button
                type="button"
                className="btn btn--purple"
                onClick={() => {
                  defaultStorageHelper.remove("current");
                  logOut();
                }}>
                Yes, logout
              </button>

              <button
                type="button"
                className="btn btn--outline"
                onClick={() => {
                  setOpenModal(false);
                }}>
                Cancel
              </button>
            </div>
          </div>
        </ModalCustom>
      )}
    </div>
  );
}

export default Header;
