import { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useStores } from "../../stores";
import { useAuth } from "../../helpers/hooks";
import "./index.css";
import { NavLink } from "react-router-dom";
import { userInfo } from "os";
import { defaultStorageHelper } from "@spry/campaign-client";

function LayoutLeft() {
  const { authStore } = useStores();

  const { isAuth } = useAuth(authStore);
  const { pathname } = useLocation();
  const role = useStores().authStore.user.role || defaultStorageHelper.get("current");

  const [menuOpen, setMenuOpen] = useState(false);

  const StyleActive = {
    padding: "20px",
    width: "100%",
    backgroundColor: "#232357",
    borderLeft: "15px solid #464498",
  };
  const StyleNoActive = { padding: "20px 30px", width: "100%" };
  return !isAuth.loading ? (
    isAuth.auth ? (
      <div className="wrapper">
        <div className="main change1">
          <header className="header header-mobile">
            <div className="hero hero--small">
              <div className="shell" style={{ paddingLeft: "30px", textAlign: "left" }}>
                <div className="hero__image">
                  <img src="/assets/images/svg/logo-white.svg" alt="" width={130} />
                </div>
              </div>
            </div>
            <hr className="underlineLayout" />
            <div className="shell" style={{ paddingLeft: "0px", paddingRight: "0" }}>
              <div className="header__inner" style={{ width: "100%" }}>
                <div
                  className={menuOpen ? "header__nav js-nav is-active" : "header__nav js-nav"}
                  style={{ width: "100%", background: "#2d2d6d", color: "white" }}>
                  <nav className="nav-utilities">
                    <ul
                      style={{
                        flexDirection: "column",
                        color: "white",
                        alignItems: "start",
                        paddingLeft: 0,
                      }}>
                      {(role === "judge" ||
                        role === "regional manager" ||
                        role === "national manager") && (
                        <>
                          <NavLink
                            to={
                              role === "national manager"
                                ? "/national/students"
                                : `/regional/students`
                            }
                            style={({ isActive }) => (isActive ? StyleActive : StyleNoActive)}
                            onClick={() => setMenuOpen(false)}
                            className="landinglink">
                            All Submissions
                          </NavLink>
                          <NavLink
                            to={
                              role === "national manager"
                                ? "/national/reviewedStudents"
                                : "/regional/reviewedStudents"
                            }
                            style={({ isActive }) => (isActive ? StyleActive : StyleNoActive)}
                            onClick={() => setMenuOpen(false)}
                            className="landinglink">
                            {" "}
                            Reviewed Submissions{" "}
                          </NavLink>
                          <NavLink
                            to={
                              role === "national manager"
                                ? "/national/unreviewedStudents"
                                : `/regional/unreviewedStudents`
                            }
                            style={({ isActive }) => (isActive ? StyleActive : StyleNoActive)}
                            onClick={() => setMenuOpen(false)}
                            className="landinglink">
                            UnReviewed Submissions
                          </NavLink>
                        </>
                      )}
                      {(role === "primary" || role === "secondary") && (
                        <>
                          <NavLink
                            to={"/dms/schools"}
                            style={({ isActive }) =>
                              isActive ||
                              pathname.includes("dms/editSchool") ||
                              pathname.includes("dms/addschool")
                                ? StyleActive
                                : StyleNoActive
                            }
                            className="landinglink"
                            onClick={() => setMenuOpen(false)}>
                            Schools
                          </NavLink>
                          <NavLink
                            to="/dms/students"
                            style={({ isActive }) => (isActive ? StyleActive : StyleNoActive)}
                            onClick={() => setMenuOpen(false)}
                            className="landinglink">
                            {" "}
                            Students{" "}
                          </NavLink>
                          <NavLink
                            to="/dms/event"
                            style={({ isActive }) => (isActive ? StyleActive : StyleNoActive)}
                            onClick={() => setMenuOpen(false)}
                            className="landinglink">
                            {" "}
                            District Event
                          </NavLink>
                        </>
                      )}
                    </ul>
                  </nav>
                </div>

                <a
                  onClick={() => (menuOpen ? setMenuOpen(false) : setMenuOpen(true))}
                  className={
                    menuOpen ? "nav-trigger js-nav-trigger is-active" : "nav-trigger js-nav-trigger"
                  }>
                  <span></span>

                  <span></span>

                  <span></span>
                </a>
              </div>
            </div>
          </header>

          {/* <div className="hero hero--small">
            <div className="shell">
              <div className="hero__image">
                <img src="assets/images/hero-image@2x.png" alt="" width={208} />
              </div>
            </div>
          </div> */}
          <Outlet />
        </div>
        {/* <Footer /> */}
      </div>
    ) : (
      <Navigate to="/" replace />
    )
  ) : (
    <></>
  );
}

export default LayoutLeft;
