import React from "react";
import { useNavigate } from "react-router";

const DistrictColumns = () => {
  const navigate = useNavigate();
  const COLUMNS = [
    {
      Header: "District",
      accessor: "districtName",
    },
    {
      Header: "Region",
      accessor: "region",
    },
    {
      Header: "Event date",
      accessor: "eventDate",
    },
    {
      Header: "Event time",
      accessor: "eventTime",
    },

    {
      Header: "Event location",
      accessor: "eventLocation",
    },
    {
      Header: "Actions",
      accessor: "districtId",
      Cell: (tableInfo: any) => {
        return (
          <div className="table__actions">
            <button
              className="noBorder"
              onClick={() => {
                navigate("/admin/editdistrict", { state: tableInfo.data[tableInfo.row.index] });
              }}>
              <img src="/assets/images/svg/edit.svg" alt="edit district" width={25} height={25} />
            </button>
          </div>
        );
      },
    },
  ];
  return COLUMNS;
};

export default DistrictColumns;
