import React, { useState, useEffect } from "react";
import { campaignClient, getSessionClient, publicCampaignClient } from "../../../api";
import { showToast } from "../../../Components/Toast/ToastManager";
import { defaultStorageHelper } from "@spry/campaign-client";
import dayjs from "dayjs";

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

const SignUpCheck = () => {
  const [email, setEmail] = useState("");
  const [timer, setTimer] = useState(defaultStorageHelper.get("timer") || null);

  useEffect(() => {
    const em = defaultStorageHelper.get("email");
    em && setEmail(em);
  }, []);

  const resendEmail = async () => {
    try {
      const res = await publicCampaignClient.call("resendVerifyEmail", {
        sessionKey: getSessionClient().__sessionKey,
      });
      if (res) {
        setTimer(dayjs().add(1, "minute").toISOString());
        defaultStorageHelper.set("timer", dayjs().add(1, "minute").toISOString());
        showToast({
          content: "Verification link was successfully resend to " + email,
          duration: 5000,
          error: false,
        });
      } else {
        window.vex.dialog.alert({
          unsafeMessage: "Something went wrong, Please try again later.",
          callback: () => {
            return;
          },
        });
      }
    } catch {
      window.vex.dialog.alert({
        unsafeMessage: "Something went wrong, Please try again later.",
        callback: () => {
          return;
        },
      });
    }
  };
  return (
    <div className="main">
      <div className="hero hero--small">
        <div className="shell">
          <div className="hero__image">
            <img src="/assets/images/hero-image@2x.png" alt="" width={208} />
          </div>
        </div>
      </div>
      <section className="section section--secondary">
        <div className="shell">
          <div className="section__content">
            <h3>
              <b>Check your inbox</b>
            </h3>
            <img src="/assets/images/svg/envelope.svg" alt="" />
            <div className="section__entry">
              <p>
                To complete your account setup and login, click the verification link in the email
                we've send to {email}
              </p>

              <button
                disabled={timer && dayjs().isBefore(dayjs(timer)) ? true : false}
                onClick={resendEmail}
                style={{
                  textDecoration: "underline",
                  background: "transparent",
                  border: "none",
                }}>
                Resend email
              </button>
              {timer && dayjs().isBefore(dayjs(timer)) && (
                <p className="error-message" style={{ fontSize: "14px" }}>
                  {" "}
                  <i className="fas fa-exclamation-circle" /> Please wait a minute to try resending
                  the verification email again.
                </p>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SignUpCheck;
