import React, { useState, useMemo } from "react";
import { campaignClient } from "../../../api";
import { useStores } from "../../../stores";
import { Table } from "../../../Components/Table";
import { Participant } from "@sprycore/spry-api-client/dist/MainDbReturnTypes";
import { SubmissionsColumns } from "./submissionColumns";
import Loading from "../../../Components/Loading";
import "./index.css";
import { ACTIONS } from "../../../helpers/utils";
import { disableBrowserHistory } from "../../../helpers/utils";
import Header from "../../../Components/Header";
import { useQuery } from "react-query";
import { defaultStorageHelper } from "@spry/campaign-client";

type updatedParticipant = Participant & {
  status?: number;
  release?: boolean;
  temp_status: string;
};
type score = {
  origin: number;
  health: number;
  ease: number;
  kid: number;
  plate: number;
  adapt: number;
  judge?: string | undefined;
};

function Submissions({ type }: { type: string }) {
  disableBrowserHistory();
  const [filterTable, setFilterTable] = useState("");

  let user = useStores().authStore.user;
  let role = user.role || defaultStorageHelper.get("current");

  const { isLoading: loadingDistricts, data: regions } = useQuery(
    ["getManagers", role],

    async () => {
      const res: any[] = await campaignClient.call("managers", {
        userName: user.name,
      });
      return res?.filter((u) => u.userType === role);
    },
    { refetchOnWindowFocus: false }
  );

  const { isLoading: studentsLoading, data: students } = useQuery(
    ["getDistrictWinners", regions],
    async () => {
      let students: any[] = [];

      if (role === "national manager") {
        const res: any[] = await campaignClient.call("getSubmissions", {
          winType: "regional-winner",
        });
        students.push(...res);
        return students;
      } else if (role === "regional manager" && regions) {
        await Promise.all(
          regions?.map(async (d) => {
            const res: any[] = await campaignClient.call("getSubmissions", {
              region: d.region,
              winType: "district-winner",
            });

            students?.push(...res);
          })
        );
        return students;
      } else if (role === "judge" && regions && regions?.length) {
        await Promise.all(
          regions?.map(async (d) => {
            const res: any[] = await campaignClient.call("getSubmissions", {
              region: d.region,
              winType: "district-winner",
            });

            students?.push(...res);
          })
        );
        return students;
      }
    },
    { enabled: !!regions, refetchOnWindowFocus: false }
  );

  const data = useMemo(() => {
    if (regions && students && type) {
      let filteredStudents = students;

      if (type === "reviewed") {
        filteredStudents = students.filter((s) =>
          s.metadata?.scores?.find((s: score) => s.judge === user.name)
        );
      }
      if (type === "unreviewed") {
        filteredStudents = students.filter(
          (s) => !s.metadata?.scores?.find((s: score) => s.judge === user.name)
        );
      }

      return filteredStudents.map((s) => {
        return {
          ...s,
          districtName: s.metadata.districtName || "",
          studentName: s.firstName + " " + s.lastName || "",
          recipeName: s.metadata?.recipe?.name || "",
          region: s.metadata?.region || "",
          school: s.metadata?.schoolId === "other" ? s.metadata.otherSchool : s.metadata.schoolName,
          status: s.metadata?.status ? ACTIONS[s.metadata.status] : "district-unreviewed",
          score: s.metadata?.scores?.length
            ? s.metadata.scores
                .map((s: score) => {
                  return +s.adapt + +s.ease + +s.health + +s.kid + +s.origin + +s.plate;
                })
                .reduce((a: number, b: number) => +a + +b, 0)
            : 0,
        };
      });
    }
  }, [regions, type, students, user.name]);
  const submissionsColumns = useMemo(() => SubmissionsColumns(), []);

  return (
    <>
      {(loadingDistricts || studentsLoading) && <Loading />}

      <section className="section section--alt">
        <div className="shell">
          <Header
            title={`All ${regions?.length && regions?.map((r) => r.region).toString()} Submissions`}
            children={
              <div className="search search--alt">
                <div className="search__row">
                  <label htmlFor="q" className="hidden">
                    Search students
                  </label>
                  <input
                    style={{ minWidth: "25rem" }}
                    type="search"
                    name="q"
                    id="q"
                    value={filterTable}
                    placeholder="Search"
                    className="search__field"
                    onChange={(e) => setFilterTable(e.target.value)}></input>
                  <button type="submit" className="search__btn">
                    <svg className="ico-search">
                      <image xlinkHref="/assets/images/svg/ico-search.svg"></image>
                    </svg>
                  </button>
                </div>
              </div>
            }
          />

          <div className="shell">
            <div className="section__inner">
              <div className="section__body">
                <div className="table table--submissions">
                  <Table
                    columns={submissionsColumns}
                    data={data && data.length > 0 ? data : []}
                    tablePageSize={8}
                    filterValue={filterTable}
                  />
                  {students && students.length < 1 && (
                    <p style={{ textAlign: "center", border: "none" }}>
                      Currently there are no submissions made for this district.{" "}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /.section */}
    </>
  );
}

export default Submissions;
