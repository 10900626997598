import React from "react";
import { useNavigate } from "react-router";
import { convertcaps } from "../../../helpers/utils";
import { useStores, StoreContext } from "../../../stores";

const UsersColumns = (fns: { deleteUsers: Function }) => {
  const { authStore } = useStores();

  const navigate = useNavigate();
  const COLUMNS = [
    {
      Header: "First name",
      accessor: "firstName",
      width: "18%",
    },
    {
      Header: "Last name",
      accessor: "lastName",
      width: "18%",
    },

    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "District/Region",
      accessor: "district",
      width: "25%",
      Cell: (tableInfo: any) => {
        let district = convertcaps(tableInfo.data[tableInfo.row.index].district);
        return district;
      },
    },
    {
      Header: "User Type",
      accessor: "type",
      width: "35%",
    },
    {
      Header: "Actions",
      accessor: "username",
      Cell: (tableInfo: any) => {
        return (
          <div className="table__actions">
            <button
              className="noBorder"
              onClick={() => {
                navigate("/admin/edituser", { state: tableInfo.data[tableInfo.row.index] });
              }}>
              <img src="/assets/images/svg/edit.svg" alt="edit user" width={25} height={25} />
            </button>
          </div>
        );
      },
    },
  ];
  return COLUMNS;
};

export default UsersColumns;
