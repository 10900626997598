import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { campaignClient, publicCampaignClient } from "../../../api";
import { PageLoader, Table } from "../../../Components";
import VideosColumns from "./VideosColumns";
import { useQueries, useQuery } from "react-query";

import ReactPlayer from "react-player";
import "./index.css";
import { showToast } from "../../../Components/Toast/ToastManager";
import { convertcaps } from "../../../helpers/utils";
import { Participant } from "@sprycore/spry-api-client/dist/MainDbReturnTypes";
import Header from "../../../Components/Header";
import ModalCustom from "../../../Components/ModalCustome";
import Loading from "../../../Components/Loading";

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

type Video = {
  videoKey?: string;
  fileName: string;
  upload_link: string;
  sessionKey: string;
  managerName: string;
  email: string;
  creationDate?: string;
  player_embed_url: string;
  html_embed: string;
  uri: string;
  updateDate?: string;
  participantName: string;
};
const Videos = () => {
  const [loading, setLoading] = useState(false);
  const [playVideo, setPlayVideo] = useState("");
  const [fileName,setFileName]= useState("")
  const [isDelete, setIsDelete] = useState({ sessionKey: "", videoKey: "" });
  const [filterTable, setFilterTable] = useState("");

  const { isLoading: loadingWinners, data: nationalWinners } = useQuery(
    "getSubmissionsv2",
    async () => {
      const res: Participant[] = await campaignClient.call("getSubmissionsv2", {
        winType: "national-finalist",
      });
      return res.length? res:[];
    },
    {
      onError: (error) => {
        window.vex.dialog.alert({
          unsafeMessage: `Something went wrong, please try again.`,
          callback: () => {
            return;
          },
        });
      },
    }
  );

  const {
    isLoading: loadingVideos,
    data: videos,
    refetch,
  } = useQuery(
    "getVideos",
    async () => {
      const res: Video[] = await publicCampaignClient.call("getVideos", {});
      return res;
    },
    {
      onError: (error) => {
        window.vex.dialog.alert({
          unsafeMessage: `Something went wrong, please try again.`,
          callback: () => {
            return;
          },
        });
      },
    }
  );

  const deleteVideo = async (sessionKey: string, videoKey: string) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      await campaignClient
        .call("deleteVideo", {
          sessionKey,
          videoKey,
        })
        .then((res) => {
          setIsDelete({ sessionKey: "", videoKey: "" });
          refetch();
          showToast({
            content: "Video has been deleted successfully! ",
            duration: 3000,
            error: false,
          });
        });
    } catch (e) {
      window.vex.dialog.alert({
        unsafeMessage: `${e}`,
        callback: () => {
          return;
        },
      });
    }
    setLoading(false);
  };
  const data = useMemo(() => {
    if (nationalWinners && videos) {
      const filterWinners = nationalWinners.filter((p) => videos.map(v=>v.sessionKey).includes(p.sessionKey)) 
      return filterWinners?.map((rp) => {
        return {
          sessionKey: rp.sessionKey,
          videoUrl:
            videos.find((video) => video.sessionKey === rp.sessionKey)?.player_embed_url || "",
          videoKey: videos.find((video) => video.sessionKey === rp.sessionKey)?.videoKey || "",
          fileName: videos.find((video) => video.sessionKey === rp.sessionKey)?.fileName || "N/A",
          studentName: convertcaps(rp.firstName! + " " + rp.lastName!) || "N/A",
          updateTime: videos.find((video) => video.sessionKey === rp.sessionKey)?.creationDate || "",

        };
      });
    }
  }, [videos, nationalWinners]);

  const navigate = useNavigate();
  const functions = {
    setIsDelete,
    setPlayVideo,
    setFileName,
  };

  const columns = VideosColumns(functions);

  return (
    <>
      {(loading || loadingVideos || loadingWinners) && <Loading />}
      <section className="section section--alt">
        <div className="shell">
          <Header
            title="Videos"
            children={
              <div className="search search--alt">
                <div className="search__row">
                  <label htmlFor="q" className="hidden">
                    Search videos
                  </label>
                  <input
                    style={{ minWidth: "25rem" }}
                    type="search"
                    name="q"
                    id="q"
                    value={filterTable}
                    placeholder="Search"
                    className="search__field"
                    onChange={(e) => setFilterTable(e.target.value)}></input>
                  <button type="submit" className="search__btn">
                    <svg className="ico-search">
                      <image xlinkHref="/assets/images/svg/ico-search.svg"></image>
                    </svg>
                  </button>
                </div>
              </div>
            }
          />
          <hr className="underlineHR" />
          <div className="shell">
            <div className="section__inner">
              <div className="section__body">
                <div
                  className="section__actions"
                  style={{ display: "flex", justifyContent: "space-between" }}>
                  <button
                    type="button"
                    className="btn btn--purple btn--add"
                    onClick={(e) => {
                      navigate("/admin/upload");
                    }}>
                    Add video
                  </button>
                </div>
                <div className="table table--schools">
                  <Table
                    columns={columns}
                    data={data ? data : []}
                    tablePageSize={15}
                    filterValue={filterTable}
                  />
                </div>
              </div>
              <div className="section__actions">
                <div className="paging">{/* <ReactPaginate /> */}</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {playVideo && (
        <ModalCustom>
          <div
              className="popup popup--alt textCenter"
              style={{
                position: "relative",
                backgroundColor: "#fff",
                padding: "45px",
              }}>
            <div className="modal-close linkButton">
              {" "}
              <button onClick={() => setPlayVideo("")} className="modal-close-btn">
              <img src="/assets/images/svg/close.svg" alt="close" />
              </button>
            </div>
            <div className="popup__content">
              <h6>{fileName}</h6>
              <ReactPlayer
                url={playVideo}
                playing={true}
                loop={false}
                controls={true}
                width="100%"
              />
            </div>
          </div>
        </ModalCustom>
      )}
      {isDelete.sessionKey && (
        <ModalCustom
       >
           <div
              className="popup popup--alt textCenter"
              style={{
                position: "relative",
                backgroundColor: "#fff",
                padding: "45px",
              }}>
            <div className="modal-close">
              {" "}
              <button
                onClick={() => setIsDelete({ sessionKey: "", videoKey: "" })}
                className="modal-close-btn">
                <img src="/assets/images/svg/close.svg" alt="close" />
              </button>
            </div>
            <div className="popup__content">
              <h3><strong>Delete video?</strong></h3>
              <p> Are you sure you want to delete the video:</p>
              <p>{videos && videos.find((v) => v.videoKey === isDelete.videoKey)?.fileName}</p>
              <button
                  className="btn btn--purple" style={{marginRight:"20px"}}
                  onClick={() => deleteVideo(isDelete.sessionKey, isDelete.videoKey)}>
                Yes, delete
              </button>
              <button
                className="btn btn--outline"
                onClick={() => {
                  setIsDelete({ sessionKey: "", videoKey: "" });
                }}>
                Cancel
              </button>
            </div>
          </div>
        </ModalCustom>
      )}
    </>
  );
};

export default Videos;
