import React from "react";

const SignUpConfirmation = () => {
  return (
    <div className="main">
      <div className="hero hero--small">
        <div className="shell">
          <div className="hero__image">
            <img src="/assets/images/hero-image@2x.png" alt="" width={208} />
          </div>
        </div>
      </div>
      <section className="section section--secondary">
        <div className="shell">
          <div className="section__content">
            <h3>
              <b>Password reset</b>
            </h3>
            <img src="/assets/images/svg/check-big.svg" alt="" />
            <div className="section__entry">
              <p>Your password has been successfully reset.</p>
            </div>
            <br />
            <a href="/login" className="form__btn btn btn--purple">
              Go to login
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SignUpConfirmation;
