import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQueries, useQuery } from "react-query";
import { useNavigate } from "react-router";
import { campaignClient } from "../../../api";
import { PageLoader, Table } from "../../../Components";
import Header from "../../../Components/Header";
import StudentColumns from "./studentCols";
import { ACTIONS, byKey, convertcaps } from "../../../helpers/utils";
import "./index.css";
import { District } from "../../Dms/Signup";
import { useForm } from "react-hook-form";
import { SchoolSignup } from "../../../helpers/types";
import { useStores } from "../../../stores";
import { defaultStorageHelper } from "@spry/campaign-client";
import { CSVLink } from "react-csv";
import { Participant } from "@sprycore/spry-api-client/dist/MainDbReturnTypes";


type SelectUsers = {
  district: string;
  region: string;
  status: string | number;
  school: string;
};
declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

const Students = () => {
  const [filterTable, setFilterTable] = useState("");
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<SelectUsers>({
    defaultValues: {
      district: "",
      region: "",
      school: "",
      status: "",
    },
  });
  const formwatch = watch();

  let user = useStores().authStore.user;
  const role = user.role || defaultStorageHelper.get("current");
  const { isLoading: loadingphase2, data: phase2 } = useQuery(
    "getschedulephase2",
    async () => {
      const res: any = await campaignClient.call("getschedule", {
        phase: "phase2",
      });
      return res;
    },
    {
      onError: (error) => {
        window.vex.dialog.alert({
          unsafeMessage: `Something went wrong, please try again.`,
          callback: () => {
            return;
          },
        });
      },
    }
  );

  const { isLoading: loadingDistricts, data: districts } = useQuery(
    "getalldistricts",

    async () => {
      if (role === "admin") {
        const res: { districts: any[] } = await campaignClient.call("getDistricts", {});
        return res.districts;
      } else {
        const res: { districts: any[] } = await campaignClient.call("getDistricts", {
          userName: user.name,
        });

        return res.districts;
      }
    },

    {
      onError: (error) => {
        window.vex.dialog.alert({
          unsafeMessage: `Something went wrong, please try again.`,
          callback: () => {
            return;
          },
        });
      },
    }
  );
  const regions = districts ? Array.from(new Set(districts.map((p) => p.region))) : [];

  const { isLoading: schoolsLoading, data: schools } = useQuery(
    "getschools",
    async () => {
      let schools: SchoolSignup[] = [];
      if (role === "admin") {
        const res: { schools: SchoolSignup[] } = await campaignClient.call("schools", {});
        if (res.schools) {
          schools = res.schools;
        }
      } else {
        const res: { schools: SchoolSignup[] } = await campaignClient.call("schools", {
          userName: user.name,
        });
        if (res.schools) {
          schools = res.schools;
        }
      }

      const sortedSchools = schools?.sort((a, b) =>
        a.schoolName.toString().localeCompare(b.schoolName.toString())
      );

      return sortedSchools;
    },
    { refetchOnWindowFocus: false }
  );



  const getAdminSubmissions = async () => {
    let ct = 0
    let tempstudents: any[] = []
    await getadminstudents(ct)

    async function getadminstudents(count: number) {

      const res: { students: any[], lastFetch: number, total: number } = await campaignClient.call("getSubmissions", { ct: count });
      tempstudents.push(...res.students.filter((p) => p.email));
      ct = res.lastFetch + 1
      if (ct < res.total) {
        await getadminstudents(ct)
      }
    }

    return tempstudents



  }

  const { isLoading: studentsLoading, data: students } = useQuery(
    ["getstudents", districts],
    async () => {
      let students: any[] = [];
      if (role === "admin") {
        const res = await getAdminSubmissions()
        students.push(...res)
      } else {
        if (districts) {
          await Promise.all(
            districts?.map(async (d) => {
              const res: any[] = await campaignClient.call("getSubmissions", {
                district: d.district,
              });
              const addState = res.map(s => {
                let de = { ...s }
                de.metadata.state = d.state
                return de
              })

              students?.push(...addState);
            })
          );
        }
      }

      return students;
    },
    { enabled: !!districts }
    // { refetchOnWindowFocus: false }
    // { refetchOnWindowFocus: false }
  );

  const { isLoading: loadingWinners, data: nationalWinners } = useQuery(
    "national-finalist",
    async () => {
      const res: Participant[] = await campaignClient.call("getSubmissionsv2", {
        winType: "national-finalist",
      });


      const result: { sessionKey: string, votes: { sessionKey: string }[] }[] = await Promise.all(res.map(async w => await campaignClient.call("getVoteStats", {
        voter: w.sessionKey,
      })))
      console.log(result)
      return byKey(result, (a) => a.sessionKey)
    })

  const data = useMemo(() => {
    if (students && districts && schools && students.length > 0 && nationalWinners) {
      let { district, region, status, school } = formwatch;

      let selectedStudents: any[] = students;

      if (region) {
        selectedStudents = selectedStudents.filter(
          (s) => s.metadata?.region?.toLowerCase() === region?.toLowerCase()
        );
      }

      if (district) {
        selectedStudents = selectedStudents.filter((s) => s.metadata?.district === district);
      }
      if (school) {
        selectedStudents = selectedStudents.filter((s) => s.metadata?.schoolId === school);
      }
      if (status) {
        selectedStudents = selectedStudents.filter((s) => +s.metadata?.status === +status);
      }
      return selectedStudents.map((s) => {
        return {
          ...s,
          districtName: districts.find((d) => d.district === s.metadata?.district)?.name || "",
          studentName: s.firstName + " " + s.lastName || "",
          recipeName: s.metadata?.recipe?.name || "",
          region: s.metadata?.region || "",
          school: s.metadata?.schoolId === "other" ? s.metadata.otherSchool : s.metadata.schoolName,
          status: s.metadata?.status ? ACTIONS[s.metadata.status] : "district-unreviewed",
          parentSigned: s.metadata?.release ? "Yes" : "No",
          noOfVotes: nationalWinners[s.sessionKey] ? (nationalWinners[s.sessionKey][0].votes.length || "0") : "",
          totalScore: s?.metadata?.scores
            ?.map(
              (s: any) =>
                +s.plate +
                +s.origin +
                +s.kid +
                +s.health +
                +s.ease +
                +s.adapt
            )
            .reduce((a: number, b: number) => +a + +b, 0)
        };
      });
    } else {
      return [];
    }
  }, [students, schools, districts, formwatch]);

  const reportData = useMemo(() => {
    if (data) {

      return data.map((rp: any) => {
        const temp: { [key: string]: any } = {
          "Student Name": rp.studentName,
          "Email": rp.metadata.studentEmail,
          "Language": rp.preferredLanguage,
          "Region": rp.region,
          "District": rp.districtName,
          "School": rp.school,
          "Grade": rp.metadata.grade,
          "Status": rp.status,
          "Parent Signed": rp.parentSigned,
          "Parent name": rp.metadata.parent ? (rp.metadata.parent?.firstName + " " + rp.metadata.parent?.lastName) : "",
          "Parent email": rp.email,
          "Recipe name": rp.recipeName,
          "Ingredients": rp.metadata.recipe.ingredients.toString().replaceAll("\n", " ").replaceAll('\"'),
          "Preperation method": rp.metadata.recipe.preparationMethod.toString().replaceAll("\n", " ").replaceAll('\"'),
          "Total score": rp.totalScore
        };
        rp.metadata?.scores?.map((s: any, i: number) => {
          temp[`score${i + 1}`] = Object.values(s).filter((a: any) => !a.toString().includes('@'))?.reduce((a: any, b: any) => +a + +b, 0) + "," + s.judge

        })
        return temp
      });
    }
  }, [data]);

  const columns = StudentColumns();

  if (studentsLoading || schoolsLoading || loadingDistricts) {
    return <PageLoader />;
  }

  return (
    <>
      <section className="section section--alt">
        <div className="shell">
          <Header
            title="Students"
            children={<>
              <div className="search search--alt">
                <div className="search__row">
                  <label htmlFor="q" className="hidden">
                    Search students
                  </label>
                  <input
                    style={{ minWidth: "25rem" }}
                    type="search"
                    name="q"
                    id="q"
                    value={filterTable}
                    placeholder="Search"
                    className="search__field"
                    onChange={(e) => setFilterTable(e.target.value)}></input>
                  <button type="submit" className="search__btn">
                    <svg className="ico-search">
                      <image xlinkHref="/assets/images/svg/ico-search.svg"></image>
                    </svg>
                  </button>
                </div>
              </div>
              <div className="main__actions">
                <div className="form__btn btn btn--purple" style={{ "marginRight": "5px", }}>
                  <CSVLink
                    filename={`Campaign-Participants-Report-${new Date().toLocaleDateString()}`}
                    className="btn btn--small btn--mobile-small"
                    style={{ color: "white", height: "5.5rem" }}
                    data={reportData ? reportData : ""}
                    asyncOnClick={true}
                    target="_blank">
                    Download Report
                  </CSVLink>
                </div>
              </div>
            </>
            }
          />
          <hr className="underlineHR" />
          <div className="shell">
            <div className="section__inner">
              <div className="section__body">
                <div
                  className="section__actions"
                  style={{ display: "flex", justifyContent: "space-between", marginBottom: "15px" }}>
                  {phase2 && phase2.state === "open" && (
                    <button
                      type="button"
                      className="btn btn--purple btn--add"
                      onClick={(e) => {
                        if (window.location.pathname.includes("/admin")) {
                          navigate("/admin/addstudent");
                        } else {
                          navigate("/dms/addstudent");
                        }
                      }}>
                      Add student
                    </button>
                  )}
                  <form onSubmit={handleSubmit(() => { })}>
                    <div style={{ display: "flex" }}>
                      {role == "admin" && (
                        <>
                          <div className="search search--alt select-multiple">
                            <div className="search__row select">
                              <select
                                style={{ width: "20rem" }}
                                id="q"
                                {...register("region")}
                                className="">
                                <option value="">All regions</option>
                                {regions?.map((r) => (
                                  <option value={r?.toLowerCase()} key={r}>
                                    {convertcaps(r)}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="search search--alt select-multiple">
                            <div className="search__row select">
                              <select style={{ width: "20rem" }} {...register("district")}>
                                <option value="">All districts</option>
                                {districts &&
                                  districts.map((v: District) => (
                                    <option value={v.district} key={v.district}>
                                      {v.name && convertcaps(v.name)}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="search search--alt select-multiple">
                        <div className="search__row select">
                          <select style={{ width: "20rem" }} {...register("school")}>
                            <option value="">All schools</option>
                            {schools &&
                              schools.map((v: SchoolSignup) => (
                                <option value={v.schoolId} key={v.schoolId + v.district}>
                                  {v.schoolName && convertcaps(v.schoolName)}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="search search--alt ">
                        <div className="search__row select">
                          {/* //status: 1->viewed, 2 -> been selected as district competition 3 -> been selected as district winner */}

                          <select style={{ width: "20rem" }} {...register("status")}>
                            <option value="">All statuses</option>
                            {Object.keys(ACTIONS).map((k) => (
                              <option value={k} key={k}>
                                {ACTIONS[Number(k)]}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="table table--schools">
                  <Table
                    columns={columns}
                    data={data ? data : []}
                    tablePageSize={15}
                    filterValue={filterTable}
                  />
                </div>
              </div>
              <div className="section__actions">
                <div className="paging">{/* <ReactPaginate /> */}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Students;
