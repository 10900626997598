import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStores } from "../../../stores";
import "react-datepicker/dist/react-datepicker.css";
import { isBefore } from "date-fns";

export const ManageColumns = ({
  setSelected,
  setOpenCalendar,
  updateState,
}: {
  setSelected: Function;
  setOpenCalendar: Function;
  updateState: Function;
}) => {
  const navigate = useNavigate();

  const COLUMNS = [
    {
      Header: "Phase",
      accessor: "phase",
      width: "5%",
    },
    {
      Header: "Description",
      accessor: "description",
      width: "10%",
    },
    {
      Header: "",
      accessor: "status",
      width: "35%",
      Cell: (tableInfo: any) => {
        return (
          <div className="swtichCont">
            <label className="switch">
              <input
                type="checkbox"
                id="checkbox_school"
                checked={tableInfo.data[tableInfo.row.index].status}
                // disabled={tableInfo.data[(tableInfo.row.index].value === 3 && tableInfo.data[3].status ? true:false}
                onChange={async (e) => {
                  if (e.currentTarget.checked) {
                    if (tableInfo.data[tableInfo.row.index].value === "3a") {
                      if (tableInfo.data[3].status) {
                        updateState("3b", false, new Date());
                      }

                      setSelected({
                        site: tableInfo.data[tableInfo.row.index].value,
                        value: e.currentTarget.checked,
                      });
                      setOpenCalendar(true);
                    } else if (tableInfo.data[tableInfo.row.index].value === "3b") {
                      if (tableInfo.data[2].status) {
                        updateState("3a", false, new Date());
                      }
                      setSelected({
                        site: tableInfo.data[tableInfo.row.index].value,
                        value: e.currentTarget.checked,
                      });
                      setOpenCalendar(true);
                    } else {
                      setSelected({
                        site: tableInfo.data[tableInfo.row.index].value,
                        value: e.currentTarget.checked,
                      });
                      setOpenCalendar(true);
                    }
                  } else {
                    updateState(
                      tableInfo.data[tableInfo.row.index].value,
                      e.currentTarget.checked,
                      new Date()
                    );
                  }
                }}
              />
              <span className="slider round"></span>
            </label>
          </div>
        );
      },
    },
    {
      Header: "End date",
      accessor: "endDate",
      width: "5%",
    },
  ];
  return COLUMNS;
};
