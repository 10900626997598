import React from "react"
import { Modal } from "react-bootstrap"

const ModalCustom = ({ children }: any) => {
  return (
    <>
      <Modal show={true} onHide={() => {}} centered>
        {children}
      </Modal>
    </>
  )
}

export default ModalCustom
