import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import "./index.css";
import { campaignClient, publicCampaignClient } from "../../../api";
import { showToast } from "../../../Components/Toast/ToastManager";
import Header from "../../../Components/Header";
import { convertcaps, emailRegex } from "../../../helpers/utils";
import { District } from "../../Dms/Signup";
import { useQuery } from "react-query";
import { PageLoader } from "../../../Components";
import ModalCustom from "../../../Components/ModalCustome";

type UserForm = {
  userType: string;
  firstName: string;
  lastName: string;
  district: string;
  email: string;
  sessionKey: string;
};
declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

const EditUser = () => {
  const { state }: { state: UserForm } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsopen] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm<UserForm>({ mode: "onChange" });

  const { isLoading: loadingDistrict, data: districtDetails } = useQuery(
    "getDistricts",
    async () => {
      const res: { districts: District[] } = await publicCampaignClient.call("getDistricts", {});
      const sortedDistricts = res.districts.sort((a, b) =>
        a.districtName.toString().localeCompare(b.districtName.toString())
      );
      const defaultValues: UserForm = state
        ? {
            ...state,
          }
        : {
            district: "",
            email: "",
            firstName: "",
            lastName: "",
            userType: "",
            sessionKey: "",
          };
      reset({ ...defaultValues });
      return sortedDistricts;
    },
    { refetchOnWindowFocus: false }
  );

  const resendVerification = async () => {
    if (loading) {
      return;
    }
    try {
      let key = state.sessionKey;
      const { sessionKey, error, message } = await publicCampaignClient.call<{
        sessionKey: string;
        error?: string;
        message?: string;
      }>("resendVerifyEmail", { sessionKey: key });
      if (sessionKey) {
        showToast({
          content: "Verification link sent! ",
          duration: 3000,
          error: false,
        });
      }
      if (error) {
        showToast({
          content: `${message}`,
          duration: 3000,
          error: true,
        });
      }
    } catch (e) {
      window.vex.dialog.alert({
        unsafeMessage: "Something went wrong.",
        callback: () => {
          return;
        },
      });
    }
  };

  const handleSave = async (data: UserForm) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const res: any = await campaignClient.call("updatemanager", {
        userName: state.email,
        attrs: [
          { Name: "family_name", Value: data.firstName },
          { Name: "name", Value: data.lastName },
        ],
      });
      if (res === true) {
        showToast({
          content: "User saved. ",
          duration: 3000,
          error: false,
        });
        setLoading(false);
        navigate("/admin/landing");
      }
      if (res.error && res.message) {
        window.vex.dialog.alert({
          unsafeMessage: res.message,
          callback: () => {
            setLoading(false);
            return;
          },
        });
      }

      setLoading(false);
    } catch (e) {
      window.vex.dialog.alert({
        unsafeMessage: "Something went wrong, please try again later.",
        callback: () => {
          setLoading(false);
          return;
        },
      });

      setLoading(false);
    }
  };

  return (
    <>
      {loadingDistrict && <PageLoader />}
      <section className="section section--alt">
        <div className="shell">
          <Header title="Edit user" />
          <hr className="underlineHR" />

          {state ? (
            <div className="shell">
              <div className="section__inner">
                <div className="section__body">
                  <form
                    onSubmit={handleSubmit(handleSave)}
                    className="form form-sign-up"
                    style={{ float: "left" }}>
                    <div className="form__head">
                      <div className="form__entry">
                        <span className="form__notice">* Required field</span>
                      </div>
                    </div>
                    <div className="form__body">
                      <div className="form__row">
                        <div className="form__cols">
                          <div className="form__col form__col--1of2">
                            <label className="form__label" htmlFor="district">
                              * User type
                            </label>

                            <div className="form__controls">
                              <div className="select js-select">
                                <select
                                  disabled
                                  id="userType"
                                  data-width="100%"
                                  data-placeholder="user type"
                                  style={{ paddingTop: "0.8rem" }}
                                  {...register("userType", {
                                    required: {
                                      value: true,
                                      message: "Please select your district.",
                                    },
                                  })}>
                                  <option value="">Select usertype</option>
                                  <option value="admin">Admin</option>
                                  <option value="primary">Primary manager</option>
                                  <option value="secondary">Secondary manager</option>
                                  <option value="regional manager">Regional manager</option>
                                  <option value="judge">Regional judge</option>
                                  <option value="national manager">National manager</option>
                                </select>
                                <i className="fas fa-angle-down"></i>
                              </div>
                              {errors.district && (
                                <p className="Registration-error">
                                  <i className="fas fa-exclamation-circle" />{" "}
                                  {errors.district.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="form__col form__col--1of2">
                            <label className="form__label" htmlFor="district">
                              * District
                            </label>

                            <div className="form__controls">
                              <div className="select js-select">
                                <select
                                  disabled
                                  id="district"
                                  data-width="100%"
                                  data-placeholder="District"
                                  style={{ paddingTop: "0.8rem" }}
                                  {...register("district", {
                                    required: {
                                      value: true,
                                      message: "Please select your district.",
                                    },
                                  })}>
                                  <option value="">Select district</option>
                                  <option value="all">All</option>

                                  {districtDetails &&
                                    districtDetails?.map((v: District) => (
                                      <option value={v.districtName} key={v.districtName}>
                                        {v.districtName && convertcaps(v.districtName)}
                                      </option>
                                    ))}
                                </select>
                                <i className="fas fa-angle-down"></i>
                              </div>
                              {errors.district && (
                                <p className="Registration-error">
                                  <i className="fas fa-exclamation-circle" />{" "}
                                  {errors.district.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="form__col form__col--1of2">
                            <label className="form__label" htmlFor="first_name">
                              * First name
                            </label>
                            <div className="form__controls">
                              <input
                                disabled
                                className="field"
                                placeholder="First name"
                                {...register("firstName", {
                                  required: {
                                    value: true,
                                    message: "Please enter your first name.",
                                  },
                                })}
                              />
                            </div>
                            {errors.firstName && (
                              <p className="Registration-error">
                                <i className="fas fa-exclamation-circle" />{" "}
                                {errors.firstName.message}
                              </p>
                            )}
                          </div>
                          <div className="form__col form__col--1of2">
                            <label className="form__label" htmlFor="last_name">
                              * Last name
                            </label>
                            <div className="form__controls">
                              <input
                                disabled
                                className="field"
                                placeholder="Last name"
                                {...register("lastName", {
                                  required: {
                                    value: true,
                                    message: "Please enter your last name.",
                                  },
                                })}
                              />
                            </div>
                            {errors.lastName && (
                              <p className="Registration-error">
                                <i className="fas fa-exclamation-circle" />{" "}
                                {errors.lastName.message}
                              </p>
                            )}
                          </div>
                          <div className="form__col form__col--1of2">
                            <label className="form__label" htmlFor="email">
                              * Email
                            </label>
                            <div className="form__controls">
                              <input
                                disabled
                                className="field"
                                placeholder="Email"
                                {...register("email", {
                                  required: {
                                    value: true,
                                    message: "Please enter your email.",
                                  },
                                  pattern: {
                                    value: emailRegex,
                                    message: "Please enter a valid email.",
                                  },
                                })}
                              />
                            </div>
                            {errors.email && (
                              <p className="Registration-error">
                                <i className="fas fa-exclamation-circle" /> {errors.email.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form__entry form__col--1of2">
                      <span className="form__notice ">
                        If this user having issues logging into their account?{" "}
                        <button
                          className="linkButton click"
                          type="button"
                          onClick={(e) => setIsopen(true)}>
                          Click here
                        </button>{" "}
                        to send a one-time verification link to their email
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : (
            <div>No user details found</div>
          )}
        </div>
      </section>

      {isOpen && (
        <ModalCustom>
          <div
            className="popup popup--alt textCenter"
            style={{
              position: "relative",
              backgroundColor: "#fff",
              padding: "45px",
            }}>
            <div className="modal-close">
              {" "}
              <button
                onClick={(e) => {
                  setIsopen(false);
                }}
                className="modal-close-btn">
                <img src="/assets/images/svg/close.svg" alt="close" />
              </button>
            </div>
            <div className="popup__content">
              <h3>Resend verification link?</h3>
              <div>
                Are you sure, you would like to resend a verification link ? This action will reset
                the user's password to a temperory password and send a verification link to reset
                their password.
              </div>
            </div>
            <div className="popup__actions">
              <button
                type="button"
                className="btn btn--purple"
                onClick={async (e) => {
                  await resendVerification();
                  setIsopen(false);
                }}>
                Yes,Submit
              </button>
              <button
                type="button"
                className="btn btn--purple"
                onClick={(e) => {
                  setIsopen(false);
                }}>
                Cancel
              </button>
            </div>
          </div>
        </ModalCustom>
      )}
    </>
  );
};

export default EditUser;
