import React from "react";
import { useState, useMemo } from "react";
import "./index.css";
import { campaignClient } from "../../../api";
import Loading from "../../../Components/Loading";
import { Table } from "../../../Components";
import { ManageColumns } from "./manageColumns";
import { isBefore } from "date-fns";
import DatePicker from "react-datepicker";
import { useMutation, useQueryClient, useQuery } from "react-query";
import Header from "../../../Components/Header";
import ModalCustom from "../../../Components/ModalCustome";

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };
export type CreateCampaignStateScheduleArgs = {
  startTime: string | Date;
  state: "open" | "closed" | "suspended" | "redemptiononly" | "comingsoon";
  metadata?: any | null;
};
export type CampaignStateSchedule = {
  campaignStateScheduleKey: string;
  campaignKey: string;
  startTime: string;
  state: "open" | "closed" | "suspended" | "redemptiononly" | "comingsoon";
  metadata: any;
  creationTime: string;
};
export type states = {
  campaignStateSchedules: CampaignStateSchedule[];
};

const Manage = () => {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [endDate, setEnddate] = useState<Date | null>(new Date());
  const [openCalendar, setOpenCalendar] = useState(false);
  const [selected, setSelected] = useState<{ site: string; value: boolean }>();

  const { isLoading: loadingphase1, data: phase1 } = useQuery(
    "getschedulephase1",
    async () => {
      const res: any = await campaignClient.call("getschedule", {
        phase: "phase1",
      });
      return res;
    },
    {
      onError: (error) => {
        window.vex.dialog.alert({
          unsafeMessage: `Something went wrong, please try again.`,
          callback: () => {
            return;
          },
        });
      },
    }
  );
  const { isLoading: loadingphase1b, data: phase1b } = useQuery(
    "getschedulephase1b",
    async () => {
      const res: any = await campaignClient.call("getschedule", {
        phase: "phase1b",
      });
      return res;
    },
    {
      onError: (error) => {
        window.vex.dialog.alert({
          unsafeMessage: `Something went wrong, please try again.`,
          callback: () => {
            return;
          },
        });
      },
    }
  );
  const { isLoading: loadingphase2, data: phase2 } = useQuery(
    "getschedulephase2",
    async () => {
      const res: any = await campaignClient.call("getschedule", {
        phase: "phase2",
      });
      return res;
    },
    {
      onError: (error) => {
        window.vex.dialog.alert({
          unsafeMessage: `Something went wrong, please try again.`,
          callback: () => {
            return;
          },
        });
      },
    }
  );
  const { isLoading: loadingphase3a, data: phase3a } = useQuery(
    "getschedulephase3a",
    async () => {
      const res: any = await campaignClient.call("getschedule", {
        phase: "phase3a",
      });
      return res;
    },
    {
      onError: (error) => {
        window.vex.dialog.alert({
          unsafeMessage: `Something went wrong, please try again.`,
          callback: () => {
            return;
          },
        });
      },
    }
  );
  const { isLoading: loadingphase3b, data: phase3b } = useQuery(
    "getschedulephase3b",
    async () => {
      const res: any = await campaignClient.call("getschedule", {
        phase: "phase3b",
      });
      return res;
    },
    {
      onError: (error) => {
        window.vex.dialog.alert({
          unsafeMessage: `Something went wrong, please try again.`,
          callback: () => {
            return;
          },
        });
      },
    }
  );
  const { isLoading: loadingphase4, data: phase4 } = useQuery(
    "getschedulephase4",
    async () => {
      const res: any = await campaignClient.call("getschedule", {
        phase: "phase4",
      });
      return res;
    },
    {
      onError: (error) => {
        window.vex.dialog.alert({
          unsafeMessage: `Something went wrong, please try again.`,
          callback: () => {
            return;
          },
        });
      },
    }
  );
  const { isLoading: loadingphase4a, data: phase4a } = useQuery(
    "getschedulephase4a",
    async () => {
      const res: any = await campaignClient.call("getschedule", {
        phase: "phase4a",
      });
      return res;
    },
    {
      onError: (error) => {
        window.vex.dialog.alert({
          unsafeMessage: `Something went wrong, please try again.`,
          callback: () => {
            return;
          },
        });
      },
    }
  );
  const { isLoading: loadingphase4b, data: phase4b } = useQuery(
    "getschedulephase4b",
    async () => {
      const res: any = await campaignClient.call("getschedule", {
        phase: "phase4b",
      });
      return res;
    },
    {
      onError: (error) => {
        window.vex.dialog.alert({
          unsafeMessage: `Something went wrong, please try again.`,
          callback: () => {
            return;
          },
        });
      },
    }
  );
  const { isLoading: loadingphase5, data: phase5 } = useQuery(
    "getschedulephase5",
    async () => {
      const res: any = await campaignClient.call("getschedule", {
        phase: "phase5",
      });
      return res;
    },
    {
      onError: (error) => {
        window.vex.dialog.alert({
          unsafeMessage: `Something went wrong, please try again.`,
          callback: () => {
            return;
          },
        });
      },
    }
  );

  const data = useMemo(() => {
    return [
      {
        phase: "1",
        description: "DistrictManager sign up",
        value: "1",
        status: phase1?.state === "open" ? true : false,
        endDate: phase1?.metadata?.closeDate ? phase1.metadata.closeDate : "N/A",
      },
      {
        phase: "1b",
        description: "DistrictManager school sign up",
        value: "1b",
        status: phase1b?.state === "open" ? true : false,
        endDate: phase1b?.metadata?.closeDate ? phase1b.metadata.closeDate : "N/A",
      },
      {
        phase: "2",
        description: "Student registration",
        value: "2",
        status: phase2?.state === "open" ? true : false,
        endDate: phase2?.metadata?.closeDate ? phase2.metadata.closeDate : "N/A",
      },
      {
        phase: "3a",
        description: "Select chefs for district competition",
        value: "3a",
        status: phase3a?.state === "open" ? true : false,
        endDate: phase3a?.metadata?.closeDate ? phase3a.metadata.closeDate : "N/A",
      },
      {
        phase: "3b",
        description: "Select a district winner",
        value: "3b",
        status: phase3b?.state === "open" ? true : false,
        endDate: phase3b?.metadata?.closeDate ? phase3b.metadata.closeDate : "N/A",
      },
      {
        phase: "4",
        description: "Ready for judging",
        value: "4",
        status: phase4?.state === "open" ? true : false,
        endDate: phase4?.metadata?.closeDate ? phase4.metadata?.closeDate : "N/A",
      },
      {
        phase: "4a",
        description: "Select a regional winner",
        value: "4a",
        status: phase4a?.state === "open" ? true : false,
        endDate: phase4a?.metadata?.closeDate ? phase4a.metadata?.closeDate : "N/A",
      },
      {
        phase: "4b",
        description: "Select a national winner",
        value: "4b",
        status: phase4b?.state === "open" ? true : false,
        endDate: phase4b?.metadata?.closeDate ? phase4b.metadata?.closeDate : "N/A",
      },
      {
        phase: "5",
        description: "Open public voting",
        value: "5",
        status: phase5?.state === "open" ? true : false,
        endDate: phase5?.metadata?.closeDate ? phase5.metadata?.closeDate : "N/A",
      },
    ];
  }, [phase1, phase1b,phase2, phase3a, phase3b, phase4, phase4a, phase4b, phase5]);

  
  const { mutate, isLoading: submitting } = useMutation(
    async (data: {
      value: string;
      site: string;
      date: Date | undefined;
      closedate: Date | undefined;
    }) => {
      const result: any = await campaignClient.call("createschedule", {
        state: data.value,
        phase: `phase${data.site}`,
        startTime: data.date,
        metadata: { closeDate: data.closedate ? data.closedate.toLocaleString() : "N/A" },
      });
      setLoading(false);
      return result;
    },
    {
      onSuccess: (data: any) => {},
      onError: (e: any) => {
        return e;
      },
      onSettled: (data: any) => {
        queryClient.invalidateQueries(`getschedule${data.phase}`);
      },
    }
  );

  const updateState = async (site: string, value: boolean, date: Date | undefined) => {
    if (loading) {
      return;
    }
    setOpenCalendar(false);

    setLoading(true);

    if (value && date) {
      try {
        mutate({
          date: new Date(),
          value: isBefore(date, new Date()) ? "close" : "open",
          site,
          closedate: date,
        });
        mutate({ value: "closed", site, date: date, closedate: date });
      } catch (e) {
        setLoading(false);
        window.vex.dialog.alert({
          unsafeMessage: `Something went wrong, please try again.`,
          callback: () => {
            return;
          },
        });
      }
    } else {
      try {
        mutate({ value: "closed", site, date: new Date(), closedate: new Date() });
        setLoading(false);
      } catch (e) {
        setLoading(false);
        window.vex.dialog.alert({
          unsafeMessage: `Something went wrong, please try again.`,
          callback: () => {
            return;
          },
        });
      }
    }
  };


  const columns = ManageColumns({ setSelected, setOpenCalendar, updateState });

  if (
    loadingphase1 ||
    loadingphase1b||
    loadingphase2 ||
    loadingphase3a ||
    loadingphase3b ||
    loadingphase4 ||
    submitting ||
    loadingphase4a ||
    loadingphase4b ||
    loadingphase5
  ) {
    return <Loading />;
  }
  return (
    <>
      <section className="section section--alt">
        <div className="shell">
          <Header title="Phases" />
          <hr className="underlineHR" />

          <div className="form form-sign-up" style={{ float: "left" }}>
            <div className="table table--schools">
              <p>
                Toggle to open/close each phase and it's accompanying site. For phase 3, only one of
                the two options can be toggled on at a time.
              </p>
              <Table
                columns={columns}
                data={data ? data : []}
                tablePageSize={15}
                // sortbyid={"phase"}
              />
            </div>
          </div>
        </div>
      </section>
      {openCalendar && (
        <ModalCustom>
          <div
            className="popup popup--alt textCenter"
            style={{
              position: "relative",
              backgroundColor: "#fff",
              padding: "45px",
            }}>
            <div className="modal-close">
              {" "}
              <button onClick={() => setOpenCalendar(false)} className="modal-close-btn">
                <img src="/assets/images/svg/close.svg" alt="close" />
              </button>
            </div>
              <p className="popup-head">Please select a end date </p>
              <div className="modal-popup-content">

              <DatePicker
                className="field"
                selected={endDate}
                inline
                onChange={async (date: Date) => {
                  setEnddate(date);
                }}
                timeIntervals={1}
                showTimeSelect
                timeInputLabel="Time:"
                dateFormat="MMMM d, yyyy h:mm aa"
              />
            </div>
            <button
              className="calendar-btn btn btn--purple"
              onClick={() => {
                if (selected && endDate) {
                  // if(isBefore(endDate,new Date())){
                  //   updateState(selected?.site, false, endDate);

                  // }
                  // else{
                  updateState(selected?.site, selected?.value, endDate);

                  // }
                }
              }}
              disabled={endDate ? false : true}>
              Submit
            </button>
          </div>
        </ModalCustom>
      )}
    </>
  );
};
export default Manage;
