import { defaultStorageHelper } from "@spry/campaign-client";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { campaignClient, publicCampaignClient, setSessionClient } from "../../../api";
import { emailRegex } from "../../../helpers/utils";
declare const window: Window &
  typeof globalThis & {
    vex: any;
  };
const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    getValues,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<{ email: string }>({
    mode: "onChange",
  });

  const handleReset = async (data: { email: string }) => {
    if (loading) {
      return;
    }
    setLoading(true);
    const res = await publicCampaignClient.call<{
      sessionKey?: string;
      error?: string;
      messsage?: string;
    }>("resendVerifyEmail", { userName: data.email,reset:true });
    if (res.sessionKey) {
      setSessionClient(campaignClient.recoverSession(res.sessionKey));
      defaultStorageHelper.set("email", data.email);
      setLoading(false);
      navigate("/resetconfirmation");
    }
    if (res.error) {
      setLoading(false);
      window.vex.dialog.alert({
        unsafeMessage: `${res.messsage}`,
        callback: () => {
          return;
        },
      });
    }
  };

  return (
    <div className="wrapper">
      <div className="main">
        <div className="hero hero--small">
          <div className="shell">
            <div className="hero__image">
              <img src="/assets/images/hero-image@2x.png" alt="" width={208} />
            </div>
          </div>
        </div>
        <section className="section section--alt">
          <div className="shell">
            <form onSubmit={handleSubmit(handleReset)}>
              <div
                className="form form--login"
                style={{ textAlign: "center", marginTop: "2.5rem" }}>
                <div className="form__head">
                  <h3>Reset your password</h3>
                  <p>
                    Enter the email address you used when you created your account and we will send
                    you a link to reset your password.
                  </p>
                </div>

                <div className="form__body">
                  <div className="form__col ">
                    <label className="form__label" htmlFor="email">
                      * Email
                    </label>
                    <div className="form__controls">
                      <input
                        className="field"
                        placeholder="Email"
                        {...register("email", {
                          required: {
                            value: true,
                            message: "Please enter your email.",
                          },
                          pattern: {
                            value: emailRegex,
                            message: "Please enter a valid email.",
                          },
                        })}
                      />
                    </div>
                    <div className="form__actions">
                      <button
                        className="form__btn btn--purple btn"
                        style={{ marginRight: "20px" }}
                        type="submit">
                        Send password reset email
                      
                      </button>
                    </div>
                  </div>
                  <Link to="/login">Back to sign in</Link>
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ResetPassword;
