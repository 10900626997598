import { useEffect, useState } from "react";
import { Navigate, Link } from "react-router-dom";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";
import { useStores } from "../../stores";
import Footer from "../Footer";
import { useAuth } from "../../helpers/hooks";
import "./index.css";
import { NavLink } from "react-router-dom";

type currentState = {
  startTime: string;
  scheduleKey: string;
  metadata: {
    closeDate: string;
  };
  phase: string;
  state: string;
};

function LandingLayoutLeft() {
  const { authStore } = useStores();

  const [menuOpen, setMenuOpen] = useState(false);

  const navigate = useNavigate();

  const logOut = async () => {
    try {
      await authStore.logout();

      navigate("/dms/landing", { replace: true });
    } catch (error) {
      console.error("error signing out: ", error);
    }
  };

  const StyleActive = {
    padding: "20px",
    width: "100%",
    backgroundColor: "#232357",
    borderLeft: "15px solid #464498",
  };
  const StyleNoActive = { padding: "20px", width: "100%", paddingLeft: "30px" };
  return (
    <div className="wrapper">
      <div className="main change1">
        <header className="header header-mobile">
          <div className="hero hero--small">
            <div className="shell" style={{ paddingLeft: "30px", textAlign: "left" }}>
              <div className="hero__image">
                <img src="/assets/images/svg/logo-white.svg" alt="" width={130} />
              </div>
            </div>
          </div>
          <div className="shell" style={{ paddingLeft: "0px", paddingRight: "0" }}>
            <div className="header__inner" style={{ width: "100%" }}>
              <div
                className={menuOpen ? "header__nav js-nav is-active" : "header__nav js-nav"}
                style={{ width: "100%",background: "#2d2d6d",
                color: "white"}}>
                <nav className="nav-utilities">
                  <ul
                    style={{
                      flexDirection: "column",
                      color: "white",
                      alignItems: "start",
                      paddingLeft: 0,
                    }}>
                    <NavLink
                      to="/signup"
                      style={({ isActive }) => (isActive ? StyleActive : StyleNoActive)}
                      className="landinglink"
                      onClick={() => setMenuOpen(false)}>
                      Sign up
                    </NavLink>
                    <NavLink
                      to="/login"
                      style={({ isActive }) => (isActive ? StyleActive : StyleNoActive)}
                      onClick={() => setMenuOpen(false)}
                      className="landinglink">
                      {" "}
                      Login
                    </NavLink>
                  </ul>
                </nav>
              </div>

              <a
                onClick={() => (menuOpen ? setMenuOpen(false) : setMenuOpen(true))}
                className={
                  menuOpen ? "nav-trigger js-nav-trigger is-active" : "nav-trigger js-nav-trigger"
                }>
                <span></span>

                <span></span>

                <span></span>
              </a>
            </div>
          </div>
        </header>
        
        <Outlet />
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default LandingLayoutLeft;
