import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { campaignClient, publicCampaignClient } from "../../../api";
import { useNavigate } from "react-router-dom";
import { americanZipCodeRegex } from "../../../helpers/utils";
import { states } from "../../../helpers/utils";
import { showToast } from "../../../Components/Toast/ToastManager";
import Header from "../../../Components/Header";
import DatePicker from "react-datepicker";
import { User } from "../Users";
import { useQuery } from "react-query";
import { useStores } from "../../../stores";
import Loading from "../../../Components/Loading";

type DistrictForm = {
  state: string;
  district: string;
  name: string;
  region: string;
  zip: string;
  city: string;
  address1: string;
  address2?: string;
  primary: string;
  secondary: string;
  event: {
    eventDate: Date;
    eventTime: Date;
    address1: string;
    address2: string;
    city: string;
    zip: string;
    state: string;
  };
};
declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

export const AddDistrict = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const user = useStores().authStore.user.name;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<DistrictForm>({
    mode: "onTouched",
  });

  const handleRegister = async (data: DistrictForm) => {
    if (loading) {
      return;
    }
    setLoading(true);

    try {
      const res: any = await campaignClient.call("adddistrict", {
        ...data,
      });

      if (res.district) {
        showToast({
          content: "District added. ",
          duration: 3000,
          error: false,
        });
        navigate("/admin/districts");
      }

      if (res.error) {
        window.vex.dialog.alert({
          unsafeMessage: `${res.error}`,
          callback: () => {
            setLoading(false);
            return;
          },
        });
      }
    } catch (e) {
      window.vex.dialog.alert({
        unsafeMessage: "Something went wrong, please try again later.",
        callback: () => {
          setLoading(false);
          return;
        },
      });
    }
  };
  const {
    isLoading: loadingUsers,
    data: users,
    refetch,
  } = useQuery("managers", async () => {
    const res: User[] = await campaignClient.call("managers", { userName: user });
    return res;
  });
  const { isLoading: loadingRegions, data: regions } = useQuery("getRegions", async () => {
    const res: string[] = await publicCampaignClient.call("getRegions", {});
    return res;
  });

  return (
    <>
      {(loadingRegions || loadingUsers) && <Loading />}

      <section className="section section--alt">
        <div className="shell">
          <Header title="Add district" />
          <hr className="underlineHR" />

          <div className="shell">
            <div className="section__inner">
              <div className="section__body">
                <form
                  onSubmit={handleSubmit(handleRegister)}
                  className="form form-sign-up"
                  style={{ float: "left" }}>
                  <div className="form__head" style={{ marginTop: "20px" }}>
                    <h6> District info</h6>

                    <span className="form__notice">* Required field</span>
                  </div>
                  <div className="form__body">
                    <div className="form__row">
                      <div className="form__cols">
                        <div className="form__col form__col--1of2">
                          <label className="form__label" htmlFor="last_name">
                            * District name
                          </label>
                          <div className="form__controls">
                            <input
                              className="field"
                              placeholder="District name"
                              {...register("name", {
                                required: {
                                  value: true,
                                  message: "Please enter a district name.",
                                },
                              })}
                            />
                          </div>
                          {errors.name && (
                            <p className="Registration-error">
                              <i className="fas fa-exclamation-circle" /> {errors.name.message}
                            </p>
                          )}
                        </div>
                        <div className="form__col form__col--1of2">
                          <label className="form__label" htmlFor="region">
                            * Region
                          </label>
                          <div className="form__controls">
                            <select
                              className="field"
                              {...register("region", {
                                required: {
                                  value: true,
                                  message: "Please select a region.",
                                },
                              })}>
                              <option value="">Select</option>
                              {regions?.map((r) => (
                                <option key={r} value={r}>
                                  {r}
                                </option>
                              ))}
                            </select>
                          </div>
                          {errors.region && (
                            <p className="Registration-error">
                              <i className="fas fa-exclamation-circle" /> {errors.region.message}
                            </p>
                          )}
                        </div>
                        <div className="form__col form__col--1of2">
                          <label className="form__label" htmlFor="address_1">
                            * Address 1
                          </label>
                          <div className="form__controls">
                            <input
                              className="field"
                              placeholder="Address 1"
                              {...register("address1", {
                                required: { value: true, message: "Please enter address 1" },
                                minLength: { value: 2, message: "Plase enter a valid address" },
                              })}
                            />
                          </div>
                          {errors.address1 && (
                            <p className="Registration-error">
                              <i className="fas fa-exclamation-circle" /> {errors.address1.message}
                            </p>
                          )}
                        </div>
                        <div className="form__col form__col--1of2">
                          <label className="form__label" htmlFor="address_2">
                            Address 2
                          </label>
                          <div className="form__controls">
                            <input
                              className="field"
                              placeholder="Address 2"
                              {...register("address2")}
                            />
                          </div>
                        </div>
                        <div className="form__col form__col--1of2">
                          <label className="form__label" htmlFor="city">
                            * City
                          </label>
                          <div className="form__controls">
                            <input
                              className="field"
                              placeholder="City"
                              {...register("city", {
                                required: { value: true, message: "Please enter city" },
                                minLength: { value: 2, message: "Plase enter a valid city" },
                              })}
                            />
                          </div>
                          {errors.city && (
                            <p className="Registration-error">
                              <i className="fas fa-exclamation-circle" /> {errors.city.message}
                            </p>
                          )}
                        </div>
                        <div
                          className="form__col form__col--1of2"
                          style={{ display: "flex", padding: 0 }}>
                          <div className="form__col form__col--1of2">
                            <label className="form__label" htmlFor="state">
                              * State
                            </label>
                            <div className="form__controls">
                              <select
                                className="field"
                                {...register("state", {
                                  required: {
                                    value: true,
                                    message: "Please select a state.",
                                  },
                                })}>
                                <option value="">Select</option>
                                {states.map((state) => (
                                  <option value={state.abbreviation.toLocaleLowerCase()}>
                                    {state.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {errors.state && (
                              <p className="Registration-error">
                                <i className="fas fa-exclamation-circle" /> {errors.state.message}
                              </p>
                            )}
                          </div>
                          <div className="form__col form__col--1of3">
                            <label className="form__label" htmlFor="zip_code">
                              * ZIP code
                            </label>
                            <div className="form__controls">
                              <input
                                className="field"
                                placeholder="ZIP code"
                                {...register("zip", {
                                  required: { value: true, message: "Required" },
                                  pattern: {
                                    value: americanZipCodeRegex,
                                    message: "Please enter a valid zip code.",
                                  },
                                })}
                              />
                            </div>
                            {errors.zip && (
                              <p className="Registration-error">
                                <i className="fas fa-exclamation-circle" /> {errors.zip.message}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="form__col form__col--1of2">
                          <label className="form__label" htmlFor="city">
                            District Manager
                          </label>
                          <div className="form__controls">
                            <select
                              className="field"
                              {...register("primary", {
                                required: {
                                  value: false,
                                  message: "Please select a primary manager.",
                                },
                              })}>
                              <option value="">Select a primary manager</option>
                              {users &&
                                users.map((u) => (
                                  <option value={u.email} key={u.district + u.email}>
                                    {u.email}
                                  </option>
                                ))}
                            </select>
                          </div>
                          {errors.primary && (
                            <p className="Registration-error">
                              <i className="fas fa-exclamation-circle" /> {errors.primary.message}
                            </p>
                          )}
                        </div>
                        <div className="form__col form__col--1of2">
                          <label className="form__label" htmlFor="city">
                            Secondary contact
                          </label>
                          <div className="form__controls">
                            <select
                              className="field"
                              {...register("secondary", {
                                required: {
                                  value: false,
                                  message: "Please select a secondary contact.",
                                },
                              })}>
                              <option value="">Select a secondary contact</option>
                              {users &&
                                users
                                  .filter((l) => l.userType !== "primary" && l.userType !== "admin")
                                  .map((u) => (
                                    <option value={u.email} key={u.district + u.email}>
                                      {u.email}
                                    </option>
                                  ))}
                            </select>
                          </div>
                          {errors.secondary && (
                            <p className="Registration-error">
                              <i className="fas fa-exclamation-circle" /> {errors.secondary.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form__head" style={{ marginTop: "20px" }}>
                    <h6> District event details (optional)</h6>
                  </div>
                  <div className="form__body">
                    <div className="form__row">
                      <div className="form__cols">
                        <div className="form__col form__col--1of2">
                          <label className="form__label" htmlFor="school_name">
                            Event date
                          </label>
                          <div className="form__controls date">
                            {/* <input
                          className="field"
                          placeholder="School name"
                          {...register("eventDate", {
                            required: {
                              value: true,
                              message: "Please select a date.",
                            },
                          })}
                        /> */}

                            <Controller
                              control={control}
                              name="event.eventDate"
                              render={({ field }) => (
                                <>
                                  <DatePicker
                                    className="field"
                                    placeholderText="Select "
                                    onChange={(date) => date && field.onChange(date)}
                                    selected={field.value}
                                  />
                                  <span className="calIco">
                                    <img src="/assets/images/ico-calendar.png" alt="Calendar" />
                                  </span>
                                </>
                              )}
                            />
                          </div>
                          {errors.event?.eventDate && (
                            <p className="Registration-error">
                              <i className="fas fa-exclamation-circle" />{" "}
                              {errors.event.eventDate.message}
                            </p>
                          )}
                        </div>
                        <div className="form__col form__col--1of2">
                          <label className="form__label" htmlFor="school_name">
                            Event time
                          </label>
                          <div className="form__controls clock">
                            <Controller
                              control={control}
                              name="event.eventTime"
                              render={({ field }) => (
                                <>
                                  <DatePicker
                                    className="field"
                                    placeholderText="Select"
                                    onChange={(date) => date && field.onChange(date)}
                                    selected={field.value}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                  />
                                  <span className="timeIco">
                                    <img src="/assets/images/ico-clock.png" alt="Select Time" />
                                  </span>
                                </>
                              )}
                            />
                          </div>
                          {errors.event?.eventTime && (
                            <p className="Registration-error">
                              <i className="fas fa-exclamation-circle" />{" "}
                              {errors.event.eventTime.message}
                            </p>
                          )}
                        </div>
                        <div className="form__col form__col--1of2">
                          <label className="form__label" htmlFor="address_1">
                            Address 1
                          </label>
                          <div className="form__controls">
                            <input
                              className="field"
                              placeholder="Address 1"
                              {...register("event.address1", {
                                required: false,
                                minLength: { value: 2, message: "Plase enter a valid address" },
                              })}
                            />
                          </div>
                          {errors.event?.address1 && (
                            <p className="Registration-error">
                              <i className="fas fa-exclamation-circle" />{" "}
                              {errors.event?.address1.message}
                            </p>
                          )}
                        </div>
                        <div className="form__col form__col--1of2">
                          <label className="form__label" htmlFor="address_2">
                            Address 2
                          </label>
                          <div className="form__controls">
                            <input
                              className="field"
                              placeholder="Address 2"
                              {...register("event.address2")}
                            />
                          </div>
                        </div>
                        <div className="form__col form__col--1of2">
                          <label className="form__label" htmlFor="city">
                            City
                          </label>
                          <div className="form__controls">
                            <input
                              className="field"
                              placeholder="City"
                              {...register("event.city", {
                                required: false,
                                minLength: { value: 2, message: "Plase enter a valid city" },
                              })}
                            />
                          </div>
                          {errors.event?.city && (
                            <p className="Registration-error">
                              <i className="fas fa-exclamation-circle" />{" "}
                              {errors.event.city.message}
                            </p>
                          )}
                        </div>
                        <div
                          className="form__col form__col--1of2"
                          style={{ display: "flex", padding: 0 }}>
                          <div className="form__col form__col--1of2">
                            <label className="form__label" htmlFor="state">
                              State
                            </label>
                            <div className="form__controls">
                              <select className="field" {...register("event.state")}>
                                <option value="">Select</option>
                                {states.map((state) => (
                                  <option value={state.abbreviation.toLocaleLowerCase()}>
                                    {state.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {errors?.event?.state && (
                              <p className="Registration-error">
                                <i className="fas fa-exclamation-circle" />{" "}
                                {errors.event?.state.message}
                              </p>
                            )}
                          </div>
                          <div className="form__col form__col--1of3">
                            <label className="form__label" htmlFor="zip_code">
                              ZIP code
                            </label>
                            <div className="form__controls">
                              <input
                                className="field"
                                placeholder="ZIP code"
                                {...register("event.zip", {
                                  required: false,
                                  pattern: {
                                    value: americanZipCodeRegex,
                                    message: "Please enter a valid zip code.",
                                  },
                                })}
                              />
                            </div>
                            {errors?.event?.zip && (
                              <p className="Registration-error">
                                <i className="fas fa-exclamation-circle" />{" "}
                                {errors?.event?.zip.message}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="form__actions">
                          {isSubmitting ? (
                            <button type="submit" disabled className="form__btn btn btn--purple">
                              <i className="fas fa-circle-notch fa-spin"></i>
                            </button>
                          ) : (
                            <button type="submit" className="form__btn btn btn--purple">
                              Add district
                            </button>
                          )}

                          <button
                            type="button"
                            className="form__btn btn btn--outline"
                            onClick={() => navigate("/admin/districts")}>
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
